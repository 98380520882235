import { CheckCircle } from "@mui/icons-material";
import { Box, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const GridBox = styled(Box)(({ theme: { spacing } }) => ({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gap: spacing(2),
}));

export default function ImportDone() {
  const { t } = useTranslation("common", {
    keyPrefix: "components.import",
  });
  return (
    <main
      style={{
        display: "grid",
        placeItems: "center",
        marginTop: "20vh",
      }}
    >
      <GridBox>
        <CheckCircle sx={{ fontSize: "2.5rem", gridRow: "1 / span 2" }} />
        <Typography variant="h4">{t("processing")}</Typography>
        <Typography>{t("completionTime")}</Typography>
      </GridBox>
    </main>
  );
}
