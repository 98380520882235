import { Cancel } from "@mui/icons-material";
import { Alert, alpha, Typography, useTheme } from "@mui/material";
import AppButton from "../../components/appButton";

export default function ImportError() {
  const { palette, spacing } = useTheme();
  return (
    <main>
      <Typography variant="h4">Import unsuccessfull</Typography>

      <Alert
        severity="error"
        sx={{
          backgroundColor: palette.error.light,
          color: palette.error.main,
          marginY: spacing(3),
        }}
        icon={<Cancel sx={{ color: palette.error.main }} />}
      >
        <Typography>
          Unfortunately, there are too many errors in your import file. Please
          correct your import file data and try again later.
        </Typography>
      </Alert>

      <Typography variant="h6" sx={{ mb: spacing(1.5) }}>
        Error list
      </Typography>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          gap: spacing(0.5),
          paddingY: spacing(0.25),
        }}
      >
        <Cancel sx={{ fontSize: spacing(2.5) }} /> Row 154 - Column terminal ID
        cannot be empty
      </Typography>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          gap: spacing(0.5),
          paddingY: spacing(0.25),
        }}
      >
        <Cancel sx={{ fontSize: spacing(2.5) }} /> Row 154 - Column terminal ID
        cannot be empty
      </Typography>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          gap: spacing(0.5),
          paddingY: spacing(0.25),
        }}
      >
        <Cancel sx={{ fontSize: spacing(2.5) }} /> Row 154 - Column terminal ID
        cannot be empty
      </Typography>
      <AppButton
        variant="outlined"
        sx={{
          color: palette.secondary.main,
          borderColor: palette.secondary.main,
          ":hover": {
            backgroundColor: alpha(palette.secondary.main, 0.1),
          },
          mt: spacing(2.5),
        }}
      >
        Go back
      </AppButton>
    </main>
  );
}
