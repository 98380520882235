import {
  DialogTitle,
  Typography,
  IconButton,
  Box,
  useTheme,
  MenuItem,
  alpha,
  styled,
} from "@mui/material";
import AppButton from "../appButton";
import AppTextField from "../form/textField";
import { useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AppSelect from "../form/select";
import { useTranslation } from "react-i18next";
import { WidgetTypes } from "../../types/enumTypes";
import { WidgetState } from "../widgets/WidgetContainer";
import { useAppSelector } from "stores/store";
import { createWidget, useWidgetTypesQuery } from "api/endpoints/dashboard";
import { COLORS } from "theme/colors";
import { appErrorHandler } from "utils/funcs/appErrorHandler";
import WidgetLoader from "components/widgetLoader";
import { delayedCallback } from "utils/funcs/delayedCallback";

const DEFAULT_WIDGET_POSITION = { x: 0, y: 999, w: 10, h: 10 };
interface CreateWidgetProps {
  handleClose: () => void;
  createCallBack?: () => void;
}
export const CreateWidget: React.FC<CreateWidgetProps> = ({
  handleClose,
  createCallBack,
}) => {
  const { t } = useTranslation("common", {
    keyPrefix: "components.createWidget",
  });
  const { t: tEnum } = useTranslation("common", { keyPrefix: "enums" });
  const [isLoading, setIsLoading] = useState(false);
  const creatingWidget = useRef(false);
  const { networkId } = useAppSelector((state) => state.user);
  const { data: widgetTypesDB, isLoading: widgetTypesLoading } =
    useWidgetTypesQuery();
  const [createWidgetState, setCreateWidgetState] = useState<
    Omit<WidgetState, "id">
  >({
    type: WidgetTypes["NETWORK_HEALTH"],
    position: DEFAULT_WIDGET_POSITION,
    name: "",
    settings: {},
  });
  const theme = useTheme();
  const { spacing } = theme;

  const StyledMenuItem = styled(MenuItem)(({ theme: { palette } }) => ({
    fontSize: "0.9rem",
    fontWeight: 400,
    color: COLORS.GREY.MAIN,
    "&:hover": {
      backgroundColor: palette.background.lightGrey,
    },
  }));

  const createW = async () => {
    // Prevent double click
    if (creatingWidget.current) {
      return;
    }
    creatingWidget.current = true;
    setIsLoading(true);
    const req = {
      type: createWidgetState.type,
      settings: {},
      name: createWidgetState.name,
      position: createWidgetState.position,
    };
    try {
      await createWidget(networkId, req);
      if (createCallBack) createCallBack();
      handleClose();
    } catch (error) {
      appErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: isLoading || widgetTypesLoading ? "hidden" : "auto",
      }}
    >
      {" "}
      {(isLoading || widgetTypesLoading) && <WidgetLoader />}
      <>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: `1px solid ${COLORS.LIGHT_GREY.DARK}`,
          }}
        >
          <Typography variant="h6" fontWeight={700}>
            {t("widgetTitle")}
          </Typography>
          <IconButton key="Settings" onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box
          sx={{
            padding: spacing(4, 3),
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <AppSelect
            required
            fullWidth
            label={t("chooseWidgetType")}
            onChange={(e) => {
              setCreateWidgetState({
                ...createWidgetState,
                type: e.target.value as WidgetTypes,
              });
            }}
            value={createWidgetState.type}
          >
            {widgetTypesDB?.map((entry, index) => (
              <StyledMenuItem key={index} value={entry.code}>
                {tEnum(`widgetTypes.${entry.code}`)}
              </StyledMenuItem>
            ))}
          </AppSelect>
          <AppTextField
            label={t("customName")}
            value={createWidgetState.name}
            onChange={(e) =>
              setCreateWidgetState({
                ...createWidgetState,
                name: e.target.value,
              })
            }
          />
          <Box sx={{ alignSelf: "end" }}>
            <AppButton
              disabled={isLoading}
              sx={{
                backgroundColor: COLORS.LIGHT_BLUE.LIGHT,
                color: COLORS.DARK_BLUE.MAIN,
                fontSize: "0.9rem",
                fontWeight: 500,
                padding: theme.spacing(2),
                ":hover": {
                  backgroundColor: alpha(theme.palette.secondary.main, 0.8),
                },
              }}
              onClick={delayedCallback(createW, 300)}
              variant="contained"
            >
              {t("confirm")}
            </AppButton>
          </Box>
        </Box>
      </>
    </Box>
  );
};
