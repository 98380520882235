import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import { Responsive, WidthProvider } from "react-grid-layout";

import { Card, styled, useMediaQuery, useTheme } from "@mui/material";
import { ReactElement, useMemo } from "react";

const DragableContainer = styled(Card)(({ theme: { palette } }) => ({
  background: palette.background.paper,
  display: "block",
  height: "100%",
}));

const Grid = WidthProvider(Responsive);

interface DragableGridProps {
  containers: { element: ReactElement }[];
  onResizeStop?: (
    id: string,
    position: { w: number; x: number; y: number; h: number },
  ) => void;
  onDragStop?: (
    id: string,
    position: { w: number; x: number; y: number; h: number },
  ) => void;
}

const DragableGrid: React.FC<DragableGridProps> = ({
  containers,
  onResizeStop,
  onDragStop,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const layout = useMemo(() => {
    return containers.map((container, index) => {
      const baseLayout = {
        i: `${container.element.props.children.props.id}`,
        ...container.element.props.children.props.position,
      };

      const width = isTablet ? 2 : 1;

      if (isTablet) {
        return {
          ...baseLayout,
          x: 0,
          y: index,
          w: width,
        };
      } else {
        return {
          ...baseLayout,
          x: (index % 2) * 6,
          y: Math.floor(index / 2) * 3,
          w: width,
        };
      }
    });
  }, [containers, isTablet]);

  return (
    <div style={{ width: "100% !important" }}>
      {layout.length > 0 ? (
        <Grid
          onDragStop={async (layout, oldItem, newItem) => {
            if (onDragStop && newItem.moved) {
              onDragStop(newItem.i, {
                w: newItem.w,
                x: newItem.x,
                y: newItem.y,
                h: newItem.h,
              });
            }
          }}
          onResizeStop={async (layout, oldItem, newItem) => {
            if (onResizeStop) {
              onResizeStop(newItem.i, {
                w: newItem.w,
                x: newItem.x,
                y: newItem.y,
                h: newItem.h,
              });
            }
          }}
          className="layout"
          layouts={{ lg: layout }}
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 2 }}
          rowHeight={40}
          autoSize={true}
          containerPadding={[0, 10]}
        >
          {containers.map((cont, index) => (
            <DragableContainer key={layout[index].i}>
              {cont.element}
            </DragableContainer>
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DragableGrid;
