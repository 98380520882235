import { CircularProgress, styled } from "@mui/material";
import { COLORS } from "theme/colors";

const Wrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100vh",
}));

const FullscreenLoader = () => {
  return (
    <Wrapper>
      <CircularProgress size={60} sx={{ color: COLORS.DARK_BLUE.MAIN }} />
    </Wrapper>
  );
};

export default FullscreenLoader;
