import { styled, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Dialog from "components/Dialog/index";
import { CheckCircleOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
interface ErrorDialogProps {
  isDialogOpen: boolean;
  title?: string;
  handleClose: () => void;
  message: string | undefined;
}

const SuccessIcon = styled(CheckCircleOutline)(
  ({ theme: { spacing, palette } }) => ({
    width: spacing(7),
    height: spacing(7),
    color: palette.success.main,
    padding: "0 !important",
  }),
);

const SuccessDialog: React.FC<ErrorDialogProps> = ({
  isDialogOpen,
  message,
  handleClose,
}: ErrorDialogProps) => {
  const { spacing } = useTheme();
  const { t } = useTranslation("common");
  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      title={t("successDialogTitle")}
      handleClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: spacing(2),
          gap: spacing(2),
        }}
      >
        <SuccessIcon />
        <Typography>{message}</Typography>
      </Box>
    </Dialog>
  );
};

export default SuccessDialog;
