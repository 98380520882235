import { List, ListItem, styled, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Dialog from "components/Dialog/index";
import { ErrorOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
interface ErrorDialogProps {
  isDialogOpen: boolean;
  title?: string;
  handleClose: () => void;
  messages: string[] | string | undefined;
}

const ErrorIcon = styled(ErrorOutline)(({ theme: { spacing, palette } }) => ({
  width: spacing(7),
  height: spacing(7),
  color: palette.error.main,
  padding: "0 !important",
}));

const ErrorDialog: React.FC<ErrorDialogProps> = ({
  isDialogOpen,
  messages,
  handleClose,
}: ErrorDialogProps) => {
  const { spacing } = useTheme();
  const { t } = useTranslation("common");
  return (
    <Dialog
      isDialogOpen={isDialogOpen}
      title={t("errorDialogTitle")}
      handleClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: spacing(2),
        }}
      >
        <ErrorIcon />
        <List>
          {Array.isArray(messages) ? (
            messages?.map((message: string, index) => {
              return <ListItem key={index}>{message}</ListItem>;
            })
          ) : (
            <Typography>{messages}</Typography>
          )}
        </List>
      </Box>
    </Dialog>
  );
};

export default ErrorDialog;
