import { styled, Select as MuiSelect, SelectProps } from "@mui/material";
import FormField from "../formField";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AppInputBase from "../appInputBase";
import { COLORS } from "theme/colors";

const StyledSelect = styled(MuiSelect)(({ theme }) => ({
  height: "40px",
  "&.Mui-error": {
    borderColor: theme.palette.error.main,
  },
}));

const StyledSelectAsButton = styled(MuiSelect, {
  shouldForwardProp: (prop) => prop !== "size",
})<{ size: SelectSize }>(() => ({
  "&.MuiOutlinedInput-root": {
    fontSize: "0.9rem",
    fontWeight: 400,
    color: COLORS.GREY.MAIN,
  },
}));

type SelectVariant = "standard" | "asButton";
type SelectSize = "small" | "normal";

const AppSelect: React.FC<
  SelectProps & {
    errorText?: string;
    selectVariant?: SelectVariant;
    size?: SelectSize;
  }
> = ({
  label,
  error,
  errorText,
  required,
  children,
  selectVariant = "standard",
  size = "small",
  startAdornment,
  ...rest
}) => {
  const inputId =
    rest.id || `app-select-${Math.random().toString(36).substr(2, 9)}`;
  return (
    <FormField
      label={label}
      required={required}
      error={error}
      errorText={errorText}
      htmlFor={inputId}
    >
      {selectVariant === "asButton" ? (
        <StyledSelectAsButton
          size={size}
          IconComponent={KeyboardArrowDownIcon}
          fullWidth
          input={<AppInputBase id={inputId} />}
          error={error}
          {...rest}
        >
          {children}
        </StyledSelectAsButton>
      ) : (
        <StyledSelect
          IconComponent={KeyboardArrowDownIcon}
          fullWidth
          input={<AppInputBase id={inputId} startAdornment={startAdornment} />}
          error={error}
          {...rest}
        >
          {children}
        </StyledSelect>
      )}
    </FormField>
  );
};

export default AppSelect;
