import { styled, Typography } from "@mui/material";
import { Cell, Legend, Pie, PieChart } from "recharts";
import CircleIcon from "@mui/icons-material/Circle";

const GRAPH_COLORS = [
  "#3DC86A",
  "#1AA6F6",
  "#FB515B",
  "#FDC638",
  "#9B73EC",
  "#177E8A",
  "#F0BAC9",
  "#3C3487",
  "#D0D0D0",
  "#383F47",
  "#D60076",
  "#F69F63",
  "#CEAC47",
  "#CD6DDA",
  "#2DECC3",
  "#59DBFC",
  "#1348D1",
];

const LegendItem = styled("div")(() => ({}));
const LegendWrapper = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  gap: spacing(2),
}));

export interface GraphData {
  name: string;
  value: number;
}

interface PieChartProps {
  data: GraphData[];
}

export const AppPieChart: React.FC<PieChartProps> = ({ data }) => {
  return (
    <PieChart width={500} height={300}>
      <Legend
        height={100}
        width={250}
        verticalAlign="middle"
        align="right"
        content={(props) => {
          return (
            <LegendWrapper
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              {props.payload?.map((entry: any, index) => (
                <LegendItem
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  key={`item-${index}`}
                >
                  <CircleIcon sx={{ color: entry.color, marginRight: 1 }} />
                  <Typography
                    sx={{ marginRight: 2 }}
                    variant="h6"
                  >{`${entry.payload?.percent.toFixed(2)} %`}</Typography>
                  <Typography
                    color="text.lightGrey.main"
                    variant="subtitle1"
                    component="span"
                  >
                    {entry.value}
                  </Typography>
                </LegendItem>
              ))}
            </LegendWrapper>
          );
        }}
      />
      <Pie
        data={data}
        cx={100}
        cy={150}
        outerRadius={100}
        dataKey="value"
        style={{ margin: "0px auto" }}
      >
        {data.map((_entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={GRAPH_COLORS[index % GRAPH_COLORS.length]}
          />
        ))}
      </Pie>
    </PieChart>
  );
};
