import { Box, Button } from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

type StepNavigationButtonsProps = {
  activeStep: number;
  prevStep: () => void;
};

export default function StepNavigationButtons({
  activeStep,
  prevStep,
}: StepNavigationButtonsProps) {
  const { spacing, palette } = useTheme();
  const { t } = useTranslation("common", { keyPrefix: "newTerminal" });

  return (
    <Box
      sx={{
        display: "flex",
        mt: spacing(2),
        justifyContent: activeStep ? "space-between" : "flex-end",
      }}
    >
      {activeStep !== 0 && (
        <Button onClick={prevStep} sx={{ mr: 1 }} color="secondary">
          {t("backBtn")}
        </Button>
      )}
      <Button
        sx={{
          backgroundColor: palette.secondary.main,
          color: palette.common.white,
          ":hover": {
            backgroundColor: alpha(palette.secondary.main, 0.8),
          },
        }}
        type="submit"
      >
        {t("continueBtn")}
      </Button>
    </Box>
  );
}
