import { Dialog, Grid, Typography } from "@mui/material";
import {
  activateMerchant,
  deactivateMerchant,
  duplicateMerchantDetail,
  updateMerchantDetail,
  useGetMerchantQuery,
} from "api/endpoints/merchants";
import { useParams } from "react-router-dom";
import { useAppSelector } from "stores/store";
import AppButton from "../components/appButton";
import { AppDataContainer } from "../components/appDataContainer";
import BreadCrumb from "../components/breadcrumbs/BreadCrumb";
import { MERCHANTS_URL } from "./appRoutes/appRoutesConst";
import { useTranslation } from "react-i18next";
import { COLORS } from "theme/colors";
import { theme } from "theme";
import { AddMerchant } from "components/Dialog/addMerchant";
import { useState } from "react";
import { detailQueryResult, Merchant, MerchantImport } from "types/commonTypes";
import { errorHandling } from "utils/funcs/errorHandling";
import ErrorDialog from "components/Dialog/ErrorDialog";
import SuccessDialog from "components/Dialog/SuccessDialog";
import FullscreenLoader from "components/fullscreenLoader";

export default function MerchantDetail() {
  const { networkId } = useAppSelector((state) => state.user);
  const { merchantId } = useParams();
  const { t } = useTranslation("common", { keyPrefix: "merchantDetail" });
  const { spacing } = theme;
  const { t: tStatus } = useTranslation("common", {
    keyPrefix: "enums.ImportStatus",
  });
  const [errors, setError] = useState<string[]>();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [defMerchantData, setDefMerchantData] = useState<MerchantImport>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogMode, setDialogMode] = useState<
    "create" | "update" | "duplicate"
  >();

  if (!merchantId) return null;

  const { data, isLoading: loadingMerchant } = useGetMerchantQuery(
    networkId,
    merchantId,
  ) as detailQueryResult<Merchant>;
  const updateDataHandler = async () => {
    setIsLoading(true);
    await updateMerchantDetail(networkId, merchantId)
      .then((resp) => {
        setDefMerchantData(resp.data);
        setDialogMode("update");
        setIsEditDialogOpen(true);
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => setIsLoading(false));
  };

  const duplicateDataHandler = async () => {
    setIsLoading(true);
    await duplicateMerchantDetail(networkId, merchantId)
      .then((resp) => {
        setDefMerchantData(resp.data);
        setDialogMode("duplicate");
        setIsEditDialogOpen(true);
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => setIsLoading(false));
  };
  const activateMerchantHandler = async () => {
    setIsLoading(true);
    await activateMerchant(networkId, merchantId)
      .then(() => {
        setSuccessMessage(t("merchantActivated"));
        setIsSuccessDialogOpen(true);
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => setIsLoading(false));
  };
  const deactivateMerchantHandler = async () => {
    setIsLoading(true);
    await deactivateMerchant(networkId, merchantId)
      .then(() => {
        setSuccessMessage(t("merchantDeactivated"));
        setIsSuccessDialogOpen(true);
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => setIsLoading(false));
  };

  const BUTTONS = [
    <AppButton
      variant="contained"
      color="secondary"
      size="small"
      key={0}
      onClick={updateDataHandler}
    >
      {t("editBtn")}
    </AppButton>,
    <AppButton
      variant="contained"
      color="secondary"
      size="small"
      key={1}
      onClick={duplicateDataHandler}
    >
      {t("duplicateBtn")}
    </AppButton>,
  ];

  if (data?.status === "C") {
    BUTTONS.push(
      <AppButton
        variant="contained"
        color="success"
        size="small"
        key={2}
        onClick={activateMerchantHandler}
      >
        {t("activateBtn")}
      </AppButton>,
    );
  } else if (data?.status === "A") {
    BUTTONS.push(
      <AppButton
        variant="contained"
        color="error"
        size="small"
        key={2}
        onClick={deactivateMerchantHandler}
      >
        {t("deactivateBtn")}
      </AppButton>,
    );
  }

  if (isLoading || loadingMerchant) return <FullscreenLoader />;

  if (!data) return null;

  return (
    <>
      <BreadCrumb
        items={[
          { title: t("breadCrumbTitle"), linkTo: MERCHANTS_URL },
          { title: data.name },
        ]}
      />
      <AppDataContainer
        sx={{ marginBottom: 3 }}
        titleRow={{
          title: t("basicInfoSectionTitle"),
          buttons: BUTTONS,
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          padding={spacing(4, 3)}
        >
          <Grid item xs={2} sm={4} md={3}>
            <Typography
              variant={"body2"}
              fontWeight={400}
              color={COLORS.GREY.MAIN}
            >
              {t("name")}
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={700}
              color={COLORS.GREY.MAIN}
            >
              {data.name}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Typography
              variant={"body2"}
              fontWeight={400}
              color={COLORS.GREY.MAIN}
            >
              {t("status")}
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={700}
              color={COLORS.GREY.MAIN}
            >
              {tStatus(data.status)}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Typography
              variant={"body2"}
              fontWeight={400}
              color={COLORS.GREY.MAIN}
            >
              {t("identifier")}
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={700}
              color={COLORS.GREY.MAIN}
            >
              {data.identifier}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Typography
              variant={"body2"}
              fontWeight={400}
              color={COLORS.GREY.MAIN}
            >
              {t("businessId")}
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={700}
              color={COLORS.GREY.MAIN}
            >
              {data.business_id}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Typography
              variant={"body2"}
              fontWeight={400}
              color={COLORS.GREY.MAIN}
            >
              {t("mcc")}
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={700}
              color={COLORS.GREY.MAIN}
            >
              {data.mcc}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Typography
              variant={"body2"}
              fontWeight={400}
              color={COLORS.GREY.MAIN}
            >
              {t("note")}
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={700}
              color={COLORS.GREY.MAIN}
            >
              {data.note}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Typography
              variant={"body2"}
              fontWeight={400}
              color={COLORS.GREY.MAIN}
            >
              {t("acquirerId")}
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={700}
              color={COLORS.GREY.MAIN}
            >
              {data.acquirer_id}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Typography
              variant={"body2"}
              fontWeight={400}
              color={COLORS.GREY.MAIN}
            >
              {t("locationCount")}
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={700}
              color={COLORS.GREY.MAIN}
            >
              {data.location_count}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Typography
              variant={"body2"}
              fontWeight={400}
              color={COLORS.GREY.MAIN}
            >
              {t("terminalCount")}
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={700}
              color={COLORS.GREY.MAIN}
            >
              {data.terminal_count}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Typography
              variant={"body2"}
              fontWeight={400}
              color={COLORS.GREY.MAIN}
            >
              {t("networkName")}
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={700}
              color={COLORS.GREY.MAIN}
            >
              {data.network_name}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <Typography
              variant={"body2"}
              fontWeight={400}
              color={COLORS.GREY.MAIN}
            >
              {t("vatId")}
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={700}
              color={COLORS.GREY.MAIN}
            >
              {data.vat_id}
            </Typography>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Typography
              variant={"body2"}
              fontWeight={400}
              color={COLORS.GREY.MAIN}
            >
              {t("address")}
            </Typography>
            <Typography
              variant={"body2"}
              fontWeight={700}
              color={COLORS.GREY.MAIN}
            >
              {`${data.street}, ${data.city}, ${data.zip_code}`}
            </Typography>
          </Grid>
        </Grid>
      </AppDataContainer>
      <Dialog open={isEditDialogOpen} fullWidth={true} maxWidth={"xs"}>
        <AddMerchant
          handleClose={() => setIsEditDialogOpen(false)}
          data={defMerchantData}
          mode={dialogMode}
          network={networkId}
        />
      </Dialog>
      <ErrorDialog
        isDialogOpen={errorDialogOpen}
        handleClose={() => setErrorDialogOpen(false)}
        messages={errors}
      />
      <SuccessDialog
        isDialogOpen={isSuccessDialogOpen}
        handleClose={() => setIsSuccessDialogOpen(false)}
        message={successMessage}
      />
    </>
  );
}
