import { NotificationLayout } from "components/layouts/NotificationLayout";
import logoutImg from "../assets/images/logout.svg";
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { navigateWithReload } from "utils/funcs/navigateWithReload";
import { DASHBOARD_URL } from "pages/appRoutes/appRoutesConst";

const Logout = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <NotificationLayout>
      <Stack alignItems="center">
        <img
          width={isMobile ? "350px" : "500px"}
          src={logoutImg}
          alt="logoutImg"
        />
        <Typography
          variant="h1"
          textAlign="center"
          mt={"80px"}
          sx={{ fontSize: "3rem !important" }}
        >
          {t("logout.success")}
        </Typography>
        <Button
          style={{
            fontSize: "1.5rem",
            color: theme.palette.text.darkBlue.main,
            marginTop: "24px",
            textTransform: "none",
            textDecoration: "underline",
          }}
          onClick={() => navigateWithReload(DASHBOARD_URL, { replace: true })}
        >
          {t("logout.logback")}
        </Button>
      </Stack>
    </NotificationLayout>
  );
};

export default Logout;
