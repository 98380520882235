export const parseCurrencyNum = (data: string[] | undefined) => {
  const currencies = data?.map((currency: string) => {
    if (data) {
      switch (currency) {
        case "784":
          return "AED";
        case "971":
          return "AFN";
        case "8":
          return "ALL";
        case "51":
          return "AMD";
        case "532":
          return "ANG";
        case "973":
          return "AOA";
        case "32":
          return "ARS";
        case "36":
          return "AUD";
        case "533":
          return "AWG";
        case "944":
          return "AZN";
        case "977":
          return "BAM";
        case "52":
          return "BBD";
        case "50":
          return "BDT";
        case "975":
          return "BGN";
        case "48":
          return "BHD";
        case "108":
          return "BIF";
        case "60":
          return "BMD";
        case "96":
          return "BND";
        case "68":
          return "BOB";
        case "984":
          return "BOV";
        case "986":
          return "BRL";
        case "44":
          return "BSD";
        case "64":
          return "BTN";
        case "72":
          return "BWP";
        case "933":
          return "BYN";
        case "84":
          return "BZD";
        case "124":
          return "CAD";
        case "976":
          return "CDF";
        case "947":
          return "CHE";
        case "756":
          return "CHF";
        case "948":
          return "CHW";
        case "990":
          return "CLF";
        case "152":
          return "CLP";
        case "156":
          return "CNY";
        case "170":
          return "COP";
        case "970":
          return "COU";
        case "188":
          return "CRC";
        case "931":
          return "CUC";
        case "192":
          return "CUP";
        case "132":
          return "CVE";
        case "203":
          return "CZK";
        case "262":
          return "DJF";
        case "208":
          return "DKK";
        case "214":
          return "DOP";
        case "12":
          return "DZD";
        case "818":
          return "EGP";
        case "232":
          return "ERN";
        case "230":
          return "ETB";
        case "978":
          return "EUR";
        case "242":
          return "FJD";
        case "238":
          return "FKP";
        case "826":
          return "GBP";
        case "981":
          return "GEL";
        case "936":
          return "GHS";
        case "292":
          return "GIP";
        case "270":
          return "GMD";
        case "324":
          return "GNF";
        case "320":
          return "GTQ";
        case "328":
          return "GYD";
        case "344":
          return "HKD";
        case "340":
          return "HNL";
        case "332":
          return "HTG";
        case "348":
          return "HUF";
        case "360":
          return "IDR";
        case "376":
          return "ILS";
        case "356":
          return "INR";
        case "368":
          return "IQD";
        case "364":
          return "IRR";
        case "352":
          return "ISK";
        case "388":
          return "JMD";
        case "400":
          return "JOD";
        case "392":
          return "JPY";
        case "404":
          return "KES";
        case "417":
          return "KGS";
        case "116":
          return "KHR";
        case "174":
          return "KMF";
        case "408":
          return "KPW";
        case "410":
          return "KRW";
        case "414":
          return "KWD";
        case "136":
          return "KYD";
        case "398":
          return "KZT";
        case "418":
          return "LAK";
        case "422":
          return "LBP";
        case "144":
          return "LKR";
        case "430":
          return "LRD";
        case "426":
          return "LSL";
        case "434":
          return "LYD";
        case "504":
          return "MAD";
        case "498":
          return "MDL";
        case "969":
          return "MGA";
        case "807":
          return "MKD";
        case "104":
          return "MMK";
        case "496":
          return "MNT";
        case "446":
          return "MOP";
        case "929":
          return "MRU";
        case "480":
          return "MUR";
        case "462":
          return "MVR";
        case "454":
          return "MWK";
        case "484":
          return "MXN";
        case "979":
          return "MXV";
        case "458":
          return "MYR";
        case "943":
          return "MZN";
        case "516":
          return "NAD";
        case "566":
          return "NGN";
        case "558":
          return "NIO";
        case "578":
          return "NOK";
        case "524":
          return "NPR";
        case "554":
          return "NZD";
        case "512":
          return "OMR";
        case "590":
          return "PAB";
        case "604":
          return "PEN";
        case "598":
          return "PGK";
        case "608":
          return "PHP";
        case "586":
          return "PKR";
        case "985":
          return "PLN";
        case "600":
          return "PYG";
        case "634":
          return "QAR";
        case "946":
          return "RON";
        case "941":
          return "RSD";
        case "643":
          return "RUB";
        case "646":
          return "RWF";
        case "682":
          return "SAR";
        case "90":
          return "SBD";
        case "690":
          return "SCR";
        case "938":
          return "SDG";
        case "752":
          return "SEK";
        case "702":
          return "SGD";
        case "654":
          return "SHP";
        case "925":
          return "SLE";
        case "706":
          return "SOS";
        case "968":
          return "SRD";
        case "728":
          return "SSP";
        case "930":
          return "STN";
        case "222":
          return "SVC";
        case "760":
          return "SYP";
        case "748":
          return "SZL";
        case "764":
          return "THB";
        case "972":
          return "TJS";
        case "934":
          return "TMT";
        case "788":
          return "TND";
        case "776":
          return "TOP";
        case "949":
          return "TRY";
        case "780":
          return "TTD";
        case "901":
          return "TWD";
        case "834":
          return "TZS";
        case "980":
          return "UAH";
        case "800":
          return "UGX";
        case "840":
          return "USD";
        case "997":
          return "USN";
        case "940":
          return "UYI";
        case "858":
          return "UYU";
        case "860":
          return "UZS";
        case "926":
          return "VED";
        case "937":
          return "VEF";
        case "704":
          return "VND";
        case "548":
          return "VUV";
        case "882":
          return "WST";
        case "950":
          return "XAF";
        case "951":
          return "XCD";
        case "960":
          return "XDR";
        case "952":
          return "XOF";
        case "953":
          return "XPF";
        case "994":
          return "XSU";
        case "965":
          return "XUA";
        case "886":
          return "YER";
        case "710":
          return "ZAR";
        case "967":
          return "ZMW";
        case "932":
          return "ZWL";
        default:
          return "Unknown currency code";
      }
    }
  });

  return currencies;
};

export const parseCurrencyStr = (data: string[] | undefined) => {
  const currencies = data?.map((currency: string) => {
    switch (currency) {
      case "AED":
        return "784";
      case "AFN":
        return "971";
      case "ALL":
        return "8";
      case "AMD":
        return "51";
      case "ANG":
        return "532";
      case "AOA":
        return "973";
      case "ARS":
        return "32";
      case "AUD":
        return "36";
      case "AWG":
        return "533";
      case "AZN":
        return "944";
      case "BAM":
        return "977";
      case "BBD":
        return "52";
      case "BDT":
        return "50";
      case "BGN":
        return "975";
      case "BHD":
        return "48";
      case "BIF":
        return "108";
      case "BMD":
        return "60";
      case "BND":
        return "96";
      case "BOB":
        return "68";
      case "BOV":
        return "984";
      case "BRL":
        return "986";
      case "BSD":
        return "44";
      case "BTN":
        return "64";
      case "BWP":
        return "72";
      case "BYN":
        return "933";
      case "BZD":
        return "84";
      case "CAD":
        return "124";
      case "CDF":
        return "976";
      case "CHE":
        return "947";
      case "CHF":
        return "756";
      case "CHW":
        return "948";
      case "CLF":
        return "990";
      case "CLP":
        return "152";
      case "CNY":
        return "156";
      case "COP":
        return "170";
      case "COU":
        return "970";
      case "CRC":
        return "188";
      case "CUC":
        return "931";
      case "CUP":
        return "192";
      case "CVE":
        return "132";
      case "CZK":
        return "203";
      case "DJF":
        return "262";
      case "DKK":
        return "208";
      case "DOP":
        return "214";
      case "DZD":
        return "12";
      case "EGP":
        return "818";
      case "ERN":
        return "232";
      case "ETB":
        return "230";
      case "EUR":
        return "978";
      case "FJD":
        return "242";
      case "FKP":
        return "238";
      case "GBP":
        return "826";
      case "GEL":
        return "981";
      case "GHS":
        return "936";
      case "GIP":
        return "292";
      case "GMD":
        return "270";
      case "GNF":
        return "324";
      case "GTQ":
        return "320";
      case "GYD":
        return "328";
      case "HKD":
        return "344";
      case "HNL":
        return "340";
      case "HTG":
        return "332";
      case "HUF":
        return "348";
      case "IDR":
        return "360";
      case "ILS":
        return "376";
      case "INR":
        return "356";
      case "IQD":
        return "368";
      case "IRR":
        return "364";
      case "ISK":
        return "352";
      case "JMD":
        return "388";
      case "JOD":
        return "400";
      case "JPY":
        return "392";
      case "KES":
        return "404";
      case "KGS":
        return "417";
      case "KHR":
        return "116";
      case "KMF":
        return "174";
      case "KPW":
        return "408";
      case "KRW":
        return "410";
      case "KWD":
        return "414";
      case "KYD":
        return "136";
      case "KZT":
        return "398";
      case "LAK":
        return "418";
      case "LBP":
        return "422";
      case "LKR":
        return "144";
      case "LRD":
        return "430";
      case "LSL":
        return "426";
      case "LYD":
        return "434";
      case "MAD":
        return "504";
      case "MDL":
        return "498";
      case "MGA":
        return "969";
      case "MKD":
        return "807";
      case "MMK":
        return "104";
      case "MNT":
        return "496";
      case "MOP":
        return "446";
      case "MRU":
        return "929";
      case "MUR":
        return "480";
      case "MVR":
        return "462";
      case "MWK":
        return "454";
      case "MXN":
        return "484";
      case "MXV":
        return "979";
      case "MYR":
        return "458";
      case "MZN":
        return "943";
      case "NAD":
        return "516";
      case "NGN":
        return "566";
      case "NIO":
        return "558";
      case "NOK":
        return "578";
      case "NPR":
        return "524";
      case "NZD":
        return "554";
      case "OMR":
        return "512";
      case "PAB":
        return "590";
      case "PEN":
        return "604";
      case "PGK":
        return "598";
      case "PHP":
        return "608";
      case "PKR":
        return "586";
      case "PLN":
        return "985";
      case "PYG":
        return "600";
      case "QAR":
        return "634";
      case "RON":
        return "946";
      case "RSD":
        return "941";
      case "RUB":
        return "643";
      case "RWF":
        return "646";
      case "SAR":
        return "682";
      case "SBD":
        return "090";
      case "SCR":
        return "690";
      case "SDG":
        return "938";
      case "SEK":
        return "752";
      case "SGD":
        return "702";
      case "SHP":
        return "654";
      case "SLE":
        return "925";
      case "SOS":
        return "706";
      case "SRD":
        return "968";
      case "SSP":
        return "728";
      case "STN":
        return "930";
      case "SVC":
        return "222";
      case "SYP":
        return "760";
      case "SZL":
        return "748";
      case "THB":
        return "764";
      case "TJS":
        return "972";
      case "TMT":
        return "934";
      case "TND":
        return "788";
      case "TOP":
        return "776";
      case "TRY":
        return "949";
      case "TTD":
        return "780";
      case "TWD":
        return "901";
      case "TZS":
        return "834";
      case "UAH":
        return "980";
      case "UGX":
        return "800";
      case "USD":
        return "840";
      case "USN":
        return "997";
      case "UYI":
        return "940";
      case "UYU":
        return "858";
      case "UZS":
        return "860";
      case "VED":
        return "926";
      case "VEF":
        return "937";
      case "VND":
        return "704";
      case "VUV":
        return "548";
      case "WST":
        return "882";
      case "XAF":
        return "950";
      case "XCD":
        return "951";
      case "XDR":
        return "960";
      case "XOF":
        return "952";
      case "XPF":
        return "953";
      case "XSU":
        return "994";
      case "XUA":
        return "965";
      case "YER":
        return "886";
      case "ZAR":
        return "710";
      case "ZMW":
        return "967";
      case "ZWL":
        return "932";
      default:
        return "Unknown currency code";
    }
  });
  return currencies;
};

export const currencyCodes = [
  "784",
  "971",
  "8",
  "51",
  "532",
  "973",
  "32",
  "36",
  "533",
  "944",
  "977",
  "52",
  "50",
  "975",
  "48",
  "108",
  "60",
  "96",
  "68",
  "984",
  "986",
  "44",
  "64",
  "72",
  "933",
  "84",
  "124",
  "976",
  "947",
  "756",
  "948",
  "990",
  "152",
  "156",
  "170",
  "970",
  "188",
  "931",
  "192",
  "132",
  "203",
  "262",
  "208",
  "214",
  "12",
  "818",
  "232",
  "230",
  "978",
  "242",
  "238",
  "826",
  "981",
  "936",
  "292",
  "270",
  "324",
  "320",
  "328",
  "344",
  "340",
  "332",
  "348",
  "360",
  "376",
  "356",
  "368",
  "364",
  "352",
  "388",
  "400",
  "392",
  "404",
  "417",
  "116",
  "174",
  "408",
  "410",
  "414",
  "136",
  "398",
  "418",
  "422",
  "144",
  "430",
  "426",
  "434",
  "504",
  "498",
  "969",
  "807",
  "104",
  "496",
  "446",
  "929",
  "480",
  "462",
  "454",
  "484",
  "979",
  "458",
  "943",
  "516",
  "566",
  "558",
  "578",
  "524",
  "554",
  "512",
  "590",
  "604",
  "598",
  "608",
  "586",
  "985",
  "600",
  "634",
  "946",
  "941",
  "643",
  "646",
  "682",
  "90",
  "690",
  "938",
  "752",
  "702",
  "654",
  "925",
  "706",
  "968",
  "728",
  "930",
  "222",
  "760",
  "748",
  "764",
  "972",
  "934",
  "788",
  "776",
  "949",
  "780",
  "901",
  "834",
  "980",
  "800",
  "840",
  "940",
  "858",
  "860",
  "926",
  "937",
  "704",
  "548",
  "882",
  "950",
  "951",
  "960",
  "952",
  "953",
  "994",
  "965",
  "886",
  "710",
  "967",
  "932",
];
