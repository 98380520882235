import {
  DialogTitle,
  Typography,
  IconButton,
  Box,
  useTheme,
  MenuItem,
  alpha,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import AppButton from "../appButton";
import AppTextField from "../form/textField";
import CloseIcon from "@mui/icons-material/Close";
import AppSelect from "../form/select";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "stores/store";
import { addMerchant } from "api/endpoints/merchants";
import { COLORS } from "theme/colors";
import { CountryCode, ImportStatus } from "types/enumTypes";
import React, { useState } from "react";
import { errorHandling } from "utils/funcs/errorHandling";
import LoadingDialog from "components/Dialog/LoadingDialog";
import ErrorDialog from "components/Dialog/ErrorDialog";
import FormController from "components/formController";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { MerchantImport } from "types/commonTypes";

// const DEFAULT_MERCHANT_POSITION = { x: 0, y: 999, w: 10, h: 10 };

interface Props {
  handleClose: () => void;
  createCallBack?: () => void;
  data?: MerchantImport;
  mode?: "create" | "update" | "duplicate";
  network?: string;
}

const NAME = "name";
const MERCHANT_ID = "identifier";
const STATUS = "status";
const ACQUIRER_ID = "acquirerId";
const MCC = "mcc";
const BUSINESS_ID = "businessId";
const VAT_ID = "vatId";
const ADDRESS = "address";
const CITY = "city";
const STREET = "street";
const ZIP_CODE = "zipCode";
const REGION = "region";
const COUNTRY = "country";
const LOGIN = "login";
const SIGNATURE_KEY = "signature_key";
const MERCHANT_URL = "merchant_url";

type FORM_VALUES = {
  [NAME]: string;
  [MERCHANT_ID]: string;
  [STATUS]: string;
  [ACQUIRER_ID]: string;
  [MCC]: string;
  [BUSINESS_ID]: string;
  [VAT_ID]: string;
  [ADDRESS]: object;
  [CITY]: string;
  [STREET]: string;
  [ZIP_CODE]: string;
  [REGION]: string;
  [COUNTRY]: string;
  [LOGIN]: string;
  [SIGNATURE_KEY]: string;
  [MERCHANT_URL]: string;
};

export const AddMerchant: React.FC<Props> = ({
  data,
  handleClose,
  createCallBack,
  mode = "create",
  network,
}) => {
  const { t } = useTranslation("common", {
    keyPrefix: "components.addMerchant",
  });
  const { t: tValidation } = useTranslation("", {
    keyPrefix: "validation",
  });
  const isValidNetwork =
    network && window.appConfig.REACT_APP_NETWORK_CODE?.includes(network);
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState<string[]>();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [isSurcharge, setIsSurcharge] = useState(false);

  const defaultValues = {
    [NAME]: data?.name ?? "",
    [MERCHANT_ID]: data?.identifier ?? "",
    [STATUS]: data?.status ?? "A",
    [ACQUIRER_ID]: data?.acquirerId ?? "",
    [MCC]: data?.mcc ?? "",
    [BUSINESS_ID]: data?.businessId ?? "",
    [VAT_ID]: data?.vatId ?? "",
    [ADDRESS]: {},
    [CITY]: data?.address.city ?? "",
    [STREET]: data?.address.street ?? "",
    [ZIP_CODE]: data?.address.zipCode ?? "",
    [REGION]: data?.address.country ?? "",
    [COUNTRY]: data?.address.country ?? "",
    [LOGIN]: data?.login ?? "",
    [SIGNATURE_KEY]: data?.signature_key,
    [MERCHANT_URL]: data?.merchant_url ?? "",
  };

  const schema = Joi.object({
    [NAME]: Joi.string()
      .trim()
      .max(63)
      .required()
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 63 }),
      }),
    [MERCHANT_ID]: Joi.string()
      .required()
      .max(16)
      .pattern(/^[a-zA-Z0-9]+$/)
      .messages({
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 16 }),
        "string.pattern.base": tValidation("pattern_base"),
      }),
    [STATUS]: Joi.string()
      .required()
      .messages({
        "string.empty": tValidation("required"),
      }),
    [ADDRESS]: Joi.object(),
    [ACQUIRER_ID]: Joi.string()
      .trim()
      .max(16)
      .required()
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 16 }),
      }),
    [MCC]: Joi.string()
      .trim()
      .max(4)
      .required()
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 4 }),
      }),
    [BUSINESS_ID]: Joi.string()
      .trim()
      .max(16)
      .required()
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 16 }),
      }),
    [VAT_ID]: Joi.string()
      .trim()
      .max(16)
      .required()
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 16 }),
      }),
    [STREET]: Joi.string()
      .max(32)
      .required()
      .messages({
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 32 }),
      }),
    [ZIP_CODE]: Joi.string()
      .max(16)
      .required()
      .messages({
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 16 }),
      }),
    [CITY]: Joi.string()
      .max(31)
      .required()
      .messages({
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 31 }),
      }),
    [COUNTRY]: Joi.string()
      .required()
      .messages({
        "string.empty": tValidation("required"),
      }),
    [REGION]: Joi.string().allow(""),
    [LOGIN]: Joi.string().when("$isSurcharge", {
      is: true,
      then: Joi.required().messages({
        "string.empty": tValidation("required"),
      }),
      otherwise: Joi.allow(""),
    }),
    [SIGNATURE_KEY]: Joi.string().when("$isSurcharge", {
      is: true,
      then: Joi.required().messages({
        "string.empty": tValidation("required"),
        "any.required": tValidation("required"),
      }),
      otherwise: Joi.allow(""),
    }),
    [MERCHANT_URL]: Joi.string().when("$isSurcharge", {
      is: true,
      then: Joi.required().messages({
        "string.empty": tValidation("required"),
      }),
      otherwise: Joi.allow(""),
    }),
  });

  const { networkId } = useAppSelector((state) => state.user);
  const theme = useTheme();
  const { spacing } = theme;

  const form = useForm<FORM_VALUES>({
    resolver: joiResolver(schema),
    defaultValues: defaultValues,
    context: { isSurcharge },
  });

  const { handleSubmit, control } = form;

  const onSubmit = async (formData: FORM_VALUES) => {
    const merchantData: MerchantImport = {
      [NAME]: formData[NAME],
      [MERCHANT_ID]: formData[MERCHANT_ID],
      [STATUS]: formData[STATUS],
      [ACQUIRER_ID]: formData[ACQUIRER_ID],
      [MCC]: formData[MCC],
      [BUSINESS_ID]: formData[BUSINESS_ID],
      [VAT_ID]: formData[VAT_ID],
      [ADDRESS]: {
        [CITY]: formData[CITY],
        [STREET]: formData[STREET],
        [ZIP_CODE]: formData[ZIP_CODE],
        [REGION]: formData[COUNTRY],
        [COUNTRY]: formData[COUNTRY],
      },
    };

    if (isSurcharge) {
      merchantData[LOGIN] = formData[LOGIN];
      merchantData[SIGNATURE_KEY] = formData[SIGNATURE_KEY];
      merchantData[MERCHANT_URL] = formData[MERCHANT_URL];
    }
    setLoading(true);
    await addMerchant(networkId, merchantData)
      .then(() => {
        if (createCallBack) createCallBack();
        handleClose();
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateDialogTitle = () => {
    switch (mode) {
      case "create":
        return t("title");
      case "update":
        return t("updateDialogTitle");
      case "duplicate":
        return t("duplicateDialogTitle");
      default:
        t("title");
    }
  };

  const handleSurcharge = (
    event: React.MouseEvent<HTMLElement>,
    surcharge: boolean,
  ) => {
    setIsSurcharge(surcharge);
  };

  return (
    <>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">{generateDialogTitle()}</Typography>
        <IconButton key="Settings" onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <LoadingDialog isDialogOpen={loading} />
      <ErrorDialog
        isDialogOpen={errorDialogOpen}
        handleClose={() => setErrorDialogOpen(false)}
        messages={errors}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            padding: spacing(3),
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <FormController control={control} name={NAME}>
            <AppTextField label={t(NAME)} required />
          </FormController>
          <FormController control={control} name={MERCHANT_ID}>
            <AppTextField
              label={t(MERCHANT_ID)}
              required
              disabled={mode === "update"}
            />
          </FormController>
          <FormController control={control} name={STATUS}>
            <AppSelect fullWidth label={t(STATUS)} required>
              {Object.entries(ImportStatus).map((entry, index) => (
                <MenuItem key={index} value={entry[0]}>
                  {entry[1]}
                </MenuItem>
              ))}
            </AppSelect>
          </FormController>
          <FormController control={control} name={ACQUIRER_ID}>
            <AppTextField label={t(ACQUIRER_ID)} required />
          </FormController>
          <FormController control={control} name={MCC}>
            <AppTextField label={t(MCC)} required />
          </FormController>
          <FormController control={control} name={BUSINESS_ID}>
            <AppTextField label={t(BUSINESS_ID)} required />
          </FormController>
          <FormController control={control} name={VAT_ID}>
            <AppTextField label={t(VAT_ID)} required />
          </FormController>
          <FormController control={control} name={STREET}>
            <AppTextField label={t(STREET)} required />
          </FormController>
          <FormController control={control} name={ZIP_CODE}>
            <AppTextField label={t(ZIP_CODE)} required />
          </FormController>
          <FormController control={control} name={CITY}>
            <AppTextField label={t(CITY)} required />
          </FormController>
          <FormController control={control} name={COUNTRY}>
            <AppSelect fullWidth label={t(COUNTRY)} required>
              {Object.entries(CountryCode).map((entry, index) => (
                <MenuItem key={index} value={entry[1]}>
                  {entry[0]}
                </MenuItem>
              ))}
            </AppSelect>
          </FormController>
          {isValidNetwork && (
            <>
              <Typography
                fontSize={"0.9rem"}
                fontWeight={700}
                color={theme.palette.text.lightGrey.main}
                noWrap
                component="label"
              >
                {t("surcharge")}
              </Typography>
              <ToggleButtonGroup
                value={isSurcharge}
                exclusive
                onChange={handleSurcharge}
                aria-label="text alignment"
              >
                <ToggleButton value={true} aria-label="left aligned">
                  <Typography>{t("yes")}</Typography>
                </ToggleButton>
                <ToggleButton value={false} aria-label="centered">
                  <Typography>{t("no")}</Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          )}
          {isSurcharge && (
            <>
              <FormController control={control} name={LOGIN}>
                <AppTextField label={t(LOGIN)} required />
              </FormController>
              <FormController control={control} name={SIGNATURE_KEY}>
                <AppTextField label={t(SIGNATURE_KEY)} required />
              </FormController>
              <FormController control={control} name={MERCHANT_URL}>
                <AppTextField label={t(MERCHANT_URL)} required />
              </FormController>
            </>
          )}
          <Box sx={{ alignSelf: "end" }}>
            <AppButton
              sx={{
                backgroundColor: COLORS.LIGHT_BLUE.LIGHT,
                color: COLORS.DARK_BLUE.MAIN,
                fontSize: "0.9rem",
                fontWeight: 500,
                padding: theme.spacing(2),
                ":hover": {
                  backgroundColor: alpha(theme.palette.secondary.main, 0.8),
                },
              }}
              type={"submit"}
              variant="contained"
            >
              {mode === "create" ? t("create") : t("confirm")}
            </AppButton>
          </Box>
        </Box>
      </form>
    </>
  );
};
