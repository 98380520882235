import React from "react";
import { FilePresent, PlayArrow } from "@mui/icons-material";
import {
  Card,
  styled,
  Typography,
  useTheme,
  Box,
  CardActionArea,
  alpha,
} from "@mui/material";
import AppButton from "../../components/appButton";
import AppLink from "../../components/appLink/appLink";

const UnredMessageContent = styled(CardActionArea)(
  ({ theme: { spacing } }) => ({
    display: "flex",
    width: "100%",
    padding: spacing(2),
  }),
);
const ReadMessageBox = styled(Box)(({ theme: { spacing, palette } }) => ({
  display: "flex",
  width: "100%",
  padding: spacing(2),
  marginTop: spacing(2),
  border: `2px solid ${palette.border.darkGrey}`,
  transition: "all .2s",
  cursor: "pointer",
  "&:hover": {
    borderColor: alpha(palette.border.secondary, 0.3),
  },
}));

export default function Messages() {
  const { spacing } = useTheme();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: spacing(3) }}>
      <Typography variant="h4">Messages</Typography>
      <div>
        <Typography variant="h6">Unread messages</Typography>
        <UnreadMessage />
        <UnreadMessage />
        <UnreadMessage />
      </div>
      <div>
        <Typography variant="h6">Read messages</Typography>
        <ReadMessage />
        <ReadMessage />
        <ReadMessage />
        <ReadMessage />
        <ReadMessage />
        <ReadMessage />
        <ReadMessage />
      </div>
      <div>
        <AppButton variant="contained">Load 10 more</AppButton>
      </div>
    </Box>
  );
}

interface MessageProps {
  date?: Date;
  title?: string;
  file?: boolean;
}

const UnreadMessage: React.FC<MessageProps> = () => {
  const { spacing } = useTheme();
  return (
    <Card sx={{ p: 0, marginTop: spacing(2) }}>
      <AppLink to="1">
        <UnredMessageContent>
          <Typography fontWeight={300} sx={{ width: "150px" }}>
            9.11.2021 14:34
          </Typography>
          <Typography fontWeight={700}>
            We have updated our Terms of Service
          </Typography>
          <FilePresent sx={{ ml: spacing(1) }} />
          <PlayArrow sx={{ marginLeft: "auto" }} />
        </UnredMessageContent>
      </AppLink>
    </Card>
  );
};

const ReadMessage: React.FC<MessageProps> = () => {
  const { spacing } = useTheme();
  return (
    <AppLink to="2">
      <ReadMessageBox>
        <Typography fontWeight={300} sx={{ width: "150px" }}>
          9.11.2021 14:34
        </Typography>
        <Typography fontWeight={700}>
          We have updated our Terms of Service
        </Typography>
        <FilePresent sx={{ ml: spacing(1) }} />
        <PlayArrow sx={{ marginLeft: "auto" }} />
      </ReadMessageBox>
    </AppLink>
  );
};
