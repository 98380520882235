import {
  Box,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useLanguageSelect from "utils/hooks/useLanguageSelect";
import switchioLogo from "../../assets/images/switchio-grey.svg";
import proidLogo from "../../assets/images/proid-grey.svg";
import { COLORS } from "theme/colors";
import React from "react";

interface Props {
  children: React.ReactNode;
}

const Layout = styled(Stack)(({ theme: { breakpoints, spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  background: "linear-gradient(to bottom right, #edf2f7, #e1e8f0)",
  width: "100%",

  [breakpoints.down("md")]: {
    padding: spacing(1, 5),
  },
}));

const NavBar = styled(Box)(() => {
  return {
    position: "absolute",
    top: 40,
    right: 210,
  };
});

const Footer = styled(Box)(({ theme: { spacing } }) => ({
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "end",
  gap: spacing(1.5),
  paddingBottom: spacing(4),
}));

const PageContainer = styled(Box)(() => ({
  flex: "1 0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const NotificationLayout = ({ children }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const languageSelect = useLanguageSelect({ isMobile, isAuthorized: false });

  return (
    <Layout>
      <NavBar>{languageSelect}</NavBar>
      <PageContainer>{children}</PageContainer>
      <Footer>
        <Typography
          color={COLORS.DARK_GRAY.MAIN}
          lineHeight={"0.8rem"}
          fontSize={"1rem"}
        >
          Powered by
        </Typography>
        <Box
          sx={{
            borderRight: `1px solid ${COLORS.DARK_GRAY.MAIN}`,
            paddingRight: theme.spacing(1.5),
            display: "flex",
            alignItems: "center",
          }}
        >
          <img src={switchioLogo} alt="switchio logo" />
        </Box>
        <img src={proidLogo} alt="switchio logo" />
      </Footer>
    </Layout>
  );
};
