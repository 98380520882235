import { InputBase, styled } from "@mui/material";
import { COLORS } from "theme/colors";

const AppInputBase = styled(InputBase)(({ theme: { palette, spacing } }) => ({
  "&.MuiInputBase-root": {
    borderRadius: "8px",
    border: `1px solid ${palette.border.darkGrey}`,
    padding: 0,
    display: "flex",
    alignItems: "center",
  },
  "& .MuiInputBase-input": {
    padding: spacing(1.5, 2),
    fontSize: "0.9rem",
    fontWeight: 400,
    color: COLORS.GREY.MAIN,
    borderColor: `${COLORS.GREY.MAIN}`,
    backgroundColor: "transparent",
    boxSizing: "border-box",
  },
  "&:hover": {
    borderColor: palette.border.secondary,
  },
  "&:focus-within": {
    borderColor: palette.border.secondary,
    borderWidth: "1px",
  },
  "&.Mui-focused": {
    borderColor: palette.border.secondary,
    borderWidth: "1px",
  },
}));

export default AppInputBase;
