import {
  styled,
  TextField as MuiTextField,
  TextFieldProps,
} from "@mui/material";
import FormField from "../formField";

export const notBold = { "& .MuiInputBase-root": { fontWeight: 500 } };

const StyledTextField = styled(MuiTextField)(
  ({ theme: { palette, spacing } }) => ({
    "& .MuiInputBase-root": {
      height: "40px",
      fontSize: "0.9rem",
      padding: spacing(1.5, 2),
      borderRadius: "8px",
      "& input": {
        padding: 0,
        color: palette.text.lightGrey.main,
        fontWeight: 400,
      },
    },
    "&.MuiTextField-root": {
      backgroundColor: "transparent",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: palette.border.darkGrey,
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: palette.border.secondary,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: palette.border.secondary,
      borderWidth: "1px",
    },
  }),
);

const AppTextField: React.FC<
  TextFieldProps & {
    errorText?: string;
    inputLabel?: string;
    optional?: boolean;
  }
> = ({ label, error, errorText, required, inputLabel, optional, ...rest }) => {
  const inputId =
    rest.id || `app-text-field-${Math.random().toString(36).substr(2, 9)}`;
  return (
    <FormField
      label={label}
      required={required}
      error={error}
      errorText={errorText}
      optional={optional}
      htmlFor={inputId}
    >
      <StyledTextField
        id={inputId}
        fullWidth
        error={error}
        {...rest}
        label={inputLabel}
      />
    </FormField>
  );
};

export default AppTextField;
