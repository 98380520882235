import React, { createContext, useState } from "react";

import {
  TerminalData,
  TerminalProfile,
  TerminalProfiles,
} from "types/commonTypes";
const TerminalContext = createContext<newTerminalData>({} as newTerminalData);

type NewTerminalContextProviderProps = {
  children: React.ReactNode;
};

type newTerminalData = {
  profileName: string;
  setProfileName: React.Dispatch<React.SetStateAction<string>>;
  profilesName: TerminalProfiles[] | null;
  setProfilesName: React.Dispatch<React.SetStateAction<TerminalProfiles[]>>;
  terminalProfile: TerminalProfile;
  newTerminal: TerminalData;
  setNewTerminal: React.Dispatch<React.SetStateAction<TerminalData>>;
  setTerminalProfile: React.Dispatch<React.SetStateAction<TerminalProfile>>;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  nextStep: () => void;
  prevStep: () => void;
  setSubmitHandler: React.Dispatch<React.SetStateAction<(() => void) | null>>;
};

export function TerminalContextProvider({
  children,
}: NewTerminalContextProviderProps) {
  const [profilesName, setProfilesName] = useState<TerminalProfiles[]>([]);
  const [terminalProfile, setTerminalProfile] = useState<TerminalProfile>(
    {} as TerminalProfile,
  );
  const [profileName, setProfileName] = useState<string>("");
  const [newTerminal, setNewTerminal] = useState<TerminalData>(
    {} as TerminalData,
  );
  const [activeStep, setActiveStep] = useState(0);
  const [submitHandler, setSubmitHandler] = useState<(() => void) | null>(null);

  const nextStep = () => {
    if (submitHandler) {
      submitHandler();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const prevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <TerminalContext.Provider
      value={{
        profilesName,
        profileName,
        terminalProfile,
        setProfilesName,
        setProfileName,
        setTerminalProfile,
        newTerminal,
        setNewTerminal,
        activeStep,
        setActiveStep,
        nextStep,
        prevStep,
        setSubmitHandler,
      }}
    >
      {children}
    </TerminalContext.Provider>
  );
}

export default TerminalContext;
