import { Typography } from "@mui/material";

type Props = {
  title: string;
};
export const PageTitle: React.FC<Props> = ({ title }) => {
  return (
    <Typography fontSize={"2.5rem"} fontWeight={700}>
      {title}
    </Typography>
  );
};
