import { useGlobalContext } from "GlobalContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_URL, ERROR_PAGE } from "./appRoutes/appRoutesConst";
import { addAuthData } from "api";
import { parseRoleContext } from "utils/funcs/convert";
import { useDispatch } from "react-redux";
import {
  IAMLang,
  setAccountUrl,
  setIAMLang,
  setUserApps,
  setUserRole,
} from "stores/slices/userSlice";
import { useRoleDetails } from "utils/hooks/useRoleDetails";
import { findGroupById } from "utils/funcs/findGroupById";
import { Claims, OidcRpLoginData, UserRole } from "types/commonTypes";
import { useTranslation } from "react-i18next";
import { navigateWithReload } from "utils/funcs/navigateWithReload";

const Callback = () => {
  const { oidcRpClient, SetOidcRpLoginData } = useGlobalContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const isLocaleSupported = (locale: string) =>
    ["cs", "en", "fr", "nl", "es", "de"].includes(locale);
  const getCurrentLocale = () =>
    localStorage.getItem("TMSLang") ?? i18n.resolvedLanguage;

  const login = () => {
    const roleIdFromLS = localStorage.getItem("roleIdTMS")
      ? JSON.parse(localStorage.getItem("roleIdTMS") ?? "")
      : undefined;
    // Na localhoste sposobuje chybu Error: missing code verifier in storage pretoze kvoli <React.StrictMode> sa odpali 2 krat mount
    oidcRpClient
      .handleLoginWithRedirectCallback()
      .then((x) => {
        if (x.type === "error") {
          navigateWithReload(ERROR_PAGE, { replace: true });
          throw new Error(`login error: ${x.error}`);
        }

        const roleId =
          roleIdFromLS ?? (x.idToken?.claims as any)?.groups[0]?.roles[0]?.id;

        const claims = x.idToken?.claims as Claims;
        const groups = claims.groups;
        const manageAccountUrl = claims.manageAccountUrl;
        const apps = claims.apps;
        const accessToken = x.accessToken;

        if (claims.setupAccountUrl || /* BC */ !claims.phoneNumber) {
          const setupAccountUrl = new URL(
            claims.setupAccountUrl || /* BC */ claims.manageAccountUrl,
          );
          // ⚠ IAM will redirect back to the URL provided as the "referrer" param once the setup is complete.
          const referrerUrl = new URL(x.state ?? "", document.baseURI).href;
          setupAccountUrl.searchParams.set("referrer", referrerUrl);
          // Redirect to IAM
          window.location.assign(setupAccountUrl);
          return;
        }

        const { role, context } = useRoleDetails(x as OidcRpLoginData, roleId);

        const preferredLocale = claims.locale;
        if (preferredLocale === getCurrentLocale()) {
          // Whenever preferred and current locale becomes the same we want to mark them as synced.
          localStorage.setItem("sync-locale", "yes");
        }
        if (
          localStorage.getItem("sync-locale") !== "no" &&
          isLocaleSupported(preferredLocale)
        ) {
          // User did not explicitely set different language for this app and our app supports the preferred locale.
          localStorage.setItem("sync-locale", "yes");
          localStorage.setItem("TMSLang", preferredLocale);
        }

        localStorage.setItem("roleIdTMS", JSON.stringify(role ? role.id : ""));

        const parsedRoleContext = parseRoleContext(context ?? "");
        SetOidcRpLoginData(x);
        window.userGroups = groups || [];
        window.userRoleId = role?.id || "";
        window.userRole = role || {};
        window.userGroup = findGroupById(groups || [], roleId) ?? groups[0];
        dispatch(
          setUserRole({
            ...parsedRoleContext,
            role: role || ({} as UserRole),
          }),
        );

        dispatch(setAccountUrl({ accountUrl: manageAccountUrl }));

        dispatch(setIAMLang({ lang: claims.locale as IAMLang["lang"] }));

        dispatch(
          setUserApps({
            userApps: apps,
          }),
        );
        addAuthData(accessToken);

        if (x.state) {
          navigate(x.state, { replace: true });
        } else {
          navigate(DASHBOARD_URL, { replace: true });
        }

        return;
      })
      .catch((err) => {
        console.error(err);
        navigateWithReload(ERROR_PAGE, { replace: true });
      });
  };

  useEffect(() => {
    login();
  }, []);

  return null;
};

export default Callback;
