import { useContext, useEffect, useMemo, useState } from "react";
import { AddCircle, Close } from "@mui/icons-material";
import { alpha, Box, styled, Typography, useTheme } from "@mui/material";
import AppButton from "../../components/appButton";
import { AppTable, AppTableProps } from "../../components/appTable";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
import ImportContext from "./ImportContext";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  Hardware,
  LocationType,
  MerchantImport,
  TerminalImportRow,
  TLocation,
} from "types/commonTypes";
import { useAppSelector } from "stores/store";
import { sendImportedTerminals } from "api/endpoints/terminals";
import { useNavigate } from "react-router-dom";
import {
  IMPORT_HARDWARE_URL,
  IMPORT_LOCATIONS_URL,
  IMPORT_MERCHANTS_URL,
  IMPORT_PAGES,
  IMPORT_TERMINAL_URL,
} from "pages/appRoutes/appRoutesConst";
import { ImportType } from "types/enumTypes";
import { sendImportedMerchants } from "api/endpoints/merchants";
import { sendImportedLocations } from "api/endpoints/locations";
import { sendImportedHardware } from "api/endpoints/hardware";
import { Status } from "components/status";
import { useTranslation } from "react-i18next";
import { appErrorHandler } from "utils/funcs/appErrorHandler";

const RowBox = styled(Box)(({ theme: { spacing, breakpoints } }) => ({
  display: "grid",
  gap: spacing(2, 1),
  gridTemplateColumns: "repeat(8, auto)",
  p: {
    paddingRight: spacing(2),
  },
  padding: spacing(4, 0, 2),
  flexWrap: "wrap",
  justifyContent: "flex-start",
  [breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(4, auto)",
  },
}));

interface ImportProps {
  type: ImportType;
}

export default function ImportOverview(props: ImportProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { type } = props;
  const [loading, setLoading] = useState(false);
  const { networkId } = useAppSelector((state) => state.user);
  const [editedData, setEditedData] = useState<
    TerminalImportRow[] | MerchantImport[] | TLocation[] | Hardware[]
  >([]);
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation("common", {
    keyPrefix: "components.import.importOverview",
  });
  const { importRowsData, setImportRowsData } = useContext(ImportContext);
  const saveTerminalData = async () => {
    setLoading(true);
    const response = await sendImportedTerminals(
      networkId,
      editedData as TerminalImportRow[],
    );
    if (response.status === 200) {
      setImportRowsData([]);
      setLoading(false);
      navigate(`/${IMPORT_TERMINAL_URL}/${IMPORT_PAGES.DONE_URL}`);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEditedData(importRowsData ?? []);
  }, []);

  const MERCHANT_COLUMNS: AppTableProps["columns"] = useMemo(
    () => [
      {
        field: "ID",
        headerName: "id",
        renderCell: (params: GridRenderCellParams<MerchantImport>) => {
          const { row } = params;
          return <>{row.identifier}</>;
        },
        flex: 1,
      },
      {
        field: "Name",
        headerName: "name",
        renderCell: (params: GridRenderCellParams<MerchantImport>) => {
          const { row } = params;
          return <>{row.name}</>;
        },
        flex: 1,
      },
      {
        field: "Acquirer ID",
        headerName: "acquirerId",
        renderCell: (params: GridRenderCellParams<MerchantImport>) => {
          const { row } = params;
          return <>{row.acquirerId}</>;
        },
        flex: 1,
      },
      {
        field: "Business ID",
        headerName: "businessId",
        renderCell: (params: GridRenderCellParams<MerchantImport>) => {
          const { row } = params;
          return <>{row.businessId}</>;
        },
        flex: 1,
      },
      {
        field: "Vat ID",
        headerName: "vatId",
        renderCell: (params: GridRenderCellParams<MerchantImport>) => {
          const { row } = params;
          return <>{row.vatId}</>;
        },
        flex: 1,
      },
      {
        field: "Status",
        headerName: "status",
        renderCell: (params: GridRenderCellParams<MerchantImport>) => {
          const { row } = params;
          return <Status status={row.status} />;
        },
        flex: 1,
      },
      {
        field: "Cross",
        headerName: "",
        renderCell: (params: GridRenderCellParams<MerchantImport>) => {
          const { row } = params;
          return (
            <>
              {
                <Close
                  cursor={"pointer"}
                  onClick={() => {
                    removeMerchantRow(row.identifier);
                  }}
                />
              }
            </>
          );
        },
      },
    ],
    [editedData, importRowsData, resolvedLanguage],
  );

  const TERMINALS_COLUMNS: AppTableProps["columns"] = useMemo(
    () => [
      {
        field: "ID",
        headerName: "id",
        renderCell: (params: GridRenderCellParams<TerminalImportRow>) => {
          const { row } = params;
          return <>{row.identifier}</>;
        },
        flex: 1,
      },
      {
        field: "Name",
        headerName: "name",
        renderCell: (params: GridRenderCellParams<TerminalImportRow>) => {
          const { row } = params;
          return <>{row.name}</>;
        },
        flex: 1,
      },
      {
        field: "Acquirer ID",
        headerName: "acquirerId",
        renderCell: (params: GridRenderCellParams<TerminalImportRow>) => {
          const { row } = params;
          return <>{row.acquirerId}</>;
        },
        flex: 1,
      },
      {
        field: "Merchant",
        headerName: "merchant",
        renderCell: (params: GridRenderCellParams<TerminalImportRow>) => {
          const { row } = params;

          return <>{row.merchantIdentifier}</>;
        },
        flex: 1,
      },
      {
        field: "location",
        headerName: "location",
        renderCell: (params: GridRenderCellParams<TerminalImportRow>) => {
          const { row } = params;
          return <>{row.locationIdentifier}</>;
        },
        flex: 1,
      },
      {
        field: "Platform",
        headerName: "platform",
        renderCell: (params: GridRenderCellParams<TerminalImportRow>) => {
          const { row } = params;
          return <>{row.platform}</>;
        },
        flex: 1,
      },
      {
        field: "Status",
        headerName: "status",
        renderCell: (params: GridRenderCellParams<TerminalImportRow>) => {
          const { row } = params;
          return <Status status={row.status} />;
        },
        flex: 1,
      },
      {
        field: "Cross",
        headerName: "",
        renderCell: (params: GridRenderCellParams<TerminalImportRow>) => {
          const { row } = params;
          return (
            <>
              {
                <Close
                  cursor={"pointer"}
                  onClick={() => {
                    removeTerminalRow(row.identifier);
                  }}
                />
              }
            </>
          );
        },
      },
    ],
    [editedData, importRowsData, resolvedLanguage],
  );

  const LOCATION_COLUMNS: AppTableProps["columns"] = useMemo(
    () => [
      {
        field: "ID",
        headerName: "id",
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return <>{row.identifier}</>;
        },
        flex: 1,
      },
      {
        field: "Name",
        headerName: "name",
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return <>{row.name}</>;
        },
        flex: 1,
      },
      {
        field: "Acquirer ID",
        headerName: "acquirerId",
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return <>{row.acquirerId}</>;
        },
        flex: 1,
      },
      {
        field: "Merchant",
        headerName: "merchant",
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return <>{row.merchantIdentifier}</>;
        },
        flex: 1,
      },
      {
        field: "Status",
        headerName: "status",
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return <Status status={row.status} />;
        },
        flex: 1,
      },
      {
        field: "Cross",
        headerName: "",
        renderCell: (params: GridRenderCellParams<TLocation>) => {
          const { row } = params;
          return (
            <>
              {
                <Close
                  cursor={"pointer"}
                  onClick={() => {
                    removeLocationsRow(row.identifier);
                  }}
                />
              }
            </>
          );
        },
      },
    ],
    [editedData, importRowsData, resolvedLanguage],
  );

  const HARDWARE_COLUMNS: AppTableProps["columns"] = useMemo(
    () => [
      {
        field: "Serial number",
        headerName: "serialNumber",
        renderCell: (params: GridRenderCellParams<Hardware>) => {
          const { row } = params;

          return <>{row.serial_number}</>;
        },
        flex: 1,
      },
      {
        field: "Category",
        headerName: "category",
        renderCell: (params: GridRenderCellParams<Hardware>) => {
          const { row } = params;

          return <>{row.category}</>;
        },
        flex: 1,
      },
      {
        field: "Type",
        headerName: "type",
        renderCell: (params: GridRenderCellParams<Hardware>) => {
          const { row } = params;

          return <>{row.type}</>;
        },
        flex: 1,
      },
      {
        field: "Platform",
        headerName: "platform",
        renderCell: (params: GridRenderCellParams<Hardware>) => {
          const { row } = params;

          return <>{row.platform}</>;
        },
        flex: 1,
      },
      {
        field: "Status",
        headerName: "status",
        renderCell: (params: GridRenderCellParams<Hardware>) => {
          const { row } = params;

          return <Status status={row.status} />;
        },
        flex: 1,
      },
      {
        field: "Cross",
        headerName: "",
        renderCell: (params: GridRenderCellParams<TLocation>) => {
          const { row } = params;
          return (
            <>
              {
                <Close
                  cursor={"pointer"}
                  onClick={() => {
                    removeHardwareRow(row.identifier);
                  }}
                />
              }
            </>
          );
        },
      },
    ],
    [editedData, importRowsData, resolvedLanguage],
  );

  const removeTerminalRow = (rowId: string) => {
    const newData =
      (editedData as TerminalImportRow[])?.filter(
        (row) => row.identifier != rowId,
      ) ?? null;
    setEditedData(newData);
  };

  const saveMerchantData = async () => {
    setLoading(true);
    try {
      await sendImportedMerchants(networkId, editedData as MerchantImport[]);
      setImportRowsData([]);
      setLoading(false);
      navigate(`/${IMPORT_MERCHANTS_URL}/${IMPORT_PAGES.DONE_URL}`);
    } catch (error) {
      setLoading(false);
      appErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const saveLocationData = async () => {
    setLoading(true);

    try {
      await sendImportedLocations(networkId, editedData as TLocation[]);
      setImportRowsData([]);
      setLoading(false);
      navigate(`/${IMPORT_LOCATIONS_URL}/${IMPORT_PAGES.DONE_URL}`);
    } catch (error) {
      setLoading(false);
      appErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const saveHardwareData = async () => {
    setLoading(true);
    const response = await sendImportedHardware(
      networkId,
      editedData as Hardware[],
    );
    if (response.status === 200) {
      setImportRowsData([]);
      setLoading(false);
      navigate(`/${IMPORT_HARDWARE_URL}/${IMPORT_PAGES.DONE_URL}`);
    } else {
      setLoading(false);
    }
  };

  const removeMerchantRow = (rowId: string) => {
    const newData =
      (editedData as TerminalImportRow[])?.filter(
        (row) => row.identifier != rowId,
      ) ?? null;
    setEditedData(newData);
  };
  const removeLocationsRow = (rowId: string) => {
    const newData =
      (editedData as TLocation[])?.filter((row) => row.identifier != rowId) ??
      null;
    setEditedData(newData);
  };
  const removeHardwareRow = (rowId: string) => {
    const newData =
      (editedData as Hardware[])?.filter((row) => row.identifier != rowId) ??
      null;
    setEditedData(newData);
  };

  const saveData = () => {
    if (type == ImportType.TERMINAL) {
      saveTerminalData();
    } else if (type == ImportType.MERCHANT) {
      saveMerchantData();
    } else if (type == ImportType.LOCATION) {
      saveLocationData();
    } else if (type == ImportType.HARDWARE) {
      saveHardwareData();
    } else {
      console.warn("type");
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const getColumnsForType = () => {
    if (type == ImportType.TERMINAL) {
      return TERMINALS_COLUMNS;
    } else if (type == ImportType.MERCHANT) {
      return MERCHANT_COLUMNS;
    } else if (type == ImportType.LOCATION) {
      return LOCATION_COLUMNS;
    } else if (type == ImportType.HARDWARE) {
      return HARDWARE_COLUMNS;
    } else {
      return TERMINALS_COLUMNS;
    }
  };

  return (
    <main>
      <LoadingDialog isDialogOpen={loading} />
      <Typography variant="h4">{t("title")}</Typography>

      <RowBox>
        <AddCircle />
        <Typography>{`${editedData?.length} ${t("rowsAmount")}`} </Typography>
      </RowBox>
      <AppTable
        rows={editedData ?? []}
        columns={getColumnsForType()}
        appTitle={t("appTitle")}
        rowsPerPage={[15, 20, 25]}
        showSettings={false}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1rem",
          pt: theme.spacing(2),
        }}
      >
        <AppButton
          variant="outlined"
          sx={{
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            ":hover": {
              backgroundColor: alpha(theme.palette.secondary.main, 0.1),
            },
          }}
          onClick={handleCancel}
        >
          {t("cancelBtn")}
        </AppButton>
        <AppButton variant="contained" onClick={saveData}>
          {t("continueBtn")}
        </AppButton>
      </Box>
    </main>
  );
}
