import {
  ClickAwayListener,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Box,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AppTextField from "../../../components/form/textField";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import AppLink from "../../appLink/appLink";
import { SearchGroup } from "../../../types/commonTypes";
import { useTranslation } from "react-i18next";
import { COLORS } from "theme/colors";
import { useSearchTermsQuery } from "api/endpoints/searchBox";
import { useAppSelector } from "stores/store";
import {
  MERCHANTS_URL,
  LOCATIONS_URL,
  TERMINALS_URL,
} from "pages/appRoutes/appRoutesConst";
import FullscreenLoader from "components/fullscreenLoader";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SearchGroupsWrap = styled(MenuList)(({ theme: { spacing } }) => ({
  "& > li": {
    padding: 0,
    "&.Mui-disabled": {
      marginTop: spacing(2),
      opacity: 1,
      "&:first-of-type": {
        marginTop: 0,
      },
    },
    "& > h4, & > a, & > div > span > a": {
      padding: spacing(0.7, 2),
      display: "block",
    },
  },
}));

const GroupTitle = styled("h4")(({ theme: { palette } }) => ({
  color: palette.text.lightGrey.main,
  fontWeight: 700,
  fontSize: "1rem",
  margin: 0,
}));

const ItemTitle = styled(ListItemText)(({ theme: { palette } }) => ({
  color: palette.text.lightGrey.main,
  "> span": {
    fontWeight: 400,
    fontSize: "0.875rem",
    "> a": {
      textDecoration: "none",
    },
  },
}));

const SeeAllLink = styled(NavLink)(({ theme: { palette } }) => ({
  color: palette.secondary.light,
  fontWeight: 400,
  fontSize: "0.875rem",
  textDecoration: "none",
  flexGrow: "1",
}));

const NoResults = styled("div")(({ theme: { spacing, palette } }) => ({
  color: palette.text.lightGrey.main,
  fontWeight: 400,
  fontSize: "0.875rem",
  padding: spacing(0.7, 2),
}));

const SearchInput = styled(AppTextField)(() => ({
  ".MuiOutlinedInput-input": {
    fontSize: "0.8rem",
    fontWeight: "normal",
  },
}));

const SearchGroupSection: React.FC<{
  items:
    | SearchGroup["locations"]
    | SearchGroup["terminals"]
    | SearchGroup["merchants"];
  title: string;
  seeAllText: string;
  detailUrl?: string;
  seeAllUrl: string;
  onTermClickHandler: () => void;
  state?: { merchantId: string; locationId?: string };
}> = ({
  items,
  title,
  seeAllText,
  detailUrl,
  seeAllUrl,
  onTermClickHandler,
  state,
}) => {
  return (
    <>
      <MenuItem disabled>
        <GroupTitle>{title}</GroupTitle>
      </MenuItem>
      {items.slice(0, 3).map((item, index) => (
        <MenuItem key={index}>
          <ItemTitle>
            <AppLink
              to={`${seeAllUrl}/${item.identifier}/${detailUrl ?? ""}`}
              onClick={onTermClickHandler}
              state={state}
            >
              {item.name}
            </AppLink>
          </ItemTitle>
        </MenuItem>
      ))}
      {items.length > 3 && (
        <MenuItem>
          <SeeAllLink to={seeAllUrl}>{seeAllText}</SeeAllLink>
        </MenuItem>
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SearchBoxGroup: React.FC<{
  results: SearchGroup;
  onTermClickHandler: () => void;
}> = ({ results, onTermClickHandler }) => {
  const { t } = useTranslation("common", { keyPrefix: "components.searchBox" });
  const { locations, terminals, merchants } = results;

  return (
    <>
      {/* Transactions */}
      {merchants.length > 0 && (
        <SearchGroupSection
          items={merchants}
          title={t("merchantsTitle")}
          seeAllText={t("seeAllMerchants")}
          seeAllUrl={`/${MERCHANTS_URL}`}
          onTermClickHandler={onTermClickHandler}
        />
      )}{" "}
      {/* Locations */}
      {locations.length > 0 && (
        <SearchGroupSection
          items={locations}
          title={t("locationsTitle")}
          seeAllText={t("seeAllLocations")}
          detailUrl={`${locations[0].merchant_identifier}`}
          seeAllUrl={`/${LOCATIONS_URL}`}
          onTermClickHandler={onTermClickHandler}
        />
      )}{" "}
      {/* Terminals */}
      {terminals.length > 0 && (
        <SearchGroupSection
          items={terminals}
          title={t("terminalsTitle")}
          seeAllText={t("seeAllTerminals")}
          detailUrl={`${terminals[0].merchant.identifier}/${terminals[0].location.identifier}`}
          seeAllUrl={`${TERMINALS_URL}`}
          onTermClickHandler={onTermClickHandler}
        />
      )}
      {locations.length === 0 && terminals.length === 0 && (
        <MenuItem disabled>
          <NoResults>{t("noResults")}</NoResults>
        </MenuItem>
      )}
    </>
  );
};

export const SearchBox = () => {
  const theme = useTheme();
  const [searchedText, setSearchedText] = useState("");
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
  const [isClearBtnVisible, setIsClearBtnVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [canBeSendQuery, setCanBeSendQuery] = useState<boolean>(false);
  const { networkId } = useAppSelector((state) => state.user);
  const { t } = useTranslation("common", { keyPrefix: "components.searchBox" });
  const { data, isLoading } = useSearchTermsQuery(
    networkId,
    searchedText,
    canBeSendQuery,
  );
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClearSearchBox = () => {
    setSearchedText("");
    setIsClearBtnVisible(false);
    setIsSearchBoxOpen(false);
  };

  const searchedTermClickHandler = () => {
    setIsSearchBoxOpen(false);
  };

  const handleIsFocused = () => {
    if (searchedText.length >= 3) {
      setIsSearchBoxOpen(true);
    }
  };

  const handleChangeSearchValue = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCanBeSendQuery(false);
    setSearchedText(event.target.value);
    setAnchorEl(event.currentTarget.parentElement);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchedText.length >= 3) {
        setIsSearchBoxOpen(true);
        setCanBeSendQuery(true);
      }
    }, 500);
    if (searchedText.length < 3) setIsSearchBoxOpen(false);
    setIsClearBtnVisible(searchedText.length > 0);
    return () => clearTimeout(timer);
  }, [searchedText]);

  if (isLoading) return <FullscreenLoader />;

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setIsSearchBoxOpen(false);
        }}
      >
        <Box
          sx={{
            width: isTablet ? "100%" : "370px",
            background: COLORS.LIGHT_GREY.MAIN,
            borderRadius: "8px",
          }}
        >
          <SearchInput
            placeholder={t("search")}
            onChange={handleChangeSearchValue}
            onFocus={handleIsFocused}
            value={searchedText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ width: 35 }}>
                  {isClearBtnVisible && (
                    <IconButton onClick={handleClearSearchBox} edge="end">
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Popper
            style={{ zIndex: 100 }}
            open={isSearchBoxOpen}
            placement="bottom-start"
            anchorEl={anchorEl}
          >
            <Paper
              sx={{
                width: 370,
                maxWidth: "100%",
                marginTop: "0.5rem",
              }}
            >
              {data && (
                <SearchGroupsWrap>
                  <SearchBoxGroup
                    results={data}
                    onTermClickHandler={searchedTermClickHandler}
                  />
                </SearchGroupsWrap>
              )}
            </Paper>
          </Popper>
        </Box>
      </ClickAwayListener>
    </>
  );
};
