import {
  ClickAwayListener,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import { useState } from "react";
import { useAppSelector } from "stores/store";

export const UserAppsMenu = () => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { userApps } = useAppSelector((state) => state.user);
  const menuItems = userApps.map((app) => ({
    label: app.name,
    icon: <img width={"20px"} height={"20px"} src={app.logo} />,
    onClick: () => {
      window.location.href = app.url;
    },
  }));

  return (
    <>
      <IconButton
        sx={{ height: "fit-content", alignSelf: "center", padding: 0 }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setIsPopperOpen(!isPopperOpen);
        }}
      >
        <AppsIcon fontSize={"large"} color="secondary" />
      </IconButton>
      <Popper
        style={{ zIndex: 10 }}
        open={isPopperOpen}
        placement="bottom-start"
        disablePortal={false}
        anchorEl={anchorEl}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (isPopperOpen) setIsPopperOpen(false);
          }}
        >
          <Paper sx={{ width: 200, maxWidth: "100%" }}>
            <MenuList>
              {menuItems.map((menuItem, index) => (
                <MenuItem key={index} onClick={menuItem.onClick}>
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText>{menuItem.label}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
