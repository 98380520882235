import { Download } from "@mui/icons-material";
import {
  Card,
  styled,
  Typography,
  useTheme,
  alpha,
  List,
  ListItem,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  downloadMerchantsTemplate,
  importMerchants,
} from "api/endpoints/merchants";
import {
  downloadTerminalsTemplate,
  importTerminals,
} from "api/endpoints/terminals";
import { IMPORT_PAGES } from "pages/appRoutes/appRoutesConst";
import { useCallback, useContext, useState } from "react";
import Dropzone from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "stores/store";
import { ImportType } from "types/enumTypes";
import AppButton from "../../components/appButton";
import LoadingDialog from "../../components/Dialog/LoadingDialog";
import ImportContext, { importDataTypes } from "./ImportContext";
import {
  downloadLocationsTemplate,
  importLocations,
} from "api/endpoints/locations";
import {
  downloadHardwareTemplate,
  importHardware,
} from "api/endpoints/hardware";
import { AxiosResponse } from "axios";
import Dialog from "components/Dialog";
import { useTranslation } from "react-i18next";

const UploadWrapper = styled(Box)(({ theme: { spacing } }) => ({
  display: "grid",
  marginTop: spacing(2),
  gap: spacing(2),
  cursor: "pointer",
}));

const UploadBox = styled(Card)(() => ({
  width: "100%",
  height: "50vh",
  display: "grid",
  placeItems: "center",
}));

interface ImportProps {
  type: ImportType;
}

interface ErrorType {
  dataRow: number;
  message: string;
}

export default function Import(props: ImportProps) {
  const theme = useTheme();
  const { type } = props;
  const { networkId } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { setImportRowsData } = useContext(ImportContext);
  const [errors, setErrors] = useState<string | ErrorType[]>();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const { t } = useTranslation("common", {
    keyPrefix: "components.import",
  });
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  }, []);
  const [loading, setLoading] = useState(false);
  const handleImport = async (
    file: File,
    importFunction: (
      networkId: string,
      file: File,
    ) => Promise<AxiosResponse<importDataTypes>>,
  ): Promise<void> => {
    setLoading(true);
    await importFunction(networkId, file)
      .then((response) => {
        if (response && response.status === 200) {
          setImportRowsData(response.data);
          navigate(`${IMPORT_PAGES.OVERVIEW_URL}`);
        }
      })
      .catch((error) => {
        if (
          error.response.status === 400 &&
          error.response.data.errorMessages
        ) {
          setErrors(error.response.data.errorMessages);
          setErrorDialogOpen(true);
        } else if (
          error.response.status === 400 &&
          error.response.data.errorMessage
        ) {
          setErrors(error.response.data.errorMessage);
          setErrorDialogOpen(true);
        } else if (error.response.status === 500) {
          setErrors(t("internalError"));
          setErrorDialogOpen(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const sendTerminalImport = (): void => {
    if (file) {
      handleImport(file, importTerminals);
    }
  };

  const sendMerchantImport = (): void => {
    if (file) {
      handleImport(file, importMerchants);
    }
  };

  const sendLocationImport = (): void => {
    if (file) {
      handleImport(file, importLocations);
    }
  };

  const sendHardwareImport = (): void => {
    if (file) {
      handleImport(file, importHardware);
    }
  };

  const importData = useCallback(() => {
    if (type === ImportType.TERMINAL) {
      sendTerminalImport();
    } else if (type === ImportType.MERCHANT) {
      sendMerchantImport();
    } else if (type === ImportType.LOCATION) {
      sendLocationImport();
    } else if (type === ImportType.HARDWARE) {
      sendHardwareImport();
    } else {
      console.warn("wrong type");
    }
  }, [type, file, networkId]);

  const downloadTemplate = () => {
    if (type === ImportType.MERCHANT) {
      downloadMerchantsTemplate(networkId);
    } else if (type === ImportType.LOCATION)
      downloadLocationsTemplate(networkId);
    else if (type === ImportType.TERMINAL) downloadTerminalsTemplate(networkId);
    else if (type === ImportType.HARDWARE) downloadHardwareTemplate(networkId);
  };

  return (
    <>
      <LoadingDialog isDialogOpen={loading} />
      <Typography variant="h4">{t("title")}</Typography>
      <UploadWrapper>
        <div>
          <AppButton
            endIcon={<Download />}
            variant="contained"
            color="info"
            fullWidth={false}
            onClick={downloadTemplate}
          >
            {t("downloadBtn")}
          </AppButton>
        </div>
        <Dropzone onDrop={onDrop} multiple={false} maxFiles={1}>
          {({ getRootProps, getInputProps }) => (
            <UploadBox {...getRootProps()}>
              <input {...getInputProps()} />

              <Typography>{file ? file.name : t("dropzoneMessage")}</Typography>
            </UploadBox>
          )}
        </Dropzone>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
          <AppButton
            variant="contained"
            onClick={() => importData()}
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              ":hover": {
                backgroundColor: alpha(theme.palette.secondary.main, 0.8),
              },
            }}
          >
            {t("continueBtn")}
          </AppButton>
        </Box>
      </UploadWrapper>
      <Dialog
        handleClose={() => setErrorDialogOpen(false)}
        isDialogOpen={errorDialogOpen}
        title={t("importErrorDialogTitle")}
      >
        {Array.isArray(errors) ? (
          <List>
            {errors?.map((error, index) => {
              return (
                <ListItem key={index}>{`${t("row")}: ${error.dataRow} ${t(
                  "error",
                )} ${error.message}`}</ListItem>
              );
            })}
          </List>
        ) : (
          <Typography>{errors}</Typography>
        )}
      </Dialog>
    </>
  );
}
