import { MenuItem, SelectChangeEvent } from "@mui/material";
import { FormEvent, useContext, useState } from "react";
import AppSelect from "../../components/form/select";
import { useAppSelector } from "stores/store";
import { useGetTerminalProfilesQuery } from "api/endpoints/terminals";
import { useTranslation } from "react-i18next";
import FullscreenLoader from "components/fullscreenLoader";
import TerminalContext from "pages/newTerminal/TerminalContext";
import StepNavigationButtons from "pages/newTerminal/StepNavigationButtons";
import { TerminalProfiles } from "types/commonTypes";

export default function Profile() {
  const { networkId } = useAppSelector((state) => state.user);
  const { profileName, setProfileName, activeStep, prevStep, nextStep } =
    useContext(TerminalContext);
  const [error, setError] = useState("");
  const { t } = useTranslation("common", {
    keyPrefix: "newTerminal",
  });
  const { t: tValidation } = useTranslation("", {
    keyPrefix: "validation",
  });

  const { data, isLoading } = useGetTerminalProfilesQuery(networkId);

  const getDefProfile = (
    value: string,
    options: TerminalProfiles[] | undefined,
  ) => {
    if (value && options?.map((o) => o.id).includes(value)) {
      return value;
    } else {
      return null;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const profile = event.target.value as string;
    setProfileName(profile);
    setError("");
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (getDefProfile(profileName, data)) {
      nextStep();
    } else {
      setError(tValidation("newTerminal.chooseProfile"));
    }
  };

  if (isLoading) return <FullscreenLoader />;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <AppSelect
          defaultValue={profileName && ""}
          label={t("profileSelectTitle")}
          onChange={handleChange}
          value={profileName}
          error={!!error}
          required
          errorText={error}
        >
          {data?.map((profile) => (
            <MenuItem key={profile.id} value={profile.id}>
              {profile.name}
            </MenuItem>
          ))}
        </AppSelect>
        <StepNavigationButtons activeStep={activeStep} prevStep={prevStep} />
      </form>
    </>
  );
}
