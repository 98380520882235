import { Dialog, IconButton, Stack, styled, Typography } from "@mui/material";
import { AppDataContainer } from "../appDataContainer";
import { useMemo, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { UpdateWidget } from "../Dialog/updateWidget";
import { WidgetTypes } from "../../types/enumTypes";
import {
  NetworkHealthWidgetData,
  NetworkInfoWidgetData,
  TerminalCallWidgetData,
  TerminalHardwareWidgetData,
  Widget,
} from "../../types/commonTypes";
import { NetworkhealthWidget } from "./Networkhealth";
import { TerminalCallsWidget } from "./TerminalCalls";
import NetworkInfo from "./NetworkInfo";
import TerminalsHardware from "./TerminalsHardware";
import { useTranslation } from "react-i18next";
import dataNotFound from "../../assets/images/icon-data-not-found.svg";
import { COLORS } from "theme/colors";

const BoxWrapper = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "0rem auto",
  gap: "5%",
  height: `calc(100% - "73px"})`,
  justifyContent: "center",
}));

interface WidgetProps extends Widget {
  updateCallback?: () => void;
}

export type WidgetState = Omit<Widget, "data">;

export const WidgetContainer: React.FC<WidgetProps> = ({
  type,
  data,
  id,
  name,
  position,
  settings,
  updateCallback,
}) => {
  const { t } = useTranslation("common", { keyPrefix: "widgets" });
  const [widgetState, setWidgetState] = useState<WidgetState>({
    type,
    id,
    position,
    name,
    settings: {
      ...settings,
      locations: position,
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => setIsModalOpen(false);
  const handleOpen = () => setIsModalOpen(true);

  const handleClickOutside = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };

  const { NETWORK_HEALTH, TERMINAL_CALLS, NETWORK_INFO, TERMINALS_HARDWARE } =
    WidgetTypes;
  const widget = useMemo(() => {
    switch (type) {
      case NETWORK_HEALTH:
        return (
          <NetworkhealthWidget
            data={data as unknown as NetworkHealthWidgetData}
          />
        );
      case TERMINAL_CALLS:
        return (
          <TerminalCallsWidget
            data={data as unknown as TerminalCallWidgetData}
          />
        );
      case NETWORK_INFO:
        return <NetworkInfo data={data as unknown as NetworkInfoWidgetData} />;
      case TERMINALS_HARDWARE:
        return (
          <TerminalsHardware
            data={data as unknown as TerminalHardwareWidgetData[]}
          />
        );
    }
  }, [type, data, widgetState]);

  return (
    <>
      <AppDataContainer
        sx={{ boxShadow: "unset", display: "flex", flexDirection: "column" }}
        titleRow={{
          title: name,
          slimTitle: undefined,
          buttons: [
            <IconButton key="Settings" onClick={() => handleOpen()}>
              <SettingsIcon />
            </IconButton>,
          ],
        }}
      >
        {data.length > 0 || Object.keys(data).length > 0 ? (
          <BoxWrapper>{widget}</BoxWrapper>
        ) : (
          <Stack
            flex={1}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant={"body1"} color={COLORS.GREY.MAIN}>
              {t("notFound")}
            </Typography>
            <img width={150} height={150} src={dataNotFound} />
          </Stack>
        )}
      </AppDataContainer>
      <Dialog
        onClose={handleClose}
        onMouseDown={handleClickOutside}
        open={isModalOpen}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <UpdateWidget
          handleClose={handleClose}
          setWidgetState={setWidgetState}
          widgetState={widgetState}
          updateCallback={updateCallback}
        />
      </Dialog>
    </>
  );
};
