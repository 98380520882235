import { api } from "../index";
import {
  AddTerminal,
  bulkTerminalsActionBody,
  FileType,
  Terminal,
  TerminalData,
  TerminalDetail,
  TerminalImportRow,
  TerminalProfile,
  TerminalProfiles,
} from "types/commonTypes";
import { useQuery } from "react-query";
import { ApiResponse } from "types/utilityInterfaces";
import { getFile } from "utils/funcs/downloadFile";
import { getNotEmptyParams } from "utils/funcs/convert";

const getAllTerminals = async (
  networkId: string,
  params: Partial<Record<string, unknown>>,
) => {
  const notEmptyParams = getNotEmptyParams(params);
  const response = await api.get<ApiResponse<Terminal[]>>(
    `/networks/${networkId}/terminals`,
    {
      params: notEmptyParams,
    },
  );
  return response.data;
};

export const useGetAllTerminalsQuery = (
  networkId: string,
  params: Partial<Record<string, unknown>>,
) =>
  useQuery(["getAllTerminals", networkId, params], () =>
    getAllTerminals(networkId, params),
  );

const getTerminal = async (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
) => {
  const response = await api.get<TerminalDetail>(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/terminals/${terminalId}`,
  );
  return response.data;
};

export const useGetTerminalQuery = (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
) =>
  useQuery(
    ["getTerminal", networkId, merchantId, locationId, terminalId],
    () => getTerminal(networkId, merchantId, locationId, terminalId),
    {
      staleTime: 0,
    },
  );

export const exportTerminals = async (
  networkId: string,
  format: FileType,
  params: Partial<Record<string, unknown>>,
) => {
  const notEmptyParams = getNotEmptyParams(params);
  const response = await getFile(
    `/networks/${networkId}/terminals/export/${format}`,
    format,
    "Terminals",
    notEmptyParams,
  );
  return response;
};

export const importTerminals = async (networkId: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await api.post<TerminalImportRow[]>(
    `/networks/${networkId}/terminals/import`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response;
};
export const addTerminal = async (
  networkId: string,
  profileId: string,
  payload: AddTerminal,
) => {
  const response = await api.post<TerminalImportRow[]>(
    `/networks/${networkId}/terminals/save/${profileId}`,
    payload,
  );
  return response;
};

export const sendImportedTerminals = async (
  networkId: string,
  req: TerminalImportRow[],
) => {
  const response = await api.post<[]>(
    `/networks/${networkId}/terminals/import/send`,
    req,
  );
  return response;
};

export const useGetTerminalProfilesQuery = (networkId: string) =>
  useQuery(["getProfiles", networkId], () => getTerminalProfiles(networkId));

export const useGetTerminalProfileQuery = (
  networkId: string,
  profileId: string,
) =>
  useQuery(["getProfile", networkId, profileId], () =>
    getTerminalProfile(networkId, profileId),
  );

export const getTerminalProfiles = async (networkId: string) => {
  const response = await api.get<TerminalProfiles[]>(
    `/networks/${networkId}/terminal-profiles`,
  );
  return response.data;
};

export const getTerminalProfile = async (
  networkId: string,
  profileId: string,
) => {
  const response = await api.get<TerminalProfile>(
    `/networks/${networkId}/terminal-profiles/${profileId}`,
  );
  return response.data;
};

export const downloadTerminalsTemplate = async (networkId: string) => {
  const response = await getFile(
    `networks/${networkId}/terminals/import/template`,
    "csv",
    "Terminals template",
  );
  return response;
};

export const updateTerminalDetail = async (
  networkId: string,
  locationId: string,
  merchantId: string,
  terminalId: string,
) => {
  const response = await api.get<TerminalData>(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/terminals/${terminalId}/updatedata`,
  );
  return response;
};

export const useUpdateTerminalDetail = (
  networkId: string,
  merchantId: string,
  locationId: string,
  terminalId: string,
  enabled: boolean,
) =>
  useQuery(
    ["updateTerminal", networkId, merchantId, locationId, terminalId],
    () => updateTerminalDetail(networkId, locationId, merchantId, terminalId),
    {
      staleTime: 0,
      enabled,
    },
  );

export const duplicateTerminalDetail = async (
  networkId: string,
  locationId: string,
  merchantId: string,
  terminalId: string,
) => {
  const response = await api.get<TerminalData>(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/terminals/${terminalId}/duplicatedata`,
  );
  return response;
};

export const useDuplicateTerminalDetail = (
  networkId: string,
  locationId: string,
  merchantId: string,
  terminalId: string,
  enabled: boolean,
) =>
  useQuery(
    ["duplicateTerminal", networkId, merchantId, locationId, terminalId],
    () =>
      duplicateTerminalDetail(networkId, locationId, merchantId, terminalId),
    {
      staleTime: 0,
      enabled,
    },
  );

export const activateTerminal = async (
  networkId: string,
  locationId: string,
  merchantId: string,
  terminalId: string,
) => {
  const response = await api.post(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/terminals/${terminalId}/activate`,
  );
  return response;
};

export const deactivateTerminal = async (
  networkId: string,
  locationId: string,
  merchantId: string,
  terminalId: string,
) => {
  const response = await api.post(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/terminals/${terminalId}/deactivate`,
  );
  return response;
};

export const bulkActivateTerminals = async (
  networkId: string,
  body: bulkTerminalsActionBody[],
) => {
  const response = await api.post(
    `/networks/${networkId}/terminals/activate`,
    body,
  );
  return response;
};

export const bulkDeactivateTerminals = async (
  networkId: string,
  body: bulkTerminalsActionBody[],
) => {
  const response = await api.post(
    `/networks/${networkId}/terminals/deactivate`,
    body,
  );
  return response;
};
