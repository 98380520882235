import React, { ChangeEvent, useState } from "react";
import AppTextField from "../form/textField";
import AppButton from "../appButton";
import { alpha, Box, useTheme } from "@mui/material";
import DialogBox, { DialogBoxProps } from ".";
import { useTranslation } from "react-i18next";

const SaveFilterDialog: React.FC<DialogBoxProps> = (props) => {
  const { spacing, palette } = useTheme();
  const [filterName, setFilterName] = useState("");
  const { t } = useTranslation("common", {
    keyPrefix: "components.saveFilterDialog",
  });
  return (
    <DialogBox {...props} title={t("createFilter")}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <AppTextField
          label={t("fileName")}
          value={filterName}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setFilterName(e.target.value)
          }
        />

        <Box sx={{ margin: "auto" }}>
          <AppButton
            sx={{
              backgroundColor: palette.secondary.main,
              color: palette.common.white,
              padding: spacing(2, 8),
              ":hover": {
                backgroundColor: alpha(palette.secondary.main, 0.8),
              },
            }}
            onClick={() => {
              // onSubmit(filterName);
              setFilterName("");
            }}
            variant="contained"
          >
            {t("confirmBtn")}
          </AppButton>
        </Box>
      </Box>
    </DialogBox>
  );
};

export default SaveFilterDialog;
