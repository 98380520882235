import {
  Box,
  Typography,
  styled,
  LinearProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { TerminalCallWidgetData } from "types/commonTypes";
import { useTranslation } from "react-i18next";

export const ImportantDataBoxColored = styled("div")(
  ({ theme: { spacing, palette, breakpoints } }) => ({
    marginBottom: useMediaQuery(breakpoints.up("md")) ? spacing(3) : spacing(1),
    backgroundColor: palette.primary.light,
    padding: useMediaQuery(breakpoints.up("md"))
      ? spacing(4, 3)
      : spacing(1, 0.5),
    borderRadius: "10px",
    width: "100%",
    display: "flex",
    flexDirection: useMediaQuery(breakpoints.up("md")) ? "column" : "row",
    alignItems: "center",
    gap: useMediaQuery(breakpoints.up("md")) ? 0 : 10,
  }),
);

const ImportantDataBoxSmallText = styled(Typography)(
  ({ theme: { palette } }) => ({
    color: palette.text.lightGrey.main,
    whiteSpace: "nowrap",
  }),
);

const ImportantDataBoxLargeText = styled(Typography)(() => ({
  fontSize: "2.5rem",
  fontWeight: "bold",
}));

interface TerminalCallsWidgetProps {
  data: TerminalCallWidgetData;
}

export const TerminalCallsWidget = (props: TerminalCallsWidgetProps) => {
  const { data } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("common", {
    keyPrefix: "widgets.terminalCalls",
  });

  // const dataFormated = [
  //   {
  //     name: "success",
  //     value: data["success_percentage"],
  //   },
  //   {
  //     name: "undef",
  //     value: data["undef_percentage"],
  //   },
  //   {
  //     name: "failure",
  //     value: data["failure_percentage"],
  //   },
  //   {
  //     name: "init",
  //     value: data["init_percentage"],
  //   },
  // ];

  return (
    <Box
      sx={{
        marginY: "auto",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        padding: isMobile ? 0 : "1rem",
        overflow: "hidden",
      }}
    >
      {/*<AppDonutChart data={dataFormated} />*/}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 0 : 2,
          paddingX: isMobile ? "0.5rem" : 0,
        }}
      >
        <Box>
          <ImportantDataBoxColored>
            <ImportantDataBoxLargeText>{data.undef}</ImportantDataBoxLargeText>
            <ImportantDataBoxSmallText>
              {t("undef")} <b>({data.undef_percentage}%)</b>
            </ImportantDataBoxSmallText>
          </ImportantDataBoxColored>
          <ImportantDataBoxColored>
            <ImportantDataBoxLargeText>{data.init}</ImportantDataBoxLargeText>
            <ImportantDataBoxSmallText>
              {t("init")} <b>({data.init_percentage}%)</b>
            </ImportantDataBoxSmallText>
          </ImportantDataBoxColored>
        </Box>
        <Box>
          <ImportantDataBoxColored>
            <ImportantDataBoxLargeText>
              {data.success}
            </ImportantDataBoxLargeText>
            <ImportantDataBoxSmallText>
              {t("success")} <b>({data.success_percentage}%)</b>
            </ImportantDataBoxSmallText>
          </ImportantDataBoxColored>
          <ImportantDataBoxColored>
            <ImportantDataBoxLargeText>
              {data.failure}
            </ImportantDataBoxLargeText>
            <ImportantDataBoxSmallText>
              {t("failure")} <b>({data.failure_percentage}%)</b>
            </ImportantDataBoxSmallText>
          </ImportantDataBoxColored>
        </Box>
      </Box>
      <LinearProgress
        variant="determinate"
        value={data.success_percentage}
        color="success"
      />
    </Box>
  );
};
