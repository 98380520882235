import { OidcRpLoginData } from "types/commonTypes";

export function useRoleDetails(data: OidcRpLoginData, roleId: string) {
  if (
    !data.idToken ||
    !data.idToken.claims ||
    !Array.isArray(data.idToken.claims.groups)
  ) {
    return {
      role: null,
      context: null,
    };
  }

  for (const group of data.idToken.claims.groups) {
    if (Array.isArray(group.roles)) {
      for (const role of group.roles) {
        if (role.id === roleId) {
          return {
            role,
            context: role.context,
          };
        }
      }
    }
  }
  return {
    role: (data.idToken.claims as any).groups[0]?.roles[0] ?? null,
    context: (data.idToken.claims as any).groups[0]?.roles[0].context,
  };
}
