import { ErrorType } from "types/commonTypes";

export const errorHandling = (
  error: ErrorType,
  setError: (errorMessages: string[]) => void,
  setErrorDialogOpen: (isOpen: boolean) => void,
) => {
  if (
    error.response.status === 400 &&
    error.response.data &&
    error.response.data.errorMessages
  ) {
    setError(error.response.data.errorMessages);
    setErrorDialogOpen(true);
  } else if (
    error.response.status === 400 &&
    error.response.data &&
    error.response.data.errorMessage
  ) {
    setError([error.response.data.errorMessage]);
    setErrorDialogOpen(true);
  } else if (error.response.status === 500) {
    setError(["Internal Server Error"]);
    setErrorDialogOpen(true);
  } else {
    setError(["Unknown error"]);
    setErrorDialogOpen(true);
  }
};
