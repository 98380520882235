import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme,
} from "@mui/material";
import { BarChartData } from "components/charts/barChart";

export interface DialogBoxProps {
  handleClose?: () => void;
  isDialogOpen: boolean;
  title?: React.ReactNode;
  data?: BarChartData[];
}

interface DialogProps {
  handleClose?: () => void;
  isDialogOpen: boolean;
  children: React.ReactNode;
  title: React.ReactNode;
  autoWidth?: boolean;
}

const DialogBox: React.FC<DialogProps> = ({
  handleClose,
  isDialogOpen,
  children,
  title,
  autoWidth,
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={isDialogOpen}
      maxWidth={autoWidth ? false : "sm"}
      fullWidth={!autoWidth}
    >
      <>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: theme.spacing(2.5, 2.5, 1.5),
          }}
        >
          {title}
          {handleClose && (
            <IconButton key="Settings" onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent sx={{ svg: { padding: "1rem" } }}>
          {children}
        </DialogContent>
      </>
    </Dialog>
  );
};
export default DialogBox;
