import { Box, MenuItem, styled } from "@mui/material";
import React, { useContext } from "react";
import { Control, useForm, UseFormWatch } from "react-hook-form";
import AppSwitch from "../../components/appSwitch";
import AppSelect from "../../components/form/select";
import FormController from "../../components/formController";
import TerminalContext from "pages/newTerminal/TerminalContext";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import StepNavigationButtons from "pages/newTerminal/StepNavigationButtons";

const ColumnsWrapper = styled("div")(({ theme: { spacing } }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: spacing(4),
}));

const DCC = "dcc";
const TOKENIZATION = "tokenization";
const TRANSPORT_MODE = "transport_mode";
const CARD_DATA_INPUT = "cardDataInput";
const CARD_DATA_INPUT_TYPE = "cardDataInputCapability";
const CARD_HOLDER_AUTH = "cardHolderAuth";
const CARD_HOLDER_AUTH_TYPE = "cardHolderAuthCapability";
const PIN_CAPTURE = "pinCapture";
const PIN_CAPTURE_TYPE = "pinCaptureCapability";
const DATA_OUTPUT = "dataOutput";
const DATA_OUTPUT_TYPE = "terminalDataOutputCapability";
const CARD_CAPTURE = "cardCapture";
const CARD_CAPTURE_TYPE = "cardCaptureCapability";
const TERMINAL_OPERATING = "terminalOperating";
const TERMINAL_OPERATING_TYPE = "terminalOperatingEnviroment";

const CARD_INPUT_SELECT = [
  "unknown",
  "manual",
  "magneticStripe",
  "barCode",
  "OCR",
  "smartCardReady",
  "keyEntryOnly",
  "contactlessMagStripe",
  "contactlessSmartCard",
] as const;
type CardSelectUnion = (typeof CARD_INPUT_SELECT)[number] | "";

const CARD_AUTH_SELECT = [
  "notAuthenticated",
  "pinVerification",
  "electronicAuth",
  "unknown",
] as const;
export type CardAuthUnion = (typeof CARD_AUTH_SELECT)[number] | "";

const PIN_SELECT = [
  "noPINCaptureCapability",
  "unknown",
  "maximimum4",
  "maximimum5",
  "maximimum6",
  "maximimum7",
  "maximimum8",
  "maximimum9",
  "maximimum10",
  "maximimum11",
  "maximimum12",
] as const;
export type PinSelectUnion = (typeof PIN_SELECT)[number] | "";

const DATA_OUTPUT_SELECT = [
  "unknown",
  "none",
  "printingOnly",
  "displayOnly",
  "printAndDisplay",
] as const;
export type DataOutputUnion = (typeof PIN_SELECT)[number] | "";

const CARD_CAPTURE_SELECT = [
  "noCaptureCapability",
  "cardCaptureCapability",
  "unknown",
];
export type CardCaptureUnion = (typeof CARD_CAPTURE_SELECT)[number] | "";

const TERMINAL_OPERATING_SELECT = [
  "noTerminalUser",
  "onPremisesCardAcceptorAttended",
  "onPremisesCardAcceptorUnattended",
  "offPremisesCardAcceptorAttended",
  "offPremisesCardAcceptorUnattended",
  "onPremisesCardholderUnattended",
  "unknown",
  "CAT1",
  "CAT2",
  "CAT3",
  "CAT4",
];
export type TerminalOperatingUnion =
  | (typeof TERMINAL_OPERATING_SELECT)[number]
  | "";

type FORM_VALUES = {
  [DCC]: boolean;
  [TOKENIZATION]: boolean;
  [TRANSPORT_MODE]: boolean;
  [CARD_DATA_INPUT]: boolean;
  [CARD_DATA_INPUT_TYPE]: CardSelectUnion | null;
  [CARD_HOLDER_AUTH]: boolean;
  [CARD_HOLDER_AUTH_TYPE]: CardAuthUnion | null;
  [PIN_CAPTURE]: boolean;
  [PIN_CAPTURE_TYPE]: PinSelectUnion | null;
  [DATA_OUTPUT]: boolean;
  [DATA_OUTPUT_TYPE]: DataOutputUnion | null;
  [CARD_CAPTURE]: boolean;
  [CARD_CAPTURE_TYPE]: CardCaptureUnion | null;
  [TERMINAL_OPERATING]: boolean;
  [TERMINAL_OPERATING_TYPE]: TerminalOperatingUnion | null;
};

export default function Settings() {
  const {
    terminalProfile,
    newTerminal,
    setNewTerminal,
    nextStep,
    activeStep,
    prevStep,
  } = useContext(TerminalContext);
  const { t } = useTranslation("common", {
    keyPrefix: "newTerminal",
  });
  const { t: tValidation } = useTranslation("", {
    keyPrefix: "validation",
  });

  const getDefValue = (value: any, options: readonly string[]) => {
    if (value && options.includes(value)) {
      return value;
    } else {
      return null;
    }
  };

  const defaultValues: FORM_VALUES = {
    [DCC]: newTerminal.dcc ?? false,
    [TOKENIZATION]: newTerminal.tokenization ?? false,
    [TRANSPORT_MODE]: newTerminal.transportModeEnabled ?? false,
    [CARD_DATA_INPUT]:
      !!newTerminal.capability?.cardDataInputCapability ?? false,
    [CARD_DATA_INPUT_TYPE]: getDefValue(
      newTerminal.capability?.cardDataInputCapability,
      CARD_INPUT_SELECT,
    ),
    [CARD_HOLDER_AUTH]:
      !!newTerminal.capability?.cardHolderAuthCapability ?? false,
    [CARD_HOLDER_AUTH_TYPE]: getDefValue(
      newTerminal.capability?.cardHolderAuthCapability,
      CARD_AUTH_SELECT,
    ),
    [PIN_CAPTURE]: !!newTerminal.capability?.pinCaptureCapability ?? false,
    [PIN_CAPTURE_TYPE]: getDefValue(
      newTerminal.capability?.pinCaptureCapability,
      PIN_SELECT,
    ),
    [DATA_OUTPUT]:
      !!newTerminal.capability?.terminalDataOutputCapability ?? false,
    [DATA_OUTPUT_TYPE]: getDefValue(
      newTerminal.capability?.terminalDataOutputCapability,
      DATA_OUTPUT_SELECT,
    ),
    [CARD_CAPTURE]: !!newTerminal.capability?.cardCaptureCapability ?? false,
    [TERMINAL_OPERATING]:
      !!newTerminal.capability?.terminalOperatingEnviroment ?? false,
    [CARD_CAPTURE_TYPE]: getDefValue(
      newTerminal.capability?.cardCaptureCapability,
      CARD_CAPTURE_SELECT,
    ),
    [TERMINAL_OPERATING_TYPE]: getDefValue(
      newTerminal.capability?.terminalOperatingEnviroment,
      TERMINAL_OPERATING_SELECT,
    ),
  };

  const schema = Joi.object({
    [DCC]: Joi.any(),
    [TOKENIZATION]: Joi.any(),
    [TRANSPORT_MODE]: Joi.boolean(),
    [CARD_DATA_INPUT]: Joi.boolean(),
    [CARD_HOLDER_AUTH]: Joi.boolean(),
    [PIN_CAPTURE]: Joi.boolean(),
    [DATA_OUTPUT]: Joi.boolean(),
    [CARD_CAPTURE]: Joi.boolean(),
    [TERMINAL_OPERATING]: Joi.boolean(),
    [CARD_DATA_INPUT_TYPE]: Joi.when(CARD_DATA_INPUT, {
      is: true,
      then: Joi.string()
        .required()
        .messages({
          "string.base": tValidation("required"),
          "string.empty": tValidation("required"),
        }),
      otherwise: Joi.allow(""),
    }),
    [CARD_HOLDER_AUTH_TYPE]: Joi.when(CARD_HOLDER_AUTH, {
      is: true,
      then: Joi.string()
        .required()
        .messages({
          "string.base": tValidation("required"),
          "string.empty": tValidation("required"),
        }),
      otherwise: Joi.allow(""),
    }),
    [PIN_CAPTURE_TYPE]: Joi.when(PIN_CAPTURE, {
      is: true,
      then: Joi.string()
        .required()
        .messages({
          "string.base": tValidation("required"),
          "string.empty": tValidation("required"),
        }),
      otherwise: Joi.allow(""),
    }),
    [DATA_OUTPUT_TYPE]: Joi.when(DATA_OUTPUT, {
      is: true,
      then: Joi.string()
        .required()
        .messages({
          "string.base": tValidation("required"),
          "string.empty": tValidation("required"),
        }),
      otherwise: Joi.allow(""),
    }),
    [CARD_CAPTURE_TYPE]: Joi.when(CARD_CAPTURE, {
      is: true,
      then: Joi.string()
        .required()
        .messages({
          "string.base": tValidation("required"),
          "string.empty": tValidation("required"),
        }),
      otherwise: Joi.allow(""),
    }),
    [TERMINAL_OPERATING_TYPE]: Joi.when(TERMINAL_OPERATING, {
      is: true,
      then: Joi.string()
        .required()
        .messages({
          "string.base": tValidation("required"),
          "string.empty": tValidation("required"),
        }),
      otherwise: Joi.allow(""),
    }),
  });

  const { control, handleSubmit, watch } = useForm<FORM_VALUES>({
    resolver: joiResolver(schema),
    defaultValues,
  });
  const formParams = { control, watch };
  const onSubmit = (data: FORM_VALUES) => {
    const UpdatedNewTerminal = {
      ...newTerminal,
      capability: {
        ...newTerminal.capability,
        [CARD_DATA_INPUT_TYPE]: data[CARD_DATA_INPUT]
          ? data[CARD_DATA_INPUT_TYPE]
          : null,
        [CARD_HOLDER_AUTH_TYPE]: data[CARD_HOLDER_AUTH]
          ? data[CARD_HOLDER_AUTH_TYPE]
          : null,
        [CARD_CAPTURE_TYPE]: data[CARD_CAPTURE]
          ? data[CARD_CAPTURE_TYPE]
          : null,
        [PIN_CAPTURE_TYPE]: data[PIN_CAPTURE] ? data[PIN_CAPTURE_TYPE] : null,
        [TERMINAL_OPERATING_TYPE]: data[TERMINAL_OPERATING]
          ? data[TERMINAL_OPERATING_TYPE]
          : null,
        [DATA_OUTPUT_TYPE]: data[DATA_OUTPUT] ? data[DATA_OUTPUT_TYPE] : null,
      },
      [DCC]: data[DCC],
      [TRANSPORT_MODE]: data[TRANSPORT_MODE],
      [TOKENIZATION]: data[TOKENIZATION],
    };
    setNewTerminal(UpdatedNewTerminal);
    nextStep();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {terminalProfile.dccEnabled && (
        <FormController control={control} name={DCC}>
          <AppSwitch
            label={DCC}
            color="secondary"
            halfWidth
            sx={{ mr: 2.5, my: 1 }}
          />
        </FormController>
      )}
      {terminalProfile.tokenizationEnabled && (
        <FormController control={control} name={TOKENIZATION}>
          <AppSwitch
            label={t(TOKENIZATION)}
            color="secondary"
            halfWidth
            sx={{ mr: 2.5, my: 1 }}
          />
        </FormController>
      )}
      {terminalProfile.transportModeEnabled && (
        <FormController control={control} name={TRANSPORT_MODE}>
          <AppSwitch label={t(TRANSPORT_MODE)} color="secondary" halfWidth />
        </FormController>
      )}
      <SwitchRow
        label={CARD_DATA_INPUT}
        selectName={CARD_DATA_INPUT_TYPE}
        selectOptions={CARD_INPUT_SELECT.concat()}
        {...formParams}
      />
      <SwitchRow
        label={CARD_HOLDER_AUTH}
        selectName={CARD_HOLDER_AUTH_TYPE}
        selectOptions={CARD_AUTH_SELECT.concat()}
        {...formParams}
      />
      <SwitchRow
        label={PIN_CAPTURE}
        selectName={PIN_CAPTURE_TYPE}
        selectOptions={PIN_SELECT.concat()}
        {...formParams}
      />
      <SwitchRow
        label={DATA_OUTPUT}
        selectName={DATA_OUTPUT_TYPE}
        selectOptions={DATA_OUTPUT_SELECT.concat()}
        {...formParams}
      />
      <SwitchRow
        label={CARD_CAPTURE}
        selectName={CARD_CAPTURE_TYPE}
        selectOptions={CARD_CAPTURE_SELECT.concat()}
        {...formParams}
      />
      <SwitchRow
        label={TERMINAL_OPERATING}
        selectName={TERMINAL_OPERATING_TYPE}
        selectOptions={TERMINAL_OPERATING_SELECT.concat()}
        {...formParams}
      />
      <StepNavigationButtons activeStep={activeStep} prevStep={prevStep} />
    </form>
  );
}

interface SwitchRowProps {
  label: keyof FORM_VALUES;
  control: Control<FORM_VALUES>;
  selectName: string;
  selectOptions: string[];
  watch: UseFormWatch<FORM_VALUES>;
}

const SwitchRow: React.FC<SwitchRowProps> = ({
  label,
  control,
  selectName,
  selectOptions,
  watch,
}) => {
  const { t } = useTranslation("common", {
    keyPrefix: "newTerminal",
  });

  const enabled = watch([label])[0];

  return (
    <ColumnsWrapper
      sx={{
        gap: 5,
        alignItems: "start",
        py: 1,
      }}
    >
      <FormController control={control} name={label}>
        <AppSwitch label={t(label)} color="secondary" halfWidth={!enabled} />
      </FormController>
      {enabled ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <FormController control={control} name={selectName}>
            <AppSelect sx={{ width: "80%" }} name={selectName}>
              {selectOptions.map((item, key) => (
                <MenuItem value={item} key={key}>
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </MenuItem>
              ))}
            </AppSelect>
          </FormController>
        </Box>
      ) : (
        <Box />
      )}
    </ColumnsWrapper>
  );
};
