import React, { createContext, useState } from "react";
import {
  MerchantImport,
  TerminalImportRow,
  TLocation,
} from "types/commonTypes";

const ImportContext = createContext<importRowsData>({} as importRowsData);

type ImportContextProviderProps = {
  children: React.ReactNode;
};

export type importDataTypes =
  | TerminalImportRow[]
  | MerchantImport[]
  | TLocation[]
  | null;

type importRowsData = {
  importRowsData: importDataTypes;
  setImportRowsData: React.Dispatch<React.SetStateAction<importDataTypes>>;
};

export function ImportContextProvider({
  children,
}: ImportContextProviderProps) {
  const [importRowsData, setImportRowsData] = useState<importDataTypes>(null);

  return (
    <ImportContext.Provider value={{ importRowsData, setImportRowsData }}>
      {children}
    </ImportContext.Provider>
  );
}

export default ImportContext;
