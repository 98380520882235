import React, { useState } from "react";
import { Box, Card, Typography, styled } from "@mui/material";
import SaveFilterDialog from "../Dialog/SaveFilterDialog";
import { useTranslation } from "react-i18next";

const GridBox = styled("div")(({ theme: { spacing, breakpoints } }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
  gap: spacing(2),
  [breakpoints.down("lg")]: {
    gridTemplateColumns: "1fr 1fr",
    gap: spacing(1),
  },
  [breakpoints.down("sm")]: {
    gridTemplateColumns: "1fr",
    gap: spacing(1),
  },
}));

export default function FilterWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation("common", {
    keyPrefix: "components.filterWrapper",
  });
  const [isCreateFilterDialogOpen, setIsCreateFilterDialogOpen] =
    useState(false);
  const closeDialog = () => {
    setIsCreateFilterDialogOpen(false);
  };
  return (
    <>
      <SaveFilterDialog
        isDialogOpen={isCreateFilterDialogOpen}
        handleClose={closeDialog}
      />
      <Card sx={{ p: [3, 2], marginY: 2 }}>
        <Box sx={{ mb: 3, display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">{t("title")}</Typography>
        </Box>
        <GridBox>{children}</GridBox>
      </Card>
    </>
  );
}
