import { Controller, useForm } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import AppTextField from "../../components/form/textField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  alpha,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import AppSelect from "../../components/form/select";
import FormController from "../../components/formController";
import { ColumnsWrapper, Column } from "./BasicInfo";
import { format, isValid, parse } from "date-fns";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { addTerminal } from "api/endpoints/terminals";
import { useAppSelector } from "stores/store";
import { TTexts } from "types/commonTypes";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import {
  CREATE_TERMINAL_PAGES,
  TERMINAL_PAGES,
  TERMINALS_URL,
} from "pages/appRoutes/appRoutesConst";
import AppButton from "components/appButton";
import { useTranslation } from "react-i18next";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import LoadingDialog from "components/Dialog/LoadingDialog";
import ErrorDialog from "components/Dialog/ErrorDialog";
import { errorHandling } from "utils/funcs/errorHandling";
import Button from "@mui/material/Button";
import TerminalContext from "pages/newTerminal/TerminalContext";

const SERIAL_NUMBER = "serialNumber";
const CLOSING_TYPE = "type";
const CLOSING_FROM = "from";
const CLOSING_TO = "to";
const CLOSING_OPTIONS = ["automatic", "manual"] as const;
const RECEIPT = "receipt";
const CUSTOM_TEXT = "texts";
const SUNDAY = "Sunday";
const MONDAY = "Monday";
const TUESDAY = "Tuesday";
const WEDNESDAY = "Wednesday";
const THURSDAY = "Thursday";
const FRIDAY = "Friday";
const SATURDAY = "Saturday";

const day_options = {
  Sunday: "Sunday",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
};
type DayOptionsKey = keyof typeof day_options;

const RECEIPTS_ROWS_ITEMS = [
  "0 - Print on terminal",
  "1 - Print on cash",
  "2 - Print on terminal - sign ticket, customer authentication (archivation)",
  "3 - B-protocol - sign ticket, customer authentication (archivation)",
  "4 - cless < CVM limit - customer ticket question",
  "8 - cless < CVM limit - do not print customer ticket",
  "10 - 2 + 8",
];

type FORM_VALUES = {
  [SERIAL_NUMBER]: string;
  [CLOSING_TYPE]: string;
  [CLOSING_FROM]: Date;
  [CLOSING_TO]: Date;
  [RECEIPT]: string;
  [CUSTOM_TEXT]: { id: string | null };
  [SUNDAY]: boolean;
  [MONDAY]: boolean;
  [TUESDAY]: boolean;
  [WEDNESDAY]: boolean;
  [THURSDAY]: boolean;
  [FRIDAY]: boolean;
  [SATURDAY]: boolean;
};

export default function Hardware() {
  const { profileName, newTerminal, setNewTerminal, prevStep } =
    useContext(TerminalContext);
  const { t } = useTranslation("common", {
    keyPrefix: "newTerminal",
  });
  const { t: tValidation } = useTranslation("", {
    keyPrefix: "validation",
  });

  const defaultTexts = newTerminal.texts
    ? Object.fromEntries(
        Object.entries(newTerminal.texts).filter(([, value]) => value !== null),
      )
    : { "1": "" };
  const generateDefaultTexts = () => {
    if (newTerminal.texts) {
      const texts = Object.fromEntries(
        Object.entries(newTerminal.texts).filter(([, value]) => value !== null),
      );
      if (Object.getOwnPropertyNames(texts).length < 1) {
        return { "1": "" };
      } else {
        return texts as TTexts;
      }
    } else {
      return { "1": "" };
    }
  };
  const { networkId } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { spacing, palette } = useTheme();
  const [btnId, setBtnId] = useState("");
  const [texts, setText] = useState<TTexts>(generateDefaultTexts());
  const [loading, setLoading] = useState(false);
  const [errors, setError] = useState<string[]>();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const theme = useTheme();

  const defaultClosingFrom = newTerminal.closing?.from
    ? parse(newTerminal.closing?.from as string, "HH:mm", new Date())
    : new Date();
  const defaultClosingTo = newTerminal.closing?.from
    ? parse(newTerminal.closing?.to as string, "HH:mm", new Date())
    : new Date();

  const defaultValues = {
    [SERIAL_NUMBER]: newTerminal.serialNumber ?? "",
    [CLOSING_TYPE]: newTerminal.closing?.type ?? "manual",
    [CLOSING_FROM]: defaultClosingFrom,
    [CLOSING_TO]: defaultClosingTo,
    [SUNDAY]: newTerminal.closing?.sunday ?? false,
    [MONDAY]: newTerminal.closing?.monday ?? false,
    [TUESDAY]: newTerminal.closing?.tuesday ?? false,
    [WEDNESDAY]: newTerminal.closing?.wednesday ?? false,
    [THURSDAY]: newTerminal.closing?.thursday ?? false,
    [FRIDAY]: newTerminal.closing?.friday ?? false,
    [SATURDAY]: newTerminal.closing?.saturday ?? false,
    [RECEIPT]: newTerminal.receipt ?? "",
    [CUSTOM_TEXT]: defaultTexts,
  };

  const schema = Joi.object({
    [SERIAL_NUMBER]: Joi.string()
      .trim()
      .required()
      .max(255)
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 255 }),
      }),
    [CLOSING_TYPE]: Joi.string()
      .required()
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
      }),
    [CLOSING_FROM]: Joi.date().custom((value, helpers) => {
      const closingType = helpers.state.ancestors[0][CLOSING_TYPE];
      const closingTo = helpers.state.ancestors[0][CLOSING_TO];
      if (
        closingType === "automatic" &&
        new Date(closingTo) <= new Date(value)
      ) {
        return helpers.message(tValidation("same_day_closing"));
      }
      return value;
    }),
    [CLOSING_TO]: Joi.date().custom((value, helpers) => {
      const closingType = helpers.state.ancestors[0][CLOSING_TYPE];
      const closingFrom = helpers.state.ancestors[0][CLOSING_FROM];
      if (
        closingType === "automatic" &&
        new Date(closingFrom) >= new Date(value)
      ) {
        return helpers.message(tValidation("same_day_closing"));
      }
      return value;
    }),
    [SUNDAY]: Joi.boolean().messages({
      "string.empty": tValidation("required"),
    }),
    [MONDAY]: Joi.boolean().messages({
      "string.empty": tValidation("required"),
    }),
    [TUESDAY]: Joi.boolean().messages({
      "string.empty": tValidation("required"),
    }),
    [WEDNESDAY]: Joi.boolean().messages({
      "string.empty": tValidation("required"),
    }),
    [THURSDAY]: Joi.boolean().messages({
      "string.empty": tValidation("required"),
    }),
    [FRIDAY]: Joi.boolean().messages({
      "string.empty": tValidation("required"),
    }),
    [SATURDAY]: Joi.boolean().messages({
      "string.empty": tValidation("required"),
    }),
    [RECEIPT]: Joi.string()
      .trim()
      .required()
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
      }),
    [CUSTOM_TEXT]: Joi.object(),
  });
  const form = useForm<FORM_VALUES>({
    resolver: joiResolver(schema),
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors: formErrors },
    trigger,
  } = form;

  const isAutomaticTime = watch([CLOSING_TYPE])[0] === "automatic";

  const onSubmit = async (data: FORM_VALUES) => {
    setLoading(true);
    const UpdatedNewTerminal = {
      ...newTerminal,
      serialNumber: data[SERIAL_NUMBER],
      closing: isAutomaticTime
        ? {
            type: data[CLOSING_TYPE],
            from: format(data[CLOSING_FROM], "HH:mm"),
            to: format(data[CLOSING_TO], "HH:mm"),
            sunday: data[SUNDAY],
            monday: data[MONDAY],
            tuesday: data[TUESDAY],
            wednesday: data[WEDNESDAY],
            thursday: data[THURSDAY],
            friday: data[FRIDAY],
            saturday: data[SATURDAY],
          }
        : {
            type: data[CLOSING_TYPE],
          },
      receipt: data[RECEIPT],
      texts: texts,
    };

    const draftParams = {
      ...newTerminal,
      serialNumber: data[SERIAL_NUMBER],
      cardDataInputCapability: newTerminal.capability.cardDataInputCapability,
      cardHolderAuthCapability: newTerminal.capability.cardHolderAuthCapability,
      cardCaptureCapability: newTerminal.capability.cardCaptureCapability,
      terminalOperatingEnviroment:
        newTerminal.capability.terminalOperatingEnviroment,
      terminalDataOutputCapability:
        newTerminal.capability.terminalDataOutputCapability,
      pinCaptureCapability: newTerminal.capability.pinCaptureCapability,
      closing: isAutomaticTime
        ? {
            type: data[CLOSING_TYPE],
            from: format(data[CLOSING_FROM], "HH:mm"),
            to: format(data[CLOSING_TO], "HH:mm"),
            sunday: data[SUNDAY],
            monday: data[MONDAY],
            tuesday: data[TUESDAY],
            wednesday: data[WEDNESDAY],
            thursday: data[THURSDAY],
            friday: data[FRIDAY],
            saturday: data[SATURDAY],
          }
        : {
            type: data[CLOSING_TYPE],
          },
      receipt: data[RECEIPT],
      texts: texts,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { capability, ...params } = draftParams;
    setNewTerminal(UpdatedNewTerminal);
    await addTerminal(networkId, profileName, params)
      .then((resp) => {
        if (resp.status === 200) {
          if (btnId === "saveOrContinueTerminalBtn") {
            navigate(`/${TERMINALS_URL}`);
          } else if (btnId === "saveTerminalBtn") {
            navigate(
              `/${TERMINALS_URL}/${TERMINAL_PAGES.NEW_TERMINAL_URL}/${CREATE_TERMINAL_PAGES.PROFILE_URL}`,
            );
          }
        }
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const addText = () => {
    const existingKeys = Object.keys(texts).map(Number).filter(Boolean);
    const uniqueId = (Math.max(...existingKeys, 0) + 1).toString();
    setText((prevTexts) => ({ ...prevTexts, [uniqueId]: null }));
  };

  const removeText = (id: string) => {
    const updatedTexts = { ...texts };
    delete updatedTexts[id];

    const sequentialTexts: TTexts = {};
    Object.keys(updatedTexts).forEach((key, index) => {
      sequentialTexts[(index + 1).toString()] = updatedTexts[key];
    });

    setText(sequentialTexts);
  };

  const handleTextFieldChange = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const updatedTextFields = { ...texts };
    updatedTextFields[id] = event.target.value;
    setText(updatedTextFields);
  };

  const backBtnHandler = () => {
    const data = getValues();
    const draftParams = {
      ...newTerminal,
      serialNumber: data[SERIAL_NUMBER],
      closing: isAutomaticTime
        ? {
            type: data[CLOSING_TYPE],
            from: format(data[CLOSING_FROM], "HH:mm"),
            to: format(data[CLOSING_TO], "HH:mm"),
            sunday: data[SUNDAY],
            monday: data[MONDAY],
            tuesday: data[TUESDAY],
            wednesday: data[WEDNESDAY],
            thursday: data[THURSDAY],
            friday: data[FRIDAY],
            saturday: data[SATURDAY],
          }
        : {
            type: data[CLOSING_TYPE],
          },
      receipt: data[RECEIPT],
      texts: texts,
    };
    setNewTerminal(draftParams);
    prevStep();
  };

  const handleTimeChange = (
    newValue: Date | null,
    onChange: (value: Date | null) => void,
  ) => {
    if (newValue && isValid(newValue)) {
      onChange(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <LoadingDialog isDialogOpen={loading} />
      <ErrorDialog
        isDialogOpen={errorDialogOpen}
        handleClose={() => setErrorDialogOpen(false)}
        messages={errors}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ColumnsWrapper>
          <Column>
            <FormController control={control} name={SERIAL_NUMBER}>
              <AppTextField label={t(SERIAL_NUMBER)} required />
            </FormController>
          </Column>
          <Column>
            <FormController control={control} name={CLOSING_TYPE}>
              <AppSelect label={t(CLOSING_TYPE)} required>
                {CLOSING_OPTIONS.map((item) => (
                  <MenuItem key={item} value={item}>
                    {t(item)}
                  </MenuItem>
                ))}
              </AppSelect>
            </FormController>
          </Column>
          {isAutomaticTime && (
            <Column>
              <Grid container spacing={4}>
                <Grid item>
                  <Controller
                    name={CLOSING_FROM}
                    control={control}
                    render={({ field }) => (
                      <TimePicker
                        label={t(CLOSING_FROM)}
                        value={field.value}
                        onChange={(newValue) => {
                          handleTimeChange(newValue, field.onChange);
                          trigger(CLOSING_TO);
                          trigger(CLOSING_FROM);
                        }}
                        renderInput={(params) => (
                          <AppTextField
                            {...params}
                            value={
                              field.value ? format(field.value, "HH:mm") : ""
                            }
                            error={!!formErrors[CLOSING_FROM]}
                            errorText={formErrors[CLOSING_FROM]?.message || ""}
                          />
                        )}
                        ampm={false}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name={CLOSING_TO}
                    control={control}
                    render={({ field }) => (
                      <TimePicker
                        label={CLOSING_TO}
                        value={field.value}
                        onChange={(newValue) => {
                          handleTimeChange(newValue, field.onChange);
                          trigger(CLOSING_FROM);
                          trigger(CLOSING_TO);
                        }}
                        renderInput={(params) => (
                          <AppTextField
                            {...params}
                            value={
                              field.value ? format(field.value, "HH:mm") : ""
                            }
                            error={!!formErrors[CLOSING_TO]}
                            errorText={formErrors[CLOSING_TO]?.message || ""}
                          />
                        )}
                        ampm={false}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Column>
          )}
          {isAutomaticTime && (
            <Column>
              <Grid container display={"flex"}>
                {Object.keys(day_options).map((key, index) => (
                  <Box key={index}>
                    <FormControlLabel
                      control={
                        <Controller
                          name={key as DayOptionsKey}
                          control={control}
                          render={({ field }) => (
                            <Checkbox
                              {...field}
                              defaultChecked={
                                defaultValues[key as DayOptionsKey]
                              }
                              onChange={(e) => field.onChange(e.target.checked)}
                              color="secondary"
                            />
                          )}
                        />
                      }
                      label={t(key)}
                    />
                  </Box>
                ))}
              </Grid>
            </Column>
          )}
          <Column>
            <FormController control={control} name={RECEIPT}>
              <AppSelect label={t(RECEIPT)} required>
                {RECEIPTS_ROWS_ITEMS.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </AppSelect>
            </FormController>
          </Column>
          <Column>
            <Grid container gap={1} direction={"column"}>
              <Typography
                fontSize={"0.9rem"}
                fontWeight={700}
                color={theme.palette.text.lightGrey.main}
              >
                {t(CUSTOM_TEXT)}
              </Typography>
              {Object.entries(texts).map(([id, text], index, array) => (
                <Box key={id} sx={{ display: "flex" }}>
                  <AppTextField
                    value={text}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleTextFieldChange(id, e)
                    }
                    fullWidth
                    variant="outlined"
                  />
                  {index === array.length - 1 ? (
                    <IconButton onClick={addText}>
                      <AddCircle />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => removeText(id)}>
                      <RemoveCircle />
                    </IconButton>
                  )}
                </Box>
              ))}
            </Grid>
          </Column>
        </ColumnsWrapper>

        <Box
          sx={{
            display: "flex",
            mt: spacing(2),
            justifyContent: "space-between",
          }}
        >
          <Button onClick={backBtnHandler} sx={{ mr: 1 }} color="secondary">
            {t("backBtn")}
          </Button>
          <Box>
            <AppButton
              id={"saveOrContinueTerminalBtn"}
              sx={{
                backgroundColor: palette.secondary.main,
                color: palette.common.white,
                ":hover": {
                  backgroundColor: alpha(palette.secondary.main, 0.8),
                },
              }}
              type={"submit"}
              onClick={(e) => setBtnId(e.currentTarget.id)}
            >
              {t("saveBtn")}
            </AppButton>
            <AppButton
              id={"saveTerminalBtn"}
              variant="outlined"
              color="secondary"
              sx={{ ml: 1 }}
              type={"submit"}
              onClick={(e) => setBtnId(e.currentTarget.id)}
            >
              {t("SaveAndCreateBtn")}
            </AppButton>
          </Box>
        </Box>
      </form>
    </LocalizationProvider>
  );
}
