import { alpha, styled } from "@mui/material";
import FullscreenLoader from "components/fullscreenLoader";

const LoaderOverlay = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: alpha(theme.palette.common.black, 0.5),
  zIndex: 9999,
}));
const WidgetLoader = () => {
  return (
    <LoaderOverlay>
      <FullscreenLoader />
    </LoaderOverlay>
  );
};

export default WidgetLoader;
