import { useTranslation } from "react-i18next";
import { Card } from "@mui/material";
import { PageTitle } from "components/pageTitle";

const NotFoundPage = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <PageTitle title={t("notFound.title")} />
      <Card sx={{ padding: "20px", mt: "10px" }}>{t("notFound.text")}</Card>
    </>
  );
};

export default NotFoundPage;
