import { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Card, useTheme } from "@mui/material";
import Profile from "./newTerminal/Profile";
import BasicInfo from "./newTerminal/BasicInfo";
import AdditInfo from "./newTerminal/AdditInfo";
import Settings from "./newTerminal/Settings";
import Hardware from "./newTerminal/Hardware";
import BreadCrumb from "../components/breadcrumbs/BreadCrumb";
import { useTranslation } from "react-i18next";
import TerminalContext from "pages/newTerminal/TerminalContext";
import {
  useDuplicateTerminalDetail,
  useUpdateTerminalDetail,
} from "api/endpoints/terminals";
import { useParams } from "react-router-dom";
import FullscreenLoader from "components/fullscreenLoader";
import { useAppSelector } from "stores/store";
import { TERMINALS_URL } from "./appRoutes/appRoutesConst";

type steps = {
  id: number;
  name: string;
  Page: JSX.Element;
}[];

export const newTerminalSteps: steps = [
  { id: 0, name: "profile", Page: <Profile /> },
  { id: 1, name: "basicInfo", Page: <BasicInfo /> },
  { id: 2, name: "addInfo", Page: <AdditInfo /> },
  { id: 3, name: "settings", Page: <Settings /> },
  { id: 4, name: "hardware", Page: <Hardware /> },
];

export default function NewTerminal() {
  const { spacing } = useTheme();
  const { activeStep, setNewTerminal, setProfileName } =
    useContext(TerminalContext);
  const { t } = useTranslation("common", { keyPrefix: "newTerminal" });
  const { networkId } = useAppSelector((state) => state.user);

  const { mode, terminalId, merchantId, locationId } = useParams();

  if (
    (mode === "edit" || mode === "duplicate") &&
    (!terminalId || !merchantId || !locationId)
  ) {
    return null;
  }

  const { data: updateTerminalData, isLoading: updateLoading } =
    useUpdateTerminalDetail(
      networkId,
      merchantId!,
      locationId!,
      terminalId!,
      mode === "edit",
    );

  const { data: duplicateTerminalData, isLoading: duplicateLoading } =
    useDuplicateTerminalDetail(
      networkId,
      locationId!,
      merchantId!,
      terminalId!,
      mode === "duplicate",
    );

  useEffect(() => {
    if (mode === "edit" && updateTerminalData?.data) {
      setNewTerminal(updateTerminalData?.data);
      setProfileName(updateTerminalData?.data.profile);
    } else if (mode === "duplicate" && duplicateTerminalData?.data) {
      setNewTerminal(duplicateTerminalData?.data);
      setProfileName(duplicateTerminalData?.data.profile);
    }
  }, [updateTerminalData?.data, duplicateTerminalData?.data]);

  const generateBreadCrumb = () => {
    switch (mode) {
      case "create":
        return t("breadCrumbTitle2");
      case "edit":
        return t("breadCrumbEdit");
      case "duplicate":
        return t("breadCrumbDuplicate");
      default:
        return t("breadCrumbTitle2");
    }
  };

  if (updateLoading || duplicateLoading) return <FullscreenLoader />;

  return (
    <main>
      <BreadCrumb
        items={[
          { title: t("breadCrumbTitle1"), linkTo: TERMINALS_URL },
          { title: generateBreadCrumb() },
        ]}
      />
      <Box>
        <Stepper
          activeStep={activeStep}
          sx={{
            "& .MuiStepLabel-root .Mui-completed": {
              color: "secondary.dark",
            },
            "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
              {
                color: "grey.500",
              },
            "& .MuiStepLabel-root .Mui-active": {
              color: "secondary.main",
            },
            "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
              color: "common.white",
            },
            "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
              fill: "black",
            },
          }}
        >
          {newTerminalSteps.map((label, index) => {
            return (
              <Step key={index}>
                <StepLabel>{t(label.name)}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Card sx={{ mt: spacing(6), p: spacing(2) }}>
          {newTerminalSteps[activeStep].Page}
        </Card>
      </Box>
    </main>
  );
}
