import {
  Avatar,
  ClickAwayListener,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SvgIconTypeMap,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useState } from "react";
import { Lock, Logout } from "@mui/icons-material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useGlobalContext } from "GlobalContext";
import { COLORS } from "theme/colors";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "stores/store";
import { appErrorHandler } from "utils/funcs/appErrorHandler";

interface MenuItem {
  label: string;
  onClick: () => void;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, "svg">> & {
    muiName: string;
  };
}

export const UserAvatar = () => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { oidcRpClient } = useGlobalContext();
  const { accountUrl } = useAppSelector((state) => state.user);
  const { t } = useTranslation("common", {
    keyPrefix: "userAvatar",
  });

  const menuItems = [
    {
      label: "manageAccount",
      icon: <Lock />,
      onClick: () => {
        window.location.href = accountUrl;
      },
    },
    // {
    //   label: "helpAndFeedback",
    //   icon: <Info />,
    //   onClick: () => {
    //   },
    // },
    {
      label: "logout",
      icon: <Logout />,
      onClick: async () => {
        oidcRpClient
          .logout({
            postLogoutRedirectUri: `${window.location.origin}/logout`,
            state: "logout",
          })
          .then(() => {
            window.location.reload();
          })
          .catch((e) => {
            appErrorHandler(e);
          });
      },
    },
  ];

  return (
    <>
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setIsPopperOpen(!isPopperOpen);
        }}
        sx={{ p: 0 }}
      >
        <Avatar sx={{ bgcolor: COLORS.GREY.LIGHT }} alt="Avatar">
          <PersonIcon color="secondary" />
        </Avatar>
      </IconButton>
      <Popper
        style={{ zIndex: 10 }}
        open={isPopperOpen}
        placement="bottom-start"
        disablePortal={false}
        anchorEl={anchorEl}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (isPopperOpen) setIsPopperOpen(false);
          }}
        >
          <Paper sx={{ width: "fit-content", maxWidth: "100%" }}>
            <MenuList>
              {menuItems.map((menuItem, index) => (
                <MenuItem key={index} onClick={menuItem.onClick}>
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText>{t(menuItem.label)}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
