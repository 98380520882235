import { api } from "api";
import { useQuery } from "react-query";
import {
  bulkLocationsActionBody,
  FileType,
  LocationType,
  TLocation,
} from "types/commonTypes";
import { ApiResponse } from "types/utilityInterfaces";
import { getNotEmptyParams } from "utils/funcs/convert";
import { getFile } from "utils/funcs/downloadFile";

const getAllLocations = async (
  networkId: string,
  params: Partial<Record<string, unknown>>,
) => {
  const notEmptyParams = getNotEmptyParams(params);
  const response = await api.get<ApiResponse<LocationType[]>>(
    `/networks/${networkId}/locations`,
    {
      params: notEmptyParams,
    },
  );
  return response.data;
};

export const useGetAllLocationsQuery = (
  networkId: string,
  params: Partial<Record<string, unknown>>,
) =>
  useQuery(["getAllLocations", networkId, params], () =>
    getAllLocations(networkId, params),
  );

const getLocation = async (
  networkId: string,
  merchantId: string,
  locationId: string,
) => {
  const response = await api.get<LocationType>(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}`,
  );
  return response.data;
};

export const useGetLocationQuery = (
  networkId: string,
  merchantId: string,
  locationId: string,
) =>
  useQuery(
    ["getLocation", networkId, merchantId, locationId],
    () => getLocation(networkId, merchantId, locationId),
    {
      staleTime: 0,
    },
  );

export const exportLocations = async (
  networkId: string,
  format: FileType,
  params: Partial<Record<string, unknown>>,
) => {
  const notEmptyParams = getNotEmptyParams(params);
  const response = await getFile(
    `/networks/${networkId}/locations/export/${format}`,
    format,
    "Locations",
    notEmptyParams,
  );
  return response;
};

export const addLocation = async (networkId: string, req: TLocation) => {
  const response = await api.post<[]>(`/networks/${networkId}/locations`, req);
  return response;
};
export const importLocationsTemplate = async (networkId: string) => {
  const response = await api.get<TLocation>(
    `/networks/${networkId}/locations/import/template`,
  );
  return response;
};

export const importLocations = async (networkId: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await api.post(
    `/networks/${networkId}/locations/import`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response;
};

export const sendImportedLocations = async (
  networkId: string,
  req: TLocation[],
) => {
  const response = await api.post<[]>(
    `/networks/${networkId}/locations/import/send`,
    req,
  );
  return response;
};

export const downloadLocationsTemplate = async (networkId: string) => {
  const response = await getFile(
    `networks/${networkId}/locations/import/template`,
    "csv",
    "Locations template",
  );
  return response;
};

export const updateLocationDetail = async (
  networkId: string,
  locationId: string,
  merchantId: string,
) => {
  const response = await api.get<TLocation>(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/updatedata`,
  );
  return response;
};

export const duplicateLocationDetail = async (
  networkId: string,
  locationId: string,
  merchantId: string,
) => {
  const response = await api.get<TLocation>(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/duplicatedata`,
  );
  return response;
};

export const activateLocation = async (
  networkId: string,
  locationId: string,
  merchantId: string,
) => {
  const response = await api.post(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/activate`,
  );
  return response;
};

export const deactivateLocation = async (
  networkId: string,
  locationId: string,
  merchantId: string,
) => {
  const response = await api.post(
    `/networks/${networkId}/merchants/${merchantId}/locations/${locationId}/deactivate`,
  );
  return response;
};

export const bulkActivateLocations = async (
  networkId: string,
  body: bulkLocationsActionBody[],
) => {
  const response = await api.post(
    `/networks/${networkId}/locations/activate`,
    body,
  );
  return response;
};

export const bulkDeactivateLocations = async (
  networkId: string,
  body: bulkLocationsActionBody[],
) => {
  const response = await api.post(
    `/networks/${networkId}/locations/deactivate`,
    body,
  );
  return response;
};
