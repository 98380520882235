import { Typography, useTheme } from "@mui/material";
import { BaseParameter } from "types/enumTypes";
import CircleIcon from "@mui/icons-material/Circle";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface StatusProps {
  status: string;
  showCircle?: boolean;
}

export const Status: React.FC<StatusProps> = ({
  status,
  showCircle = true,
}) => {
  const [color, setColor] = useState("error");
  const theme = useTheme();
  const { palette } = theme;
  const { t } = useTranslation("common", {
    keyPrefix: "enums.ImportStatus",
  });

  useEffect(() => {
    switch (status) {
      case "A":
        setColor(palette.success.main);
        break;
      case "C":
        setColor(palette.text.lightGrey.main);
        break;
      case BaseParameter["ACTUAL"]:
        setColor(palette.success.main);
        break;
      case BaseParameter["OLD"]:
        setColor(palette.text.lightGrey.main);
        break;
      default:
        setColor(palette.error.main);
        break;
    }
  }, [status]);

  return (
    <Typography
      sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      color={color}
    >
      {showCircle && <CircleIcon sx={{ marginRight: 1, color }} />}
      <Typography component={"span"} sx={{ color }}>
        {status && status === BaseParameter[status as BaseParameter]
          ? status
          : t(`${status.toUpperCase()}`)}
      </Typography>
    </Typography>
  );
};
