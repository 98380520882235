import { Dialog, Grid, styled, Typography } from "@mui/material";
import {
  activateLocation,
  deactivateLocation,
  duplicateLocationDetail,
  updateLocationDetail,
  useGetLocationQuery,
} from "api/endpoints/locations";
import { useParams } from "react-router-dom";
import { useAppSelector } from "stores/store";
import AppButton from "../components/appButton";
import { AppDataContainer } from "../components/appDataContainer";
import BreadCrumb from "../components/breadcrumbs/BreadCrumb";
import { LOCATIONS_URL } from "./appRoutes/appRoutesConst";
import { detailQueryResult, LocationType, TLocation } from "types/commonTypes";
import { theme } from "theme";
import { COLORS } from "theme/colors";
import { useTranslation } from "react-i18next";
import { errorHandling } from "utils/funcs/errorHandling";
import { useState } from "react";
import ErrorDialog from "components/Dialog/ErrorDialog";
import SuccessDialog from "components/Dialog/SuccessDialog";
import { AddLocation } from "components/Dialog/addLocation";
import FullscreenLoader from "components/fullscreenLoader";

const StyledTitleText = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.body2,
  fontWeight: "400",
  color: COLORS.GREY.MAIN,
}));

const StyledValueText = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.body2,
  fontWeight: "700",
  color: COLORS.GREY.MAIN,
}));

export default function LocationDetail() {
  const { networkId } = useAppSelector((state) => state.user);
  const { locationId, merchantId } = useParams();
  const { spacing } = theme;
  const { t } = useTranslation("common", { keyPrefix: "locationDetail" });
  const { t: tStatus } = useTranslation("common", {
    keyPrefix: "enums.ImportStatus",
  });
  const [errors, setError] = useState<string[]>();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [defLocationData, setDefLocationData] = useState<TLocation>();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [dialogMode, setDialogMode] = useState<
    "create" | "update" | "duplicate"
  >();

  if (!locationId || !merchantId) return null;

  const { data, isLoading: loadingLocation } = useGetLocationQuery(
    networkId,
    merchantId,
    locationId,
  ) as detailQueryResult<LocationType>;

  const updateDataHandler = async () => {
    setIsLoading(true);
    await updateLocationDetail(networkId, locationId, merchantId)
      .then((resp) => {
        setDefLocationData(resp.data);
        setDialogMode("update");
        setIsEditDialogOpen(true);
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => setIsLoading(false));
  };

  const duplicateDataHandler = async () => {
    setIsLoading(true);

    await duplicateLocationDetail(networkId, locationId, merchantId)
      .then((resp) => {
        setDefLocationData(resp.data);
        setDialogMode("duplicate");
        setIsEditDialogOpen(true);
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => setIsLoading(false));
  };
  const activateLocationHandler = async () => {
    setIsLoading(true);

    await activateLocation(networkId, locationId, merchantId)
      .then(() => {
        setSuccessMessage(t("locationActivated"));
        setIsSuccessDialogOpen(true);
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => setIsLoading(false));
  };
  const deactivateLocationHandler = async () => {
    setIsLoading(true);

    await deactivateLocation(networkId, locationId, merchantId)
      .then(() => {
        setSuccessMessage(t("locationDeactivated"));
        setIsSuccessDialogOpen(true);
      })
      .catch((error) => {
        errorHandling(error, setError, setErrorDialogOpen);
      })
      .finally(() => setIsLoading(false));
  };

  const BUTTONS = [
    <AppButton
      variant="contained"
      color="secondary"
      size="small"
      key={0}
      onClick={updateDataHandler}
    >
      {t("editBtn")}
    </AppButton>,
    <AppButton
      variant="contained"
      color="secondary"
      size="small"
      key={1}
      onClick={duplicateDataHandler}
    >
      {t("duplicateBtn")}
    </AppButton>,
  ];

  if (data?.status === "C") {
    BUTTONS.push(
      <AppButton
        variant="contained"
        color="success"
        size="small"
        key={2}
        onClick={activateLocationHandler}
      >
        {t("activateBtn")}
      </AppButton>,
    );
  } else if (data?.status === "A") {
    BUTTONS.push(
      <AppButton
        variant="contained"
        color="error"
        size="small"
        key={2}
        onClick={deactivateLocationHandler}
      >
        {t("deactivateBtn")}
      </AppButton>,
    );
  }

  if (isLoading || loadingLocation) return <FullscreenLoader />;

  if (!data) return null;

  return (
    <div>
      <BreadCrumb
        items={[
          { title: t("breadCrumbTitle"), linkTo: LOCATIONS_URL },
          { title: `${data.name} (${data.identifier})` },
        ]}
      />
      <AppDataContainer
        sx={{ marginBottom: 3 }}
        titleRow={{
          title: t("basicInfoSectionTitle"),
          buttons: BUTTONS,
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          padding={spacing(4, 3)}
        >
          <Grid item xs={2} sm={4} md={2}>
            <StyledTitleText>{t("status")}</StyledTitleText>
            <StyledValueText>{tStatus(data.status)}</StyledValueText>
          </Grid>
          <Grid item xs={2} sm={4} md={2}>
            <StyledTitleText>{t("locationId")}</StyledTitleText>
            <StyledValueText>{data.identifier}</StyledValueText>
          </Grid>
          <Grid item xs={2} sm={4} md={3}>
            <StyledTitleText>{t("merchantName")}</StyledTitleText>
            <StyledValueText>{data.merchant_name}</StyledValueText>
          </Grid>
          <Grid item xs={2} sm={4} md={2}>
            <StyledTitleText>{t("network")}</StyledTitleText>
            <StyledValueText>{data.network_name}</StyledValueText>
          </Grid>
          <Grid item xs={2} sm={4} md={2}>
            <StyledTitleText>{t("acquirerId")}</StyledTitleText>
            <StyledValueText>{data.acquirer_id}</StyledValueText>
          </Grid>

          <Grid item xs={2} sm={4} md={2}>
            <StyledTitleText>{t("businessId")}</StyledTitleText>
            <StyledValueText>{data.business_id}</StyledValueText>
          </Grid>
          <Grid item xs={2} sm={4} md={2}>
            <StyledTitleText>{t("vatId")}</StyledTitleText>
            <StyledValueText>{data.vat_id}</StyledValueText>
          </Grid>
          <Grid item xs={2} sm={4} md={5}>
            <StyledTitleText>{t("address")}</StyledTitleText>
            <StyledValueText>{`${data.street}, ${data.city}, ${data.zip_code}`}</StyledValueText>
          </Grid>
          <Grid item xs={2} sm={4} md={2}>
            <StyledTitleText>{t("mcc")}</StyledTitleText>
            <StyledValueText>{data.mcc}</StyledValueText>
          </Grid>

          <Grid item xs={2} sm={4} md={12}>
            <StyledTitleText>{t("note")}</StyledTitleText>
            <StyledValueText>{data.note}</StyledValueText>
          </Grid>

          <Grid item xs={2} sm={4} md={2}>
            <StyledTitleText>{t("terminals")}</StyledTitleText>
            <StyledValueText>{data.terminal_count}</StyledValueText>
          </Grid>
        </Grid>
      </AppDataContainer>
      <Dialog open={isEditDialogOpen} fullWidth={true} maxWidth={"xs"}>
        <AddLocation
          handleClose={() => setIsEditDialogOpen(false)}
          defLocationData={defLocationData}
          mode={dialogMode}
        />
      </Dialog>
      <ErrorDialog
        isDialogOpen={errorDialogOpen}
        handleClose={() => setErrorDialogOpen(false)}
        messages={errors}
      />
      <SuccessDialog
        isDialogOpen={isSuccessDialogOpen}
        handleClose={() => setIsSuccessDialogOpen(false)}
        message={successMessage}
      />
    </div>
  );
}
