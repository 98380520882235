import { Group } from "types/commonTypes";

export function findGroupById(groups: Group[], id: string) {
  for (let i = 0; i < groups.length; i++) {
    const obj = groups[i];
    if (obj.roles && obj.roles.length > 0) {
      for (let j = 0; j < obj.roles.length; j++) {
        const role = obj.roles[j];
        if (role.id === id) {
          return obj;
        }
      }
    }
  }
  return null;
}
