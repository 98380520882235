import { api } from "../index";
import {
  createWidgeRequest,
  createWidgetResponse,
  LocationType,
  Widget,
  WidgetTypeDB,
} from "types/commonTypes";
import { useQuery } from "react-query";
import { getNotEmptyParams } from "utils/funcs/convert";

const getWidgets = async (networkId: string) => {
  const response = await api.get<Widget[]>(`/networks/${networkId}/widgets`);
  return response.data;
};

const getWidget = async (widgetId: number, networkId: string) => {
  const response = await api.get<Widget>(
    `/networks/${networkId}/widgets/${widgetId}`,
  );
  return response;
};

export const getsingleWidget = async (widgetId: number, networkId: string) => {
  const response = await getWidget(widgetId, networkId);
  if (response.status > 199 && response.status < 300) {
    return response.data;
  }
};

export const useGetWidgetsQuery = (networkId: string) =>
  useQuery(["getWidgets", networkId], () => getWidgets(networkId));

const getLocations = async (
  networkId: string,
  params: Partial<Record<string, unknown>>,
) => {
  const notEmptyParams = getNotEmptyParams(params);

  const response = await api.get<{ data: LocationType[] }>(
    `/networks/${networkId}/locations`,
    {
      params: notEmptyParams,
    },
  );
  return response.data?.data;
};

export const useLocationsQuery = (
  networkId: string,
  params: Partial<Record<string, unknown>>,
) =>
  useQuery(["getWidgetLocations", networkId, params], () =>
    getLocations(networkId, params),
  );

const getWidgetTypes = async () => {
  const response = await api.get<WidgetTypeDB[]>(`/widget/types`);
  return response.data;
};

export const useWidgetTypesQuery = () =>
  useQuery(["getWidgetTypes"], () => getWidgetTypes());

export const updateWidget = async (
  networkId: string,
  widgetId: string | number,
  req: createWidgeRequest,
) => {
  const response = await api.post<createWidgetResponse>(
    `/networks/${networkId}/widgets/${widgetId}`,
    req,
  );

  return response;
};

export const createWidget = async (
  networkId: string,
  req: createWidgeRequest,
) => {
  const response = await api.post<createWidgetResponse>(
    `/networks/${networkId}/widgets`,
    req,
  );
  return response;
};

export const deleteWidget = async (
  networkId: string,
  widgetId: string | number,
) => {
  const response = await api.delete<null>(
    `/networks/${networkId}/widgets/${widgetId}`,
  );
  return response;
};
