import { Grid, styled, Typography } from "@mui/material";
import { NetworkInfoWidgetData } from "types/commonTypes";
import { useTranslation } from "react-i18next";

const TextWrapper = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  gap: spacing(2),
  alignItems: "center",
  width: "100%",
  cursor: "pointer",
  padding: spacing(2),
}));
const LargeText = styled(Typography)(() => ({
  fontSize: "2.5rem",
  fontWeight: "bold",
}));
const SmallText = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.h6,
}));

interface NetworkInfoProps {
  data: NetworkInfoWidgetData;
}

export default function NetworkInfo(props: NetworkInfoProps) {
  const { data } = props;
  const { t } = useTranslation("common", {
    keyPrefix: "widgets.networkInfo",
  });
  return (
    <Grid container direction={"column"}>
      <Grid item alignSelf={"center"}>
        <TextWrapper>
          <LargeText>{data.merchants}</LargeText>
          <SmallText>{t("merchants")}</SmallText>
        </TextWrapper>
        <TextWrapper>
          <LargeText>{data.locations}</LargeText>
          <SmallText>{t("locations")}</SmallText>
        </TextWrapper>
        <TextWrapper>
          <LargeText>{data.terminals}</LargeText>
          <SmallText>{t("terminals")}</SmallText>
        </TextWrapper>
      </Grid>
    </Grid>
  );
}
