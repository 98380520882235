import { Route, Routes } from "react-router-dom";
import Dashboard from "../Dashboard";
import Import from "../Import";
import ImportError from "../Import/ImportError";
import ImportOverview from "../Import/ImportOverview";
import ImportDone from "../Import/ImportDone";
import Merchants from "../Merchants";
import Messages from "../messages/Messages";
import NewTerminal from "../NewTerminal";
import TerminalDetail from "../newTerminal/TerminalDetail";
import Locations from "pages/Locations";
import Terminals from "../Terminals";
import MessageDetail from "../messages/MessageDetail";
import {
  CALLBACK_URL,
  DASHBOARD_URL,
  IMPORT_MERCHANTS_URL,
  IMPORT_PAGES,
  IMPORT_TERMINAL_URL,
  LOGOUT_URL,
  MERCHANTS_URL,
  MERCHANT_DETAIL_URL,
  MESSAGE_DETAIL_URL,
  LOCATIONS_URL,
  LOCATION_DETAIL_URL,
  TERMINALS_URL,
  TERMINAL_PAGES,
  IMPORT_LOCATIONS_URL,
  IMPORT_HARDWARE_URL,
  TERMINALS_DETAIL_URL,
  ERROR_PAGE,
} from "./appRoutesConst";
import MerchantDetail from "../MerchantDetail";
import LocationDetail from "pages/LocationDetail";
import { ImportType } from "types/enumTypes";
import { ImportContextProvider } from "pages/Import/ImportContext";
import { AuthProvider } from "providers/authProvider";
import Login from "pages/Callback";
import { TerminalContextProvider } from "pages/newTerminal/TerminalContext";
import Logout from "pages/Logout";
import ErrorPage from "pages/ErrorPage";
import NotFoundPage from "pages/NotFoundPage";

export default function AppRoutes() {
  return (
    <ImportContextProvider>
      <TerminalContextProvider>
        <Routes>
          <Route element={<AuthProvider />}>
            <Route element={<Dashboard />} path={DASHBOARD_URL} />
            <Route element={<Merchants />} path={MERCHANTS_URL} />
            <Route element={<MerchantDetail />} path={MERCHANT_DETAIL_URL} />
            <Route element={<Locations />} path={LOCATIONS_URL} />
            <Route element={<LocationDetail />} path={LOCATION_DETAIL_URL} />
            <Route path={TERMINALS_URL} index element={<Terminals />} />
            <Route
              element={<NewTerminal />}
              path={TERMINAL_PAGES.NEW_TERMINAL_URL}
            />
            <Route
              element={<NewTerminal />}
              path={TERMINAL_PAGES.EDIT_TERMINAL_URL}
            />
            <Route
              element={<NewTerminal />}
              path={TERMINAL_PAGES.DUPLICATE_TERMINAL_URL}
            />
            <Route element={<TerminalDetail />} path={TERMINALS_DETAIL_URL} />
            <Route path={IMPORT_TERMINAL_URL}>
              <Route element={<Import type={ImportType.TERMINAL} />} index />
              <Route
                element={<ImportOverview type={ImportType.TERMINAL} />}
                path={IMPORT_PAGES.OVERVIEW_URL}
              />
              <Route element={<ImportDone />} path={IMPORT_PAGES.DONE_URL} />
              <Route element={<ImportError />} path={IMPORT_PAGES.ERROR_URL} />
            </Route>
            <Route path={IMPORT_LOCATIONS_URL}>
              <Route element={<Import type={ImportType.LOCATION} />} index />
              <Route
                element={<ImportOverview type={ImportType.LOCATION} />}
                path={IMPORT_PAGES.OVERVIEW_URL}
              />
              <Route element={<ImportDone />} path={IMPORT_PAGES.DONE_URL} />
              <Route element={<ImportError />} path={IMPORT_PAGES.ERROR_URL} />
            </Route>
            <Route path={IMPORT_HARDWARE_URL}>
              <Route element={<Import type={ImportType.HARDWARE} />} index />
              <Route
                element={<ImportOverview type={ImportType.HARDWARE} />}
                path={IMPORT_PAGES.OVERVIEW_URL}
              />
              <Route element={<ImportDone />} path={IMPORT_PAGES.DONE_URL} />
              <Route element={<ImportError />} path={IMPORT_PAGES.ERROR_URL} />
            </Route>
            <Route path={IMPORT_MERCHANTS_URL}>
              <Route element={<Import type={ImportType.MERCHANT} />} index />
              <Route
                element={<ImportOverview type={ImportType.MERCHANT} />}
                path={IMPORT_PAGES.OVERVIEW_URL}
              />
              <Route element={<ImportDone />} path={IMPORT_PAGES.DONE_URL} />
              <Route element={<ImportError />} path={IMPORT_PAGES.ERROR_URL} />
            </Route>

            <Route path="messages">
              <Route element={<Messages />} index />
              <Route element={<MessageDetail />} path={MESSAGE_DETAIL_URL} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
          <Route path={CALLBACK_URL} element={<Login />} />
          <Route path={LOGOUT_URL} element={<Logout />} />
          <Route path={ERROR_PAGE} element={<ErrorPage />} />
        </Routes>
      </TerminalContextProvider>
    </ImportContextProvider>
  );
}
