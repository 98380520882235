import AuthGuard from "components/authGuard";
import { Outlet } from "react-router-dom";

export const AuthProvider = () => {
  return (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  );
};
