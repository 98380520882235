import { ApiResponse } from "types/utilityInterfaces";
import { useQuery } from "react-query";
import {
  bulkMerchantsActionBody,
  FileType,
  Merchant,
  MerchantImport,
  MerchantImportData,
} from "types/commonTypes";
import { getNotEmptyParams } from "utils/funcs/convert";
import { getFile } from "utils/funcs/downloadFile";
import { api } from "../index";

const getAllMerchants = async (
  networkId: string,
  params: Partial<Record<string, unknown>>,
) => {
  const notEmptyParams = getNotEmptyParams(params);
  const response = await api.get<ApiResponse<Merchant[]>>(
    `/networks/${networkId}/merchants`,
    {
      params: notEmptyParams,
    },
  );
  return response.data;
};

export const useGetAllMerchantsQuery = (
  networkId: string,
  params: Partial<Record<string, unknown>>,
) =>
  useQuery(["getAllMerchants", networkId, params], () =>
    getAllMerchants(networkId, params),
  );

const getMerchant = async (networkId: string, merchantId: string) => {
  const response = await api.get<Merchant>(
    `/networks/${networkId}/merchants/${merchantId}`,
  );
  return response.data;
};
export const useGetMerchantImportDataQuery = (networkId: string) =>
  useQuery(["getMerchantImportData", networkId], () =>
    getMerchantImportData(networkId),
  );
const getMerchantImportData = async (networkId: string) => {
  const response = await api.get<MerchantImportData[]>(
    `/networks/${networkId}/merchants/importdata`,
  );
  return response.data;
};

export const useGetMerchantQuery = (networkId: string, merchantId: string) =>
  useQuery(
    ["getMerchant", networkId, merchantId],
    () => getMerchant(networkId, merchantId),
    {
      staleTime: 0,
    },
  );

export const exportMerchants = async (
  networkId: string,
  format: FileType,
  params: Partial<Record<string, unknown>>,
) => {
  const notEmptyParams = getNotEmptyParams(params);
  const response = await getFile(
    `/networks/${networkId}/merchants/export/${format}`,
    format,
    "Merchants",
    notEmptyParams,
  );
  return response;
};

export const importMerchants = async (networkId: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await api.post<MerchantImport[]>(
    `/networks/${networkId}/merchants/import`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response;
};

export const sendImportedMerchants = async (
  networkId: string,
  req: MerchantImport[],
) => {
  const response = await api.post<[]>(
    `/networks/${networkId}/merchants/import/send`,
    req,
  );
  return response;
};

export const addMerchant = async (networkId: string, req: MerchantImport) => {
  const response = await api.post<[]>(`/networks/${networkId}/merchants`, req);
  return response;
};

export const downloadMerchantsTemplate = async (networkId: string) => {
  const response = await getFile(
    `networks/${networkId}/merchants/import/template`,
    "csv",
    "Merchants template",
  );
  return response;
};
export const updateMerchantDetail = async (
  networkId: string,
  merchantId: string,
) => {
  const response = await api.get<MerchantImport>(
    `/networks/${networkId}/merchants/${merchantId}/updatedata`,
  );
  return response;
};

export const duplicateMerchantDetail = async (
  networkId: string,
  merchantId: string,
) => {
  const response = await api.get<MerchantImport>(
    `/networks/${networkId}/merchants/${merchantId}/duplicatedata`,
  );
  return response;
};

export const activateMerchant = async (
  networkId: string,
  merchantId: string,
) => {
  const response = await api.post(
    `/networks/${networkId}/merchants/${merchantId}/activate`,
  );
  return response;
};

export const deactivateMerchant = async (
  networkId: string,
  merchantId: string,
) => {
  const response = await api.post(
    `/networks/${networkId}/merchants/${merchantId}/deactivate`,
  );
  return response;
};

export const bulkActivateMerchants = async (
  networkId: string,
  body: bulkMerchantsActionBody[],
) => {
  const response = await api.post(
    `/networks/${networkId}/merchants/activate`,
    body,
  );
  return response;
};

export const bulkDeactivateMerchants = async (
  networkId: string,
  body: bulkMerchantsActionBody[],
) => {
  const response = await api.post(
    `/networks/${networkId}/merchants/deactivate`,
    body,
  );
  return response;
};
