import { styled, Typography } from "@mui/material";
import { TerminalHardwareWidgetData } from "types/commonTypes";

const Collumns = styled("div")(({ theme: { spacing } }) => ({
  padding: spacing(3, 2),
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: spacing(2),
}));
const Collumn = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  gap: spacing(2),
}));
const TextBox = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  alignItems: "baseline",
  justifyContent: "center",
  gap: spacing(1),
}));
const LargeText = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.h5,
  textAlign: "right",
}));

const SmallText = styled(Typography)(({ theme: { spacing } }) => ({
  whiteSpace: "nowrap",
  display: "block",
  lineHeight: spacing(4),
}));

interface TerminalsHardwareProps {
  data: TerminalHardwareWidgetData[];
}

export default function TerminalsHardware(props: TerminalsHardwareProps) {
  const { data } = props;

  return (
    <Collumns>
      {data?.map((row, index) => {
        return (
          <TextBox key={index}>
            <Collumn>
              <LargeText>{row.count}</LargeText>
            </Collumn>
            <Collumn>
              <SmallText>{row.type}</SmallText>
            </Collumn>
          </TextBox>
        );
      })}
    </Collumns>
  );
}
