import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FilterTypes } from "components/filters";
import { isEqual } from "lodash";

const defaultState = {
  MERCHANT_ID: "",
  MERCHANT_NAME: "",
  MERCHANT_STATUS: "",
  MERCHANT_BUSINESS_ID: "",
  LOCATION_ID: "",
  LOCATION_NAME: "",
  LOCATION_STATUS: "",
  LOCATION_BUSINESS_ID: "",
  TERMINAL_ID: "",
  TERMINAL_MERCHANT_ID: "",
  TERMINAL_STATUS: "",
  SERIAL_NUMBER: "",
  HARDWARE_LOCATION: "",
  HARDWARE_STATUS: "",
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FiltersContext = createContext<any>(null);

export const useFiltersContext = () => useContext(FiltersContext);

export const FiltersProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const filters = params.get("filters");
  const [filterInputsData, setFilters] = useState(defaultState);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (filters) {
      setFilters({ ...defaultState, ...JSON.parse(filters) });
    } else {
      setFilters({ ...defaultState });
    }
    setIsInitialized(true);
  }, [location.pathname]);

  useEffect(() => {
    if (isInitialized) {
      const params = new URLSearchParams(location.search);

      const nonDefaultFilters = Object.entries(filterInputsData)
        .filter(([key, value]) => {
          const defaultValue = defaultState[key as FilterTypes];
          return !isEqual(value, defaultValue);
        })
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {} as any);

      if (Object.keys(nonDefaultFilters).length > 0) {
        params.set("filters", JSON.stringify(nonDefaultFilters));
      } else {
        params.delete("filters");
      }

      navigate({ search: params.toString() }, { replace: true });
    }
  }, [filterInputsData, isInitialized]);

  return (
    <FiltersContext.Provider value={{ filterInputsData, setFilters }}>
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersContext;
