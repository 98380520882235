import { AppDonutChart } from "../charts/donutChart";
import { AppPieChart } from "../charts/pieChart";
import { useState } from "react";
import AppButton from "../appButton";
import { ZoomIn } from "@mui/icons-material";
import BarChartDialog from "../Dialog/BarChartDialog";
import { NetworkHealthWidgetData } from "types/commonTypes";
import { useTranslation } from "react-i18next";

interface NetworkhealthWidgetProps {
  data: NetworkHealthWidgetData;
}

export const NetworkhealthWidget = (props: NetworkhealthWidgetProps) => {
  const { data } = props;
  const { t } = useTranslation("common", {
    keyPrefix: "widgets.networkHealth",
  });
  const dataFormated = [
    {
      name: t("active"),
      value: data["active_percentage"],
    },
    {
      name: t("inactive"),
      value: data["inactive_percentage"],
    },
    {
      name: t("error"),
      value: data["error_percentage"],
    },
  ];
  const [dialog, setDialog] = useState(false);
  const openDialog = () => {
    setDialog(true);
  };
  const closeDialog = () => {
    setDialog(false);
  };

  return (
    <>
      {dataFormated.length > 6 ? (
        <AppPieChart data={dataFormated} />
      ) : (
        <AppDonutChart data={dataFormated} />
      )}
      <AppButton
        startIcon={<ZoomIn />}
        color="secondary"
        variant="text"
        sx={{
          bgcolor: "transparent !important",
          position: "absolute",
          bottom: 0,
          left: 2,
        }}
        onClick={openDialog}
      >
        {t("zoom")}
      </AppButton>
      <BarChartDialog
        data={dataFormated}
        handleClose={closeDialog}
        isDialogOpen={dialog}
      />
    </>
  );
};
