import {
  Select,
  MenuItem,
  useTheme,
  alpha,
  darken,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/KeyboardArrowDown";
import dayjs from "dayjs";
import czechLocale from "dayjs/locale/cs";
import germanLocale from "dayjs/locale/de";
import spainLocale from "dayjs/locale/se";
import frenchLocale from "dayjs/locale/fr";
import dutchLocale from "dayjs/locale/nl";
import { User } from "stores/slices/userSlice";
import { useAppSelector } from "stores/store";

interface UseLanguageSelectProps {
  color?: "whiteText";
  isMobile?: boolean;
  isAuthorized?: boolean;
}

const CZECH = "cs";
const ENGLISH = "en";
const GERMAN = "de";
const SPANISH = "es";
const FRENCH = "fr";
const DUTCH = "nl";

const LANGUAGES = [
  { value: ENGLISH, label: "english" },
  { value: CZECH, label: "czech" },
  { value: GERMAN, label: "german" },
  { value: SPANISH, label: "spanish" },
  { value: FRENCH, label: "french" },
  { value: DUTCH, label: "dutch" },
];

const useLanguageSelect = ({
  color = undefined,
  isMobile,
  isAuthorized = true,
}: UseLanguageSelectProps = {}) => {
  const { t, i18n } = useTranslation("common", {
    keyPrefix: "navigation.languages",
  });
  const langFromLS = localStorage.getItem("TMSLang") || ENGLISH;
  const { lang } = useAppSelector((state) => state.user);
  const [language, setLanguage] = useState(langFromLS);
  const theme = useTheme();
  const { palette } = theme;

  useEffect(() => {
    if (language === CZECH) {
      dayjs.locale(czechLocale);
    } else if (language === GERMAN) {
      dayjs.locale(germanLocale);
    } else if (language === SPANISH) {
      dayjs.locale(spainLocale);
    } else if (language === FRENCH) {
      dayjs.locale(frenchLocale);
    } else if (language === DUTCH) {
      dayjs.locale(dutchLocale);
    } else {
      dayjs.locale(ENGLISH);
    }
    i18n.changeLanguage(language);
  }, [language]);

  const variableColor = useMemo(
    () =>
      color === "whiteText" ? palette.text.white.main : palette.text.black.main,
    [color],
  );

  const changeLanguage = (e: SelectChangeEvent<string>) => {
    const locale = e.target.value;
    setLanguage(locale as User["lang"]);
    localStorage.setItem("sync-locale", locale === lang ? "yes" : "no");
    localStorage.setItem("TMSLang", locale);
  };

  return (
    <Select
      value={language}
      onChange={changeLanguage}
      MenuProps={{
        sx: {
          "&& .MuiPopover-paper": {
            backgroundColor:
              color === "whiteText"
                ? alpha(palette.common.black, 0.3)
                : palette.text.white.main,
            "& .Mui-selected": {
              backgroundColor:
                color === "whiteText"
                  ? darken(palette.common.black, 0.3)
                  : "initial",
            },
            " .MuiMenuItem-root:hover": {
              backgroundColor:
                color === "whiteText"
                  ? alpha(palette.text.white.main, 0.3)
                  : palette.primary.main,
            },
            " .MuiMenuItem-root": {
              backgroundColor:
                color === "whiteText" ? "transparent" : "initial",
            },
          },
        },
      }}
      sx={{
        color: variableColor,
        "&.MuiOutlinedInput-root .MuiSelect-icon": {
          color: variableColor,
        },
        "&.MuiOutlinedInput-root": {
          width: isMobile ? "100%" : "initial",
        },
        "& .MuiOutlinedInput-input": {
          padding: 0,
        },
        "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      }}
      IconComponent={ArrowDropDownIcon}
    >
      {LANGUAGES.map((lang, index) => (
        <MenuItem sx={{ color: variableColor }} key={index} value={lang.value}>
          {isAuthorized ? t(lang.label) : t(`full_name.${lang.label}`)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default useLanguageSelect;
