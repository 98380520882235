import { PictureAsPdf } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";

export default function MessageDetail() {
  const { spacing } = useTheme();
  return (
    <main>
      <Typography variant="h4">We have updated our Terms of Service</Typography>
      <Typography fontWeight={300} sx={{ mt: spacing(2) }}>
        9.11.2021 14:34
      </Typography>
      <Typography sx={{ mt: spacing(4) }} variant="subtitle1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
        bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra
        justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam
        fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci,
        sed rhoncus pronin sapien nunc accuan eget.
      </Typography>
      <Typography sx={{ my: spacing(3) }} variant="subtitle1">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod
        bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra
        justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque
        penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam
        fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci,
        sed rhoncus pronin sapien nunc accuan eget.
      </Typography>
      <Typography variant="h6">Attachments</Typography>
      <Box
        sx={{
          maxWidth: spacing(30),
          display: "flex",
          gap: spacing(1),
          alignItems: "center",
          pt: spacing(1),
        }}
      >
        <PictureAsPdf fontSize="large" />
        <Typography variant="subtitle2">
          New Terms of Service Switchio TMS Console.pdf
        </Typography>
      </Box>
    </main>
  );
}
