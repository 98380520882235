import { alpha } from "@mui/material";

export const COLORS = {
  LIGHT_BLUE: {
    EXTRA_LIGHT: "#F1FAFD",
    LIGHT: "#DDF2F6",
    MAIN: "#C1E4EB",
  },
  DARK_BLUE: {
    LIGHT: "#8DC9D6",
    MAIN: "#001145",
  },
  LIGHT_GREEN: {
    LIGHT: alpha("#00B929", 0.05),
    MAIN: "#00B929",
  },
  ORANGE: {
    MAIN: "#f79a1b",
  },
  YELLOW: {
    MAIN: "#FDC638",
  },
  RED: {
    LIGHT: alpha("#F54432", 0.15),
    MAIN: "#F54432",
  },
  LIGHT_GREY: {
    LIGHT: "#FAFAFA",
    MAIN: "#F5F5F5",
    DARK: "#E0E0E0",
  },
  GREY: {
    LIGHT: "#BCD0DB",
    MAIN: "#666673",
    DARK: "#001145",
  },
  DARK_GRAY: {
    MAIN: "#A4ADC0",
  },
  BLACK: "#000000",
  WHITE: "#FFFFFF",
};
