import { NotificationLayout } from "components/layouts/NotificationLayout";
import { useTranslation } from "react-i18next";
import { Stack, Typography, useTheme } from "@mui/material";
import errorImg from "assets/images/error.svg";
import AppLink from "components/appLink/appLink";

const ErrorPage = () => {
  const { t } = useTranslation("common");
  const theme = useTheme();

  return (
    <NotificationLayout>
      <Stack alignItems="center">
        <img width="280px" src={errorImg} alt="errorImg" />
        <Typography
          variant="h1"
          textAlign="center"
          mt={"80px"}
          sx={{ fontSize: "3rem !important" }}
        >
          {t("error_page.title")}
        </Typography>
        <AppLink
          style={{
            fontSize: "1.5rem",
            color: theme.palette.text.darkBlue.main,
            marginTop: "24px",
          }}
          to={"/"}
        >
          {t("error_page.subtitle")}
        </AppLink>
      </Stack>
    </NotificationLayout>
  );
};

export default ErrorPage;
