import { useGlobalContext } from "GlobalContext";
import AuthenticatedLayout from "components/layouts/AuthenticatedLayout";
import { CALLBACK_URL } from "pages/appRoutes/appRoutesConst";
import { useEffect } from "react";
import { appErrorHandler } from "utils/funcs/appErrorHandler";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
interface Props {
  children: React.ReactNode;
}

const AuthGuard = ({ children }: Props) => {
  const { oidcRpClient, oidcRpLoginData } = useGlobalContext();
  const { i18n } = useTranslation();
  const getCurrentLocale = () =>
    localStorage.getItem("TMSLang") ?? i18n.resolvedLanguage;

  const location = useLocation();
  const login = () => {
    oidcRpClient
      .loginWithRedirect({
        redirectUri: `${window.location.origin}${CALLBACK_URL}`,
        scope: "",
        state: `${location.pathname}${location.search}`,
        uiLocales: getCurrentLocale(),
      })
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        appErrorHandler(e);
      });
  };
  useEffect(() => {
    if (!oidcRpLoginData) {
      login();
    }
  }, []);

  return <AuthenticatedLayout>{children}</AuthenticatedLayout>;
};

export default AuthGuard;
