import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AddCircleOutline,
  ArrowForwardIos,
  Download,
} from "@mui/icons-material";
import {
  alpha,
  Box,
  Dialog,
  Grid,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import AppButton from "../components/appButton";
import AppTextField from "../components/form/textField";
import AppSelect from "../components/form/select";
import useFilters from "../components/filters";
import { AppTable, AppTableProps } from "../components/appTable";
import useSelectInTable from "../components/appTable/useSelectInTable";
import { useAppSelector } from "stores/store";
import {
  bulkActivateLocations,
  bulkDeactivateLocations,
  exportLocations,
  useGetAllLocationsQuery,
} from "api/endpoints/locations";
import { LocationType, SortModel } from "types/commonTypes";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Status } from "components/status";
import AppLink from "components/appLink/appLink";
import { fromRoot, IMPORT_LOCATIONS_URL } from "./appRoutes/appRoutesConst";
import { AddLocation } from "components/Dialog/addLocation";
import { useTranslation } from "react-i18next";
import { errorHandling } from "utils/funcs/errorHandling";
import ErrorDialog from "components/Dialog/ErrorDialog";
import SuccessDialog from "components/Dialog/SuccessDialog";
import { useDebounce } from "utils/hooks/useDebounce";
import { PageTitle } from "components/pageTitle";
import FullscreenLoader from "components/fullscreenLoader";
import usePagination from "utils/hooks/usePagination";
import { useLocation, useNavigate } from "react-router-dom";
import useQueryParameters from "utils/hooks/useQueryParameters";
import { createHandleSortModelChange } from "utils/funcs/createHandleSortModelChange";

export default function Locations() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const getQueryParameter = useQueryParameters();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const params = new URLSearchParams(location.search);
  const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errors, setError] = useState<string[]>();
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { networkId } = useAppSelector((state) => state.user);
  const { page, setPage, pageSize, setPageSize } = usePagination({
    location,
    navigate,
  });

  const queryOptionsQP = useMemo(
    () => getQueryParameter("queryOptions"),
    [location.search],
  );
  const filtersQP = useMemo(
    () => getQueryParameter("filters"),
    [location.search],
  );
  const [queryOptions, setQueryOptions] = useState<SortModel | null>(
    queryOptionsQP,
  );

  const {
    t: tStatusEnum,
    i18n: { resolvedLanguage },
  } = useTranslation("common", {
    keyPrefix: "enums.filterStatusSelect",
  });
  const { t } = useTranslation("common", {
    keyPrefix: "components.locations",
  });

  const { TableCheckbox, tableProps, selectedRows } =
    useSelectInTable<string>();
  const { Filters, filterInputsData, reset } = useFilters({
    inputs: [
      {
        Element: AppTextField,
        name: "LOCATION_ID",
        label: "locationId/acquirerId",
      },
      { Element: AppTextField, name: "LOCATION_NAME", label: "name" },
      {
        Element: (field) => (
          <AppSelect {...field}>
            <MenuItem value="">{tStatusEnum("all")}</MenuItem>
            <MenuItem value="A">{tStatusEnum("active")}</MenuItem>
            <MenuItem value="C">{tStatusEnum("inactive")}</MenuItem>
          </AppSelect>
        ),
        name: "LOCATION_STATUS",
        label: "status",
      },
      {
        Element: AppTextField,
        name: "LOCATION_BUSINESS_ID",
        label: "businessId",
      },
    ],
    filters: [
      "LOCATION_ID",
      "LOCATION_NAME",
      "LOCATION_STATUS",
      "LOCATION_BUSINESS_ID",
    ],
    namePairs: [
      { filterName: "LOCATION_ID", queryname: "locationId" },
      { filterName: "LOCATION_NAME", queryname: "name" },
      { filterName: "LOCATION_STATUS", queryname: "status" },
      { filterName: "LOCATION_BUSINESS_ID", queryname: "businessId" },
    ],
  });

  useEffect(() => {
    reset(filtersQP);
  }, []);

  const debouncedName = useDebounce(filterInputsData?.name, 400);
  const debouncedLocationId = useDebounce(filterInputsData?.locationId, 400);
  const debouncedBusinessId = useDebounce(filterInputsData?.businessId, 400);
  const debouncedStatus = useDebounce(filterInputsData?.status, 400);

  const { data, isLoading: loadingLocations } = useGetAllLocationsQuery(
    networkId,
    {
      ...filterInputsData,
      offset: page * pageSize,
      limit: pageSize,
      order_column: queryOptions?.field,
      order_type: queryOptions?.sort.toUpperCase(),
      name: debouncedName,
      locationId: debouncedLocationId,
      status: debouncedStatus,
      businessId: debouncedBusinessId,
    },
  );
  const filteredSelectedRows = data?.data
    .filter((location) => selectedRows.includes(location.identifier.toString()))
    .map((row) => ({
      merchantIdentifier: row.merchant_identifier,
      locationIdentifier: row.identifier,
    }));

  const handleSortModelChange = useCallback(
    createHandleSortModelChange({
      params,
      navigate,
      setQueryOptions,
      setPage,
    }),
    [navigate, params, setQueryOptions, setPage],
  );

  const confirmActionHandler = async (select: string) => {
    if (select === "Activate" && filteredSelectedRows) {
      setIsLoading(true);
      await bulkActivateLocations(networkId, filteredSelectedRows)
        .then(() => {
          setSuccessMessage(t("locationsActivated"));
          setIsSuccessDialogOpen(true);
        })
        .catch((error) => {
          errorHandling(error, setError, setErrorDialogOpen);
        })
        .finally(() => setIsLoading(false));
    }

    if (select === "Deactivate" && filteredSelectedRows) {
      setIsLoading(true);
      await bulkDeactivateLocations(networkId, filteredSelectedRows)
        .then(() => {
          setSuccessMessage(t("locationsDeactivated"));
          setIsSuccessDialogOpen(true);
        })
        .catch((error) => {
          errorHandling(error, setError, setErrorDialogOpen);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const LOCATIONS_COLUMNS: AppTableProps["columns"] = useMemo(
    () => [
      {
        field: "checked",
        headerName: "",
        renderCell: ({ row }) => {
          return <TableCheckbox rowId={row.id} />;
        },
      },
      {
        field: "id",
        headerName: "id",
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return <>{row.identifier}</>;
        },
        flex: 1,
      },
      {
        field: "name",
        headerName: "name",
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return <>{row.name}</>;
        },
        flex: 1,
      },
      {
        field: "acquirerId",
        headerName: "acquirerId",
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return <>{row.acquirer_id}</>;
        },
        flex: 1,
      },
      {
        field: "merchant",
        headerName: "merchant",
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return <>{row.merchant_name}</>;
        },
        flex: 1,
      },
      {
        field: "terminals",
        headerName: "terminals",
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return <>{row.terminal_count}</>;
        },
        flex: 1,
      },
      {
        field: "status",
        headerName: "status",
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return <Status status={row.status} />;
        },
        flex: 1,
      },
      {
        field: "goToDetail",
        sortable: false,
        headerName: "",
        renderCell: (params: GridRenderCellParams<LocationType>) => {
          const { row } = params;

          return (
            <AppLink
              to={`${row.identifier}/${row.merchant_identifier}`}
              isGreyLink
            >
              <ArrowForwardIos />
            </AppLink>
          );
        },
      },
    ],
    [selectedRows, resolvedLanguage],
  );

  if (isLoading) return <FullscreenLoader />;

  return (
    <main>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: isMobile ? "center" : "space-between",
          alignItems: "center",
        }}
      >
        <PageTitle title={t("title")} />
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "space-between",
          }}
        >
          <AppLink to={fromRoot(IMPORT_LOCATIONS_URL)}>
            <AppButton
              variant="contained"
              startIcon={<Download />}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                ":hover": {
                  backgroundColor: alpha(theme.palette.secondary.main, 0.8),
                },
              }}
            >
              {t("importsBtn")}
            </AppButton>
          </AppLink>
          <AppButton
            variant="contained"
            startIcon={<AddCircleOutline />}
            onClick={() => setIsAddLocationModalOpen(true)}
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              ":hover": {
                backgroundColor: alpha(theme.palette.secondary.main, 0.8),
              },
            }}
          >
            {t("addButton")}
          </AppButton>
        </Box>
      </Grid>
      {Filters}
      <AppTable
        loading={loadingLocations}
        handleSortModelChange={handleSortModelChange}
        rows={data?.data ?? []}
        columns={LOCATIONS_COLUMNS}
        appTitle={"List"}
        showSettings={false}
        mapComponent={false}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        totalRows={data?.paging.total_count ?? 0}
        {...tableProps}
        rowSelected={filteredSelectedRows?.length}
        confirmActionHandler={confirmActionHandler}
        exportMenuItems={[
          {
            onClick: async () =>
              await exportLocations(networkId, "xlsx", filterInputsData),
            label: "XLSX",
          },
          {
            onClick: async () =>
              await exportLocations(networkId, "csv", filterInputsData),
            label: "CSV",
          },
        ]}
      />
      <Dialog open={isAddLocationModalOpen} fullWidth={true} maxWidth={"xs"}>
        <AddLocation handleClose={() => setIsAddLocationModalOpen(false)} />
      </Dialog>
      <ErrorDialog
        isDialogOpen={errorDialogOpen}
        handleClose={() => setErrorDialogOpen(false)}
        messages={errors}
      />
      <SuccessDialog
        isDialogOpen={isSuccessDialogOpen}
        handleClose={() => setIsSuccessDialogOpen(false)}
        message={successMessage}
      />
    </main>
  );
}
