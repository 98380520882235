import { useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";

function useQueryParameters() {
  const location = useLocation();

  const queryParameters = useMemo(() => {
    return Object.fromEntries(new URLSearchParams(location.search).entries());
  }, [location.search]);

  const getQueryParameter = useCallback(
    (key: string) => {
      const value = queryParameters[key];
      return value ? JSON.parse(value) : null;
    },
    [queryParameters],
  );

  return getQueryParameter;
}

export default useQueryParameters;
