import { Checkbox } from "@mui/material";
import { useState } from "react";

export default function useSelectInTable<T>() {
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCheckboxClick = (rowId: T) => (e: any) => {
    e.target.checked
      ? setSelectedRows((cur) => [...cur, rowId])
      : setSelectedRows((cur) => cur.filter((id) => rowId !== id));
  };
  const isChecked = (rowId: T) => selectedRows.some((id) => rowId === id);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRowClick = (props: any) => {
    const rowId = props.row.id;
    const checked = !isChecked(rowId);
    return handleCheckboxClick(rowId)({ target: { checked } });
  };

  const TableCheckbox = ({ rowId }: { rowId: T }) => (
    <Checkbox checked={isChecked(rowId)} color="secondary" />
  );

  return {
    TableCheckbox,
    selectedRows,
    tableProps: {
      onRowClick: handleRowClick,
      rowSelected: selectedRows.length,
    },
  };
}
