import { styled, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { COLORS } from "theme/colors";

interface FormFieldProps {
  label: React.ReactNode;
  error: boolean | undefined;
  errorText?: string;
  required: boolean | undefined;
  optional?: boolean;
  children: React.ReactNode;
  htmlFor?: string;
}

const TopInfoWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "label",
})<{
  label: FormFieldProps["label"];
}>(({ label, theme: { spacing } }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: label ? spacing(0.7) : 0,
}));

const FormField: React.FC<FormFieldProps> = ({
  children,
  label,
  error,
  errorText,
  optional,
  required,
  htmlFor,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("common", {
    keyPrefix: "components.formFields",
  });

  return (
    <div>
      <TopInfoWrapper label={label}>
        {label && (
          <Typography
            fontSize={"0.9rem"}
            fontWeight={700}
            color={theme.palette.text.lightGrey.main}
            noWrap
            component="label"
            htmlFor={htmlFor}
          >
            {label}
            {required && <span>*</span>}
          </Typography>
        )}
        {optional && (
          <Typography
            color={COLORS.RED.MAIN}
            fontSize={"0.8rem"}
            fontWeight={300}
          >
            *{t("optional")}
          </Typography>
        )}
      </TopInfoWrapper>
      {children}
      {error && errorText && <Typography color="error">{errorText}</Typography>}
    </div>
  );
};

export default FormField;
