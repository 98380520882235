import {
  Card,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridColumns,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { csCZ, deDE, enUS, frFR, nlNL, esES } from "@mui/x-data-grid/locales";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SettingsIcon from "@mui/icons-material/Settings";
import MapIcon from "@mui/icons-material/Map";
import TableChartIcon from "@mui/icons-material/TableChart";
import AppMenuButton from "../appMenuButton";
import AppButton from "../appButton";
import { Box } from "@mui/system";
import { dataGridTheme } from "theme";
import { GridPagination } from "@mui/x-data-grid-pro";
import { COLORS } from "theme/colors";

type ConfirmActionHandler = (select: string) => void;
interface ActionHandler {
  confirmActionHandler: ConfirmActionHandler;
}

export interface AppTableProps {
  rows: readonly {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }[];
  columns: GridColumns;
  rowsPerPage?: number[];
  initalPage?: number;
  appTitle: string;
  // TODO: change component type
  mapComponent?: React.ReactNode;
  showSettings?: boolean;
  showExportButton?: boolean;
  exportMenuItems?: { onClick: () => void; label: string }[];
  hideHeaderRow?: boolean;
  rowSelected?: number;
  page?: number;
  pageSize?: number;
  setPageSize?: (pageSize: number) => void;
  setPage?: (page: number) => void;
  totalRows?: number;
  confirmActionHandler?: ConfirmActionHandler;
  handleSortModelChange?: (sortModel: GridSortModel) => void;
}

export const AppTable = ({
  loading,
  rows,
  columns,
  page,
  pageSize,
  setPage,
  setPageSize,
  rowsPerPage = [5, 10, 20],
  appTitle,
  mapComponent,
  showSettings = true,
  showExportButton = true,
  hideHeaderRow = false,
  exportMenuItems,
  rowSelected,
  totalRows,
  confirmActionHandler,
  handleSortModelChange,
  ...rest
}: AppTableProps & DataGridProProps) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [localeText, setLocaleText] = useState(enUS);
  const [isMapDisplayed, setIsMapDisplayed] = useState(false);
  const {
    t,
    i18n: { language },
  } = useTranslation("common", { keyPrefix: "components.table" });
  const rowsWithId = rows.map((row) => ({
    ...row,
    id: row.id ?? row.identifier,
  }));
  const translatedColumns = columns.map((column) => ({
    ...column,
    headerName: column.headerName ? t(column.headerName) : "",
  }));
  useEffect(() => {
    if (language === "en") setLocaleText(enUS);
    if (language === "cs") setLocaleText(csCZ);
    if (language === "de") setLocaleText(deDE);
    if (language === "fr") setLocaleText(frFR);
    if (language === "nl") setLocaleText(nlNL);
    if (language === "es") setLocaleText(esES);
  }, [language]);

  return (
    <Card sx={{ width: "100%" }}>
      {!hideHeaderRow && (
        <Typography
          component="div"
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "8px",
          }}
        >
          <Typography
            component="div"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography variant="h5" component="div">
              {appTitle}
            </Typography>
            {mapComponent && (
              <IconButton onClick={() => setIsMapDisplayed(!isMapDisplayed)}>
                {isMapDisplayed ? <TableChartIcon /> : <MapIcon />}
              </IconButton>
            )}
          </Typography>
          <div>
            {showSettings && (
              <IconButton>
                <SettingsIcon />
              </IconButton>
            )}
            {showExportButton &&
              exportMenuItems &&
              exportMenuItems?.length > 0 && (
                <AppMenuButton
                  startIcon={<DownloadIcon />}
                  endIcon={<KeyboardArrowDownIcon />}
                  color="secondary"
                  size="small"
                  variant="contained"
                  menuItems={exportMenuItems}
                >
                  {t("export")}
                </AppMenuButton>
              )}
          </div>
        </Typography>
      )}
      {isMapDisplayed ? (
        mapComponent
      ) : (
        <ThemeProvider theme={dataGridTheme}>
          <DataGridPro
            disableColumnSelector
            loading={loading}
            disableColumnFilter
            components={{
              Footer: () => (
                <Box
                  sx={{
                    borderTop: `1px solid ${COLORS.LIGHT_GREY.DARK}`,
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {rowSelected ? (
                    <Box
                      sx={{
                        margin: "0 16px",
                        display: "flex",
                        gap: ".5rem",
                        alignItems: "center",
                      }}
                    >
                      {`${t("selectedRows")} ${rowSelected} :`}
                      <TableFooterSelect
                        confirmActionHandler={
                          confirmActionHandler as ConfirmActionHandler
                        }
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Box sx={{ marginLeft: "auto" }}>
                    <GridPagination />
                  </Box>
                </Box>
              ),
            }}
            localeText={{
              ...localeText.components.MuiDataGrid.defaultProps.localeText,
            }}
            columns={translatedColumns as unknown as GridColumns}
            onSortModelChange={handleSortModelChange}
            rows={rowsWithId}
            pagination
            rowCount={totalRows}
            rowsPerPageOptions={rowsPerPage}
            pageSize={pageSize}
            page={page}
            onPageSizeChange={setPageSize}
            onPageChange={setPage}
            autoHeight
            sortingMode="server"
            paginationMode="server"
            sx={{
              width: "100%",
              borderRadius: "0px",
              borderLeft: "none",
              borderRight: "none",
              borderBottom: "none",
              color: theme.palette.text.darkGrey.main,
              "& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeaderDraggableContainer":
                {
                  flexDirection: isTablet ? "row-reverse" : "row",
                },
            }}
            {...rest}
          />
        </ThemeProvider>
      )}
    </Card>
  );
};

const options = ["Activate", "Deactivate"] as const;

const TableFooterSelect = ({ confirmActionHandler }: ActionHandler) => {
  const [select, setSelect] = useState("");
  const { t } = useTranslation("common", { keyPrefix: "components.table" });
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelect(event.target.value);
  };

  return (
    <>
      <Select
        value={select}
        displayEmpty
        onChange={handleChange}
        size="small"
        placeholder="Choose action"
        sx={{ fontSize: ".8rem" }}
        defaultValue=""
        renderValue={(selected) => {
          if (selected.length === 0) {
            return "Choose action";
          }
          return selected;
        }}
      >
        {options.map((item, index) => (
          <MenuItem value={item} key={index} sx={{ fontSize: ".8rem" }}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <AppButton
        variant="contained"
        size="small"
        onClick={() => confirmActionHandler(select)}
      >
        {t("confirmBtn")}
      </AppButton>
    </>
  );
};
