import { alpha, Box, Dialog, useMediaQuery, useTheme } from "@mui/material";
import { updateWidget, useGetWidgetsQuery } from "api/endpoints/dashboard";
import React, { useState, useMemo, useCallback } from "react";
import { useAppSelector } from "stores/store";
import AppButton from "../components/appButton";
import { CreateWidget } from "../components/Dialog/createWidget";
import DragableGrid from "../components/layouts/gridLayout";
import { WidgetContainer } from "../components/widgets/WidgetContainer";
import { Widget } from "../types/commonTypes";
import { useTranslation } from "react-i18next";
import { PageTitle } from "components/pageTitle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { appErrorHandler } from "utils/funcs/appErrorHandler";
import FullscreenLoader from "components/fullscreenLoader";

export default function Dashboard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isCreateWidgetModalOpen, setIsCreateWidgetModalOpen] = useState(false);
  const closeCreateWidgetModal = () => setIsCreateWidgetModalOpen(false);
  const { networkId } = useAppSelector((state) => state.user);
  const { data, refetch, isLoading } = useGetWidgetsQuery(networkId);
  const widgets = data;
  const { t } = useTranslation("common", {
    keyPrefix: "components.dashboard",
  });

  const onResizeStop = useCallback(
    async (id: string, position: Widget["position"]) => {
      const parsedId = parseInt(id);
      const foundWidget = widgets?.find((widget) => widget.id === parsedId);

      if (foundWidget) {
        try {
          await updateWidget(networkId, id, {
            type: foundWidget.type,
            position,
            name: foundWidget.name,
            settings: foundWidget.settings,
          });
        } catch (error) {
          appErrorHandler(error);
        }
      }
    },
    [widgets],
  );

  const widgetsInContainer = useMemo(() => {
    return widgets?.map((widget: Widget, index: number) => ({
      element: (
        <React.Fragment key={index}>
          <WidgetContainer {...widget} updateCallback={() => refetch()} />
        </React.Fragment>
      ),
    }));
  }, [widgets]);

  if (isLoading) return <FullscreenLoader />;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <PageTitle title={t("pageTitle")} />
        <AppButton
          variant="contained"
          onClick={() => setIsCreateWidgetModalOpen(true)}
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            padding: theme.spacing(2, 2),
            ":hover": {
              backgroundColor: alpha(theme.palette.secondary.main, 0.8),
            },
            gap: 1,
          }}
        >
          <AddCircleIcon />
          {t("addWidgetButton")}
        </AppButton>
      </Box>
      <Dialog open={isCreateWidgetModalOpen} fullWidth={true} maxWidth={"xs"}>
        <CreateWidget
          createCallBack={() => refetch()}
          handleClose={closeCreateWidgetModal}
        />
      </Dialog>
      <DragableGrid
        containers={widgetsInContainer || []}
        onResizeStop={onResizeStop}
        onDragStop={onResizeStop}
      />
    </>
  );
}
