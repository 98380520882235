import { styled, Switch, SwitchProps } from "@mui/material";
import React from "react";
import FormField from "../form/formField";

const SwitchWrapper = styled("div")(({ theme: { breakpoints } }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  [breakpoints.down("lg")]: {
    width: "100% !important",
  },
}));

const AppSwitch: React.FC<
  SwitchProps & {
    label?: string;
    error?: boolean;
    errorText?: string;
    halfWidth?: boolean;
  }
> = ({ label, error, errorText, required, halfWidth, ...rest }) => {
  return (
    <SwitchWrapper sx={halfWidth ? { width: "50%" } : {}}>
      <FormField
        label={label}
        required={required}
        error={error}
        errorText={errorText}
      >
        <></>
      </FormField>
      <Switch {...rest} />
    </SwitchWrapper>
  );
};

export default AppSwitch;
