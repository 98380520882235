import {
  DialogTitle,
  Typography,
  IconButton,
  Box,
  useTheme,
  alpha,
  styled,
} from "@mui/material";
import AppButton from "../appButton";
import AppTextField from "../form/textField";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import { WidgetState } from "../widgets/WidgetContainer";
import { useAppSelector } from "stores/store";
import { deleteWidget, updateWidget } from "api/endpoints/dashboard";
import { appErrorHandler } from "utils/funcs/appErrorHandler";
import { useState } from "react";
import WidgetLoader from "components/widgetLoader";

interface UpdateWidgetProps {
  handleClose: () => void;
  setWidgetState: React.Dispatch<React.SetStateAction<WidgetState>>;
  widgetState: WidgetState;
  updateCallback?: () => void;
}

const ButtonsContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  gap: 20,
}));

export const UpdateWidget: React.FC<UpdateWidgetProps> = ({
  handleClose,
  setWidgetState,
  widgetState,
  updateCallback,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("common", {
    keyPrefix: "components.updateWidget",
  });
  const theme = useTheme();
  const { spacing } = theme;
  const { networkId } = useAppSelector((state) => state.user);

  const deleteW = async () => {
    setIsLoading(true);
    try {
      await deleteWidget(networkId, widgetState.id);
    } catch (error) {
      appErrorHandler(error);
    } finally {
      if (updateCallback) updateCallback();
      handleClose();
      setIsLoading(false);
    }
  };

  const update = async (widgetState: WidgetState) => {
    setIsLoading(true);
    const req = {
      type: widgetState.type,
      settings: {},
      name: widgetState.name,
      position: widgetState.position,
    };
    try {
      await updateWidget(networkId, widgetState.id, req);

      if (updateCallback) updateCallback();
      handleClose();
    } catch (error) {
      appErrorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: isLoading ? "hidden" : "auto",
      }}
    >
      {" "}
      {isLoading && <WidgetLoader />}
      <>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">{t("widgetTitle")}</Typography>
          <IconButton key="Settings" onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box
          sx={{
            padding: spacing(3),
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <AppTextField
            label={t("customName")}
            value={widgetState.name}
            onChange={(e) =>
              setWidgetState({ ...widgetState, name: e.target.value })
            }
          />
          <ButtonsContainer>
            <AppButton
              disabled={isLoading}
              variant="contained"
              color="error"
              fullWidth
              onClick={() => deleteW()}
              startIcon={<DeleteIcon />}
            >
              {t("delete")}
            </AppButton>
            <AppButton
              disabled={isLoading}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                padding: theme.spacing(2, 8),
                ":hover": {
                  backgroundColor: alpha(theme.palette.secondary.main, 0.8),
                },
              }}
              onClick={() => update(widgetState)}
              variant="contained"
            >
              {t("confirm")}
            </AppButton>
          </ButtonsContainer>
        </Box>
      </>
    </Box>
  );
};
