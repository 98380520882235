import React from "react";
import DialogBox, { DialogBoxProps } from ".";
import { AppBarChart } from "../charts/barChart";

const BarChartDialog: React.FC<DialogBoxProps> = (props) => {
  return (
    <DialogBox {...props} title="Graph headline" autoWidth>
      <AppBarChart data={props.data} yAxisUnit="%" barAreaHeight={400} />
    </DialogBox>
  );
};

export default BarChartDialog;
