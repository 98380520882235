import { Button as MuiButton, ButtonProps, styled } from "@mui/material";

const StyledButton = styled(MuiButton)(
  ({ theme: { typography, breakpoints, spacing } }) => ({
    ...typography.button,
    textTransform: "none",
    whiteSpace: "nowrap",
    [breakpoints.down("md")]: {
      padding: spacing(1, 3),
    },
  }),
);

const AppButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return <StyledButton {...rest}>{children}</StyledButton>;
};

export default AppButton;
