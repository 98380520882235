import axios from "axios";
import toast from "react-hot-toast";
import i18n from "i18next";

interface ErrorResponse {
  errorMessage?: string;
  message?: string;
}

export const appErrorHandler = async (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const response = error?.response;
    const data = response?.data as ErrorResponse;

    switch (response?.status) {
      case 401: {
        return toast.error(i18n.t("errorHandling.unauthorized"));
      }
      case 403: {
        return toast.error(i18n.t("errorHandling.accessDenied"));
      }
      case 500: {
        return toast.error(i18n.t("errorHandling.serverError"));
      }
      default: {
        const err = data?.errorMessage || data?.message;
        if (err) {
          toast.error(err);
        } else {
          toast.error(i18n.t("errorHandling.generalError"));
        }
      }
    }
  } else {
    toast.error(i18n.t("errorHandling.generalError"));
  }
};
