import { styled, Typography } from "@mui/material";
import React from "react";
import AppLink from "../appLink/appLink";

interface FooterProps {
  dark?: boolean;
}

const FooterWrapper = styled("footer")<FooterProps>(
  ({ theme: { spacing }, dark }) => ({
    borderTop: "1px solid #E5E5E5",
    width: "100%",
    display: "flex",
    padding: spacing(2),
    marginTop: spacing(3),
    justifyContent: "flex-end",
    flexWrap: "wrap",
    color: dark ? "white" : "inherit",
  }),
);
const Row = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  alignItems: "center",
  gap: spacing(1.5),
  flexWrap: "wrap",
}));

const Footer: React.FC<FooterProps> = ({ dark }) => {
  return (
    <FooterWrapper dark={dark}>
      <Row>
        {!dark && (
          <>
            <AppLink to="mailto:support@switchio.com" isExternal>
              support@switchio.com
            </AppLink>
            <Typography sx={{ px: 2 }}>|</Typography>
          </>
        )}
      </Row>
      <Typography>
        Copyright © {new Date().getFullYear()} Monet+, a.s.
      </Typography>
    </FooterWrapper>
  );
};
export default Footer;
