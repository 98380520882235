import {
  Checkbox,
  Chip,
  FormControlLabel,
  ListItemText,
  MenuItem,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import FormField from "../../components/form/formField";
import AppSelect from "../../components/form/select";
import AppTextField from "../../components/form/textField";
import FormController from "../../components/formController";
import { useContext, useEffect, useState } from "react";
import TerminalContext from "pages/newTerminal/TerminalContext";
import { useGetTerminalProfileQuery } from "api/endpoints/terminals";
import { joiResolver } from "@hookform/resolvers/joi";
import { useAppSelector } from "stores/store";
import {
  currencyCodes,
  parseCurrencyNum,
  parseCurrencyStr,
} from "pages/newTerminal/helpers";
import { ImportStatus } from "types/enumTypes";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import AppButton from "components/appButton";
import FullscreenLoader from "components/fullscreenLoader";
import { TerminalData } from "types/commonTypes";
import StepNavigationButtons from "pages/newTerminal/StepNavigationButtons";
import { useParams } from "react-router-dom";

export const ColumnsWrapper = styled("div")(({ theme: { spacing } }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: spacing(4),
}));
export const Column = styled("div")(() => ({
  width: "100%",
}));

const SelectButtonContainer = styled(Box)(() => {
  return {
    display: "flex",
    justifyContent: "flex-end",
    padding: "1rem",
    position: "sticky",
    bottom: 0,
    backgroundColor: "#fff",
  };
});

const TERMINAL_ID = "identifier";
const TERMINAL_NAME = "name";
const STATUS = "status";
const CURRENCY = "currencies";
const OPERATIONS = "operations";

type FormValues = {
  [TERMINAL_ID]: string;
  [TERMINAL_NAME]: string;
  [STATUS]: string;
  [CURRENCY]: string[];
  [OPERATIONS]: {
    [key: string]: boolean;
  };
};

export default function BasicInfo() {
  const {
    profileName,
    newTerminal,
    setNewTerminal,
    setTerminalProfile,
    activeStep,
    prevStep,
    nextStep,
  } = useContext(TerminalContext);
  const { networkId } = useAppSelector((state) => state.user);
  const [isAppSelectOpen, setIsAppSelectOpen] = useState(false);
  const { mode } = useParams();
  const { t } = useTranslation("common", {
    keyPrefix: "newTerminal",
  });
  const { t: tValidation } = useTranslation("", {
    keyPrefix: "validation",
  });

  const { data, isLoading } = useGetTerminalProfileQuery(
    networkId,
    profileName,
  );

  useEffect(() => {
    if (data) {
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(
          ([key]) => key !== "operations" && key !== "currencies",
        ),
      );

      if (mode === "new" && data && !Object.keys(newTerminal).length) {
        setNewTerminal({ ...newTerminal, ...filteredData });
      }
    }
  }, [data]);

  if (data) setTerminalProfile(data);

  const dataOperations = data?.operations || {};
  const filteredDataOperations = Object.fromEntries(
    Object.entries(dataOperations).filter(([, value]) => value === 1),
  );

  const defaultOperations = newTerminal.operations
    ? Object.fromEntries(
        Object.keys(filteredDataOperations).map((key) => [
          key,
          newTerminal.operations[key] || false,
        ]),
      )
    : {};

  const defaultCurrencies = newTerminal.currencies
    ? parseCurrencyNum(newTerminal.currencies)
    : [];

  const getDefValue = (value: any, options: readonly string[]) => {
    if (value && options.includes(value)) {
      return value;
    } else {
      return null;
    }
  };

  const defaultValues = {
    [TERMINAL_ID]: newTerminal.identifier ?? "",
    [TERMINAL_NAME]: newTerminal.name ?? "",
    [STATUS]: getDefValue(newTerminal.status, ["A", "C"]),
    [CURRENCY]: defaultCurrencies,
    [OPERATIONS]: defaultOperations,
  };

  const schema = Joi.object({
    [TERMINAL_ID]: Joi.string()
      .required()
      .max(16)
      .pattern(/^[a-zA-Z0-9]+$/)
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 16 }),
        "string.pattern.base": tValidation("pattern_base"),
      }),
    [TERMINAL_NAME]: Joi.string()
      .trim()
      .required()
      .max(63)
      .messages({
        "string.base": tValidation("textField.mustBeString"),
        "string.empty": tValidation("required"),
        "string.max": tValidation("maxLength", { max: 63 }),
      }),
    [STATUS]: Joi.string()
      .trim()
      .required()
      .messages({
        "string.base": tValidation("required"),
        "string.empty": tValidation("required"),
      }),
    [CURRENCY]: Joi.array()
      .min(1)
      .required()
      .messages({
        "array.min": tValidation("newTerminal.atLeastOneCurrency"),
        "any.required": tValidation("newTerminal.atLeastOneCurrency"),
      }),
    [OPERATIONS]: Joi.object()
      .custom((value, helpers) => {
        const atLeastOneTrue = Object.values(value).some(
          (operation) => operation === 1 || operation === true,
        );

        const hasOneTrue = Object.values(defaultOperations).some(
          (o) => o === true,
        );

        if (!atLeastOneTrue && !hasOneTrue) {
          return helpers.message(
            tValidation("newTerminal.atLeastOneOperation"),
          );
        }
        return value;
      }, "at least one operation set to true")
      .required()
      .messages({
        "object.min": tValidation("newTerminal.atLeastOneOperation"),
        "any.required": tValidation("newTerminal.atLeastOneOperation"),
        "object.pattern.base": tValidation("newTerminal.atLeastOneOperation"),
      }),
  });

  const form = useForm<FormValues>({
    resolver: joiResolver(schema),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultValues: defaultValues,
  });

  const { control, handleSubmit, watch, setValue } = form;
  const allowedTransactionsData = watch([OPERATIONS])[0];
  const selectedCurrency = watch(CURRENCY);

  const operations = Object.keys(dataOperations).filter(
    (key: string) => dataOperations[key] === 1,
  );

  const parsedCurrencies = parseCurrencyNum(currencyCodes);

  useEffect(() => {
    if (data?.currencies && !newTerminal.currencies) {
      setValue(CURRENCY, parseCurrencyNum(data.currencies) as string[]);
    }
  }, [data?.currencies, setValue]);

  const onSubmit = (formData: FormValues) => {
    const UpdatedNewTerminal = {
      ...newTerminal,
      identifier: formData[TERMINAL_ID],
      name: formData[TERMINAL_NAME],
      status: formData[STATUS],
      currencies: parseCurrencyStr(formData[CURRENCY]),
      capability: mode === "create" ? data?.capability : newTerminal.capability,
      operations: Object.fromEntries(
        Object.keys(dataOperations).map((key) => [
          key,
          formData[OPERATIONS]?.[key] !== undefined
            ? formData[OPERATIONS][key]
              ? 1
              : 0
            : defaultOperations[key]
            ? 1
            : 0,
        ]),
      ),
    };
    setNewTerminal(UpdatedNewTerminal as TerminalData);
    nextStep();
  };

  if (isLoading) return <FullscreenLoader />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ColumnsWrapper>
        <Column sx={{ mb: 0 }}>
          <FormController name={TERMINAL_ID} control={control}>
            <AppTextField
              variant="outlined"
              disabled={mode === "edit"}
              name={TERMINAL_ID}
              required
              label={t(TERMINAL_ID)}
            />
          </FormController>
          <Box sx={{ mt: 2 }}>
            <FormController name={TERMINAL_NAME} control={control}>
              <AppTextField
                variant="outlined"
                name={TERMINAL_NAME}
                required
                label={t(TERMINAL_NAME)}
              />
            </FormController>
          </Box>

          <Column sx={{ mt: 2 }}>
            <FormController name={STATUS} control={control}>
              <AppSelect
                variant="outlined"
                name={STATUS}
                label={t(STATUS)}
                required
              >
                {Object.entries(ImportStatus).map((entry, index) => (
                  <MenuItem key={index} value={entry[0]}>
                    {entry[1]}
                  </MenuItem>
                ))}
              </AppSelect>
            </FormController>
          </Column>
          <Column sx={{ mt: 2 }}>
            <FormController name={CURRENCY} control={control}>
              <AppSelect
                label={t(CURRENCY)}
                multiple
                required
                open={isAppSelectOpen}
                onClose={() => setIsAppSelectOpen(false)}
                onOpen={() => setIsAppSelectOpen(true)}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                renderValue={(selected: any) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                    {selected.map((value: any) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {parsedCurrencies?.map((key, index) => (
                  <MenuItem key={index} value={key}>
                    <Checkbox
                      color="secondary"
                      checked={selectedCurrency.indexOf(key as string) > -1}
                    />
                    <ListItemText primary={key} />
                  </MenuItem>
                ))}
                <SelectButtonContainer>
                  <AppButton
                    variant="contained"
                    onClick={() => setIsAppSelectOpen(false)}
                  >
                    {t("confirmBtn")}
                  </AppButton>
                </SelectButtonContainer>
              </AppSelect>
            </FormController>
          </Column>
        </Column>
      </ColumnsWrapper>
      <Box sx={{ mt: 2 }}>
        <Controller
          name={OPERATIONS}
          control={control}
          render={({ fieldState: { error } }) => (
            <FormField
              error={!!error?.message}
              errorText={error?.message}
              label={t(OPERATIONS)}
              required
            >
              <></>
            </FormField>
          )}
        />
        {operations.map((key, index) => (
          <Box key={index} sx={{ display: "flex", flexDirection: "column" }}>
            <FormControlLabel
              control={
                <Controller
                  name={OPERATIONS}
                  control={control}
                  render={(props) => (
                    <Checkbox
                      {...props}
                      defaultChecked={defaultOperations[key] as boolean}
                      onChange={(e) => {
                        props.field.onChange({
                          ...allowedTransactionsData,
                          [key]: e.target.checked,
                        });
                        setNewTerminal((prev) => ({
                          ...prev,
                          operations: {
                            ...prev.operations,
                            [key]: e.target.checked ? 1 : 0,
                          },
                        }));
                      }}
                      color="secondary"
                    />
                  )}
                />
              }
              label={key.charAt(0).toUpperCase() + key.slice(1)}
            />
          </Box>
        ))}
      </Box>
      <StepNavigationButtons activeStep={activeStep} prevStep={prevStep} />
    </form>
  );
}
