import { api } from "api";
import { useQuery } from "react-query";
import { FileType, Hardware } from "types/commonTypes";
import { ApiResponse } from "types/utilityInterfaces";
import { getNotEmptyParams } from "utils/funcs/convert";
import { getFile } from "utils/funcs/downloadFile";

const getAllHardware = async (
  networkId: string,
  params: Partial<Record<string, unknown>>,
) => {
  const notEmptyParams = getNotEmptyParams(params);
  const response = await api.get<ApiResponse<Hardware[]>>(
    `/networks/${networkId}/hardware`,
    {
      params: notEmptyParams,
    },
  );
  return response.data;
};

export const useGetAllHardwareQuery = (
  networkId: string,
  params: Partial<Record<string, unknown>>,
) =>
  useQuery(["getAllHardware", networkId, params], () =>
    getAllHardware(networkId, params),
  );

export const exportHardware = async (
  networkId: string,
  format: FileType,
  params: Partial<Record<string, unknown>>,
) => {
  const notEmptyParams = getNotEmptyParams(params);
  const response = await getFile(
    `/networks/${networkId}/hardware/export/${format}`,
    format,
    "Hardware",
    notEmptyParams,
  );
  return response;
};

export const importHardware = async (networkId: string, file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await api.post(
    `/networks/${networkId}/hardware/import`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response;
};

export const sendImportedHardware = async (
  networkId: string,
  req: Hardware[],
) => {
  const response = await api.post<[]>(
    `/networks/${networkId}/hardware/import/send`,
    req,
  );
  return response;
};

export const downloadHardwareTemplate = async (networkId: string) => {
  const response = await getFile(
    `networks/${networkId}/hardware/import/template`,
    "csv",
    "Hardware template",
  );
  return response;
};
