import { CircularProgress, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import DialogBox, { DialogBoxProps } from ".";
import { useTranslation } from "react-i18next";

const LoadingDialog: React.FC<DialogBoxProps> = (props) => {
  const { spacing } = useTheme();
  const { t } = useTranslation("common", {
    keyPrefix: "components.loadingDialog",
  });
  return (
    <DialogBox
      {...props}
      title={
        <Typography
          variant="h3"
          sx={{ textAlign: "center", width: "100%", paddingTop: spacing(2) }}
        >
          {t("title")}
        </Typography>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3rem",
          alignItems: "center",
          paddingBottom: spacing(2),
        }}
      >
        <Typography variant="h6">{t("message")}</Typography>
        <CircularProgress
          color="secondary"
          sx={{ marginTop: spacing(8) }}
          size={60}
        />
      </Box>
    </DialogBox>
  );
};

export default LoadingDialog;
