import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import FilterWrapper from "./FilterWrapper";
import { useTranslation } from "react-i18next";
import { useFiltersContext } from "context/FiltersContext";
import { isArray } from "lodash";

interface NamePair<T, R> {
  queryname: keyof T | (keyof T)[];
  filterName: R;
}

export interface FilterProps<T = void> {
  filters: FilterTypes[];
  inputs: { Element: React.ElementType; name: string; label: string }[];
  namePairs?: NamePair<T, FilterTypes>[];
}

export type FilterTypes =
  | "MERCHANT_ID"
  | "MERCHANT_NAME"
  | "MERCHANT_STATUS"
  | "MERCHANT_BUSINESS_ID"
  | "LOCATION_ID"
  | "LOCATION_NAME"
  | "LOCATION_STATUS"
  | "LOCATION_BUSINESS_ID"
  | "TERMINAL_ID"
  | "TERMINAL_MERCHANT_ID"
  | "TERMINAL_STATUS"
  | "SERIAL_NUMBER"
  | "HARDWARE_LOCATION"
  | "HARDWARE_STATUS";

const composeDefaultFormState = (
  filters: FilterProps["filters"],
  filterInputsData: any,
) => {
  const defaultState: Record<Partial<FilterTypes>, string> = {
    MERCHANT_ID: filterInputsData.MERCHANT_ID ?? "",
    MERCHANT_NAME: filterInputsData.MERCHANT_NAME ?? "",
    MERCHANT_STATUS: filterInputsData.MERCHANT_STATUS ?? "",
    MERCHANT_BUSINESS_ID: filterInputsData.MERCHANT_BUSINESS_ID ?? "",
    LOCATION_ID: filterInputsData.LOCATION_ID ?? "",
    LOCATION_NAME: filterInputsData.LOCATION_NAME ?? "",
    LOCATION_STATUS: filterInputsData.LOCATION_STATUS ?? "",
    LOCATION_BUSINESS_ID: filterInputsData.LOCATION_BUSINESS_ID ?? "",
    TERMINAL_ID: filterInputsData.TERMINAL_ID ?? "",
    TERMINAL_MERCHANT_ID: filterInputsData.TERMINAL_MERCHANT_ID ?? "",
    TERMINAL_STATUS: filterInputsData.TERMINAL_STATUS ?? "",
    SERIAL_NUMBER: filterInputsData.SERIAL_NUMBER ?? "",
    HARDWARE_LOCATION: filterInputsData.HARDWARE_LOCATION ?? "",
    HARDWARE_STATUS: filterInputsData.HARDWARE_STATUS ?? "",
  };
  const objKeys = Object.keys(defaultState) as FilterTypes[];

  // Create default state only for required filters
  objKeys.forEach(
    (defStateKey) =>
      !filters.includes(defStateKey) && delete defaultState[defStateKey],
  );

  return defaultState;
};

export default function useFilters<T>({
  inputs,
  filters,
  namePairs,
}: FilterProps<T>) {
  const { t } = useTranslation("common", {
    keyPrefix: "components.filter",
  });

  const { filterInputsData, setFilters } = useFiltersContext();

  const defaultValues = composeDefaultFormState([...filters], filterInputsData);

  const { control, watch, reset, setValue } = useForm({ defaultValues });

  const f = watch();
  useEffect(() => {
    if (filterInputsData) {
      const isDifferent = Object.keys(f).some((key) => {
        return f[key as FilterTypes] !== filterInputsData[key];
      });

      if (isDifferent) {
        setFilters(f);
      }
    }
  }, [f]);

  useEffect(() => {
    if (filterInputsData) {
      Object.keys(filterInputsData).some((key) => {
        setValue(key as FilterTypes, filterInputsData[key]);
      });
    }
  }, []);

  const refactorFilterValues = (
    filterValuesBeforeRefactor: typeof filterInputsData,
    namePairs: {
      filterName: keyof typeof filterInputsData;
      queryname: keyof T | (keyof T)[];
    }[],
  ) => {
    const refactoredFilter: Partial<T> = {};
    if (filterInputsData && namePairs.length > 0) {
      namePairs.map((namePair) => {
        if (namePair?.queryname && namePair?.filterName) {
          if (
            isArray(namePair?.queryname) &&
            namePair?.queryname.length > 0 &&
            isArray(filterValuesBeforeRefactor[namePair.filterName])
          ) {
            namePair?.queryname.map((q, index) => {
              refactoredFilter[q] =
                filterValuesBeforeRefactor[namePair.filterName][index];
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            refactoredFilter[namePair.queryname] =
              filterValuesBeforeRefactor[namePair.filterName];
          }
        }
      });
      return refactoredFilter;
    } else {
      return filterValuesBeforeRefactor;
    }
  };

  return {
    Filters: (
      <FilterWrapper>
        {inputs.map(({ Element, name, label }, key) => (
          <Controller
            name={name as FilterTypes}
            control={control}
            render={({ field }) => <Element {...field} label={t(label)} />}
            key={key}
          />
        ))}
      </FilterWrapper>
    ),
    filterInputsData: namePairs
      ? refactorFilterValues(filterInputsData, namePairs)
      : filterInputsData,
    reset,
  };
}
