// AUTHENTICATED ROUTES
export const DASHBOARD_URL = "/";
export const MERCHANTS_URL = "merchants";
export const MERCHANT_DETAIL_URL = "merchants/:merchantId";
export const LOCATIONS_URL = "locations";
export const LOCATION_DETAIL_URL = "locations/:locationId/:merchantId";
export const TERMINALS_URL = "terminals";
export const TERMINALS_DETAIL_URL =
  "terminals/:terminalId/:merchantId/:locationId";

export const TERMINAL_PAGES = {
  NEW_TERMINAL: "new",
  NEW_TERMINAL_URL: "terminals/:mode",
  EDIT_TERMINAL: "edit",
  EDIT_TERMINAL_URL: "terminals/:mode/:merchantId/:locationId/:terminalId",
  DUPLICATE_TERMINAL: "duplicate",
  DUPLICATE_TERMINAL_URL: "terminals/:mode/:merchantId/:locationId/:terminalId",
};

export const CREATE_TERMINAL_PAGES = {
  PROFILE_URL: "profile",
  BASIC_INFO_URL: "basic-info",
  ADDIT_INFO_URL: "addit-info",
  SETTINGS_URL: "settings",
  HARDWARE_URL: "hardware",
};

export const HARDWARE_URL = "hardware";
export const IMPORT_TERMINAL_URL = "terminals/import";
export const IMPORT_MERCHANTS_URL = "merchants/import";
export const IMPORT_LOCATIONS_URL = "locations/import";
export const IMPORT_HARDWARE_URL = "hardware/import";

export const IMPORT_PAGES = {
  OVERVIEW_URL: "overview",
  DONE_URL: "done",
  ERROR_URL: "error",
};

export const MESSAGES_URL = "messages";
export const MESSAGE_DETAIL_URL = ":id";

export const CALLBACK_URL = "/callback";
export const LOGOUT_URL = "/logout";
export const ERROR_PAGE = "/error";
export const LOGIN_URL = "/login";

export const fromRoot = (url: string) => `/${url}`;
