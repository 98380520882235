import "./App.css";
import { CssBaseline } from "@mui/material";
import { Provider } from "react-redux";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { store } from "./stores/store";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import AppRoutes from "./pages/appRoutes/appRoutes";
import { GlobalContextProvider } from "GlobalContext";
import { FiltersProvider } from "context/FiltersContext";
import { Toast } from "components/Toast";
import { appErrorHandler } from "utils/funcs/appErrorHandler";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 0,
      },
    },
    queryCache: new QueryCache({
      onError: (err: unknown) => {
        appErrorHandler(err);
      },
    }),
  });

  return (
    <>
      <CssBaseline />
      <Toast />
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <GlobalContextProvider>
              <FiltersProvider>
                <AppRoutes />
              </FiltersProvider>
            </GlobalContextProvider>
          </ThemeProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Provider>
    </>
  );
}

export default App;
